<!-- hospital.component.html -->
<div class="bg-gray-100 p-6" style="max-height: 90vh; overflow-y: auto;">
  <mat-icon id="close-icon" style="float: right; cursor: pointer;" class="close-icon" [mat-dialog-close]="true">
    close
  </mat-icon>
  <form #officeForm="ngForm" (ngSubmit)="saveOffice()">
    <div class="mx-auto max-w-md">
      <h1 class="text-2xl font-bold mb-8">{{addedit?'Edit':'Add'}} Office</h1>

      <mat-form-field class="w-full">
        <mat-label>Enter Office Name</mat-label>
        <input matInput [(ngModel)]="officeName" name="officeName" required>
      </mat-form-field>

      <mat-form-field class="w-full">
        <mat-label for="zoneSelect">Select Zone</mat-label>
        <mat-select [(ngModel)]="zoneId" (ngModelChange)="onZoneChange($event)" name="zoneId" id="zoneSelect">
          <mat-option *ngFor="let zone of zones" [value]="zone.id">
            {{zone.zoneName}}
          </mat-option>
          <mat-option *ngIf="zones?.length === 0" [value]="'noOptions'">No options available</mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="!hideWard">
        <mat-form-field class="w-full">
          <mat-label for="wardSelect">Select Ward</mat-label>
          <mat-select [(ngModel)]="wardId" (ngModelChange)="onWardSelection($event)" name="wardId" id="wardSelect">
            <mat-option *ngFor="let ward of wards" [value]="ward.id">
              {{ward.wardName}}
            </mat-option>
            <mat-option *ngIf="wards?.length === 0" [value]="'noOptions'">No options available</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <p class="text-center" *ngIf="!hideWard && !hideNode"> Or </p>
      <p class="text-center" *ngIf="!hideWard && !hideNode">(Note:Select Ward Or Zone)</p>


      <div *ngIf="!hideNode">
        <mat-form-field class="w-full">
          <mat-label for="nodeSelect">Select Node</mat-label>
          <mat-select [(ngModel)]="nodeId" (ngModelChange)="onNodeChange($event)" name="nodeId" id="nodeSelect">
            <mat-option *ngFor="let node of nodes" [value]="node.id">
              {{ node.nodeName }}
            </mat-option>
            <mat-option *ngIf="nodes?.length === 0" [value]="'noOptions'">No options available</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field class="w-full" *ngIf="(zoneId && wardId) ||nodeId">
        <mat-label for="sectorSelect">Select Sector</mat-label>
        <mat-select [(ngModel)]="sectorId" (ngModelChange)="onSectorChange($event)" name="sectorId" id="sectorSelect">
          <mat-option *ngFor="let sector of sectorData" [value]="sector.id">
            {{ sector.sectorName }}
          </mat-option>
          <mat-option *ngIf="sectorData?.length === 0" [value]="'noOptions'">No options available</mat-option>
        </mat-select>
      </mat-form-field>


      <!-- <div class="relative z-0 w-full mb-5" *ngIf="(zoneId && wardId) || (zoneId && nodeId)">
        <label for="address" class="block text-gray-600">Address</label>
        <input [(ngModel)]="address" type="textArea" id="address" name="address"
          class="w-full border rounded-md px-4 py-2 focus:outline-none focus:border-blue-400" required />
      </div> -->

      <mat-form-field class="w-full" *ngIf="(zoneId && wardId) || (zoneId && nodeId)">
        <mat-label>Enter Office Address</mat-label>
        <input matInput [(ngModel)]="address" name="address" type="textArea" required>
      </mat-form-field>

      <button id="button" type="submit" [disabled]="officeForm.invalid || !sectorId"
        class="w-full disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none">
        Save
      </button>
      <div class="text-center text-red-500 mt-2">
        {{errorMessage}}
      </div>
    </div>
  </form>
</div>