<mat-card  [class.mat-elevation-z8]="true"
style="margin: 1.4rem;" >

<mat-card-content> 
<div class=" w-full  p-4">
    <div class="lg:flex lg:items-center lg:justify-between">
        <div class="min-w-0 flex-1">
        <h2 class="text-2xl font-bold">Center</h2>
    
        </div>
        <div class="mt-5 flex lg:ml-4 lg:mt-0">
        
        <span class="sm:ml-3">
            <button type="button" (click)="addCenter()"
            class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-lg px-2 py-2.5 text-center inline-flex items-center mr-2  dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
            
                <svg aria-hidden="true" class="w-5 h-5 mr-2 -ml-1 mb-2" fill="currentColor" stroke="currentColor" stroke-width="1.5" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6"></path>
                    </svg>
                    Add new center
                </button>
        </span>
    
        </div>
    </div>
    
</div>
<div class="w-full  p-4">
    <mat-form-field class="searchBox"  appearance="outline">
        <input matInput  (keyup)="applyFilter($event)" placeholder="Search" #input>
    </mat-form-field>
        <table mat-table [dataSource]="centers" *ngIf="centers.data.length > 0 && contentLoaded"
            matSort >
            <ng-container *ngFor="let column of columnNames" [matColumnDef]="column.columnDef">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ column.header }}</th>
                <td mat-cell *matCellDef="let row,let i = index">
                  <ng-container *ngIf="column.columnDef !== 'actions' && column.Def !== 'sno'; else slideToggleTemplate">
                    {{ row[column.columnDef] }}
                  </ng-container>
                  <div *ngIf="column.columnDef==='sno'">
                    {{i+1}}
                  </div>
                  <ng-template #slideToggleTemplate>
                    <div class="action">
                      <mat-slide-toggle [checked]="row.activeFlag === 'Y'" class="mdc-switch"
                      (change)="onSlideToggleChange(row, $event)"></mat-slide-toggle>
                      <button mat-icon-button (click)="onEdit(row)" matTooltip="Edit Job" [disabled]="row.activeFlag === 'N'">
                        <mat-icon>edit</mat-icon>
                      </button>
                     
                    </div>
                  </ng-template>
                </td>
              </ng-container>

          

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator 
        [pageSizeOptions]="[20,40,60]"
         showFirstLastButtons></mat-paginator>
         <ngx-skeleton-loader
         *ngIf="!contentLoaded"
                count="4" 
                appearance="line"
                animation="progress-dark"
                ></ngx-skeleton-loader>
        <h3 *ngIf="centers.data.length == 0 && contentLoaded">No data available.</h3>

       
</div>


</mat-card-content>

</mat-card>
