import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';

@Component({
  selector: 'app-add-cast',
  templateUrl: './add-cast.component.html',
  styleUrls: ['./add-cast.component.scss']
})
export class AddCastComponent {
  religion: string = '';
  religionId: any;
  religions: any;
  cast: any;
  description: any;
  addedit: any;
  id = null;
  errorMessage: string = "";
  createdUserId: any;
  updateUserId: any;
  userId: string;

  constructor(private msmService: MsmamServiceService,
    private _snackBar: SnackbarMsgService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddCastComponent>,
    @Inject(MAT_DIALOG_DATA) public data1: any) {

  }
  ngOnInit() {
    this.userId = localStorage.getItem("userId");
    this.createdUserId = this.userId;
    this.updateUserId = this.userId;
    this.addedit = this.data1['isEditMode']
    if (this.addedit == true) {
      const data12 = this.data1['row'];
      this.createdUserId = data12['createdUserId'];
      this.updateUserId = this.userId;
      this.religionId = data12['religionId'];
      this.religion = data12['religion'];
      this.cast = data12['cast'];
      this.description = data12['description'];
      this.id = data12['id'];
    }
    this.getReligion();
  }
  getReligion() {
    this.msmService.getByActiveFlagReligion().subscribe((data) => {
      this.religions = data['religion'];
    }, (err) => {
      this._snackBar.error("Religion List is Empty");
    })
  }
  religinChange($event) {

  }
  saveCaste() {
    const data = {
      "id": this.id,
      "religionId": this.religionId,
      "cast": this.cast,
      "description": this.description,
      "createdUserId": this.createdUserId,
      "updateUserId": this.updateUserId,
      "activeFlag": "Y"
    };


    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: "550px",
      data: {
        mode: this.addedit ? 'Edit' : 'Add',
        component: 'Caste'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        this.msmService.saveCast(data).subscribe(
          res => {
            if (res['status'] === 'Success') {
              setTimeout(() => {
                this.dialogRef.close({ data: 'Success' });
              }, 1000);
            } else {
              this.errorMessage = res['status']
            }
            // this._snackBar.success(this.addedit?'Caste Edited successfully!':'Caste Added successfully!')

          },
          err => {
            this._snackBar.error('caste not added')
          }
        )
      }
    })
  }
}
