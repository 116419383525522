<div class="flex h-screen overflow-hidden">
    <!-- Left Side with Image -->
    <div class="hidden md:flex w-0 md:w-3/5 bg-cover bg-center"
        style="background-image: url('assets/NMMC-Banner.jpg');">
    </div>

    <!-- Right Side with Registration Form -->
    <div class="flex items-center justify-center w-full md:w-2/5 h-screen bg-gray-50 relative">
        <div class="absolute inset-0 flex items-center justify-center">
            <div class="w-full max-w-md p-6 bg-white shadow-lg rounded-lg">
                <img src="../../assets/nmmc.jpg" alt="NMMC Logo"
                    class="mx-auto w-32 h-32 sm:w-40 sm:h-40 md:w-44 md:h-44 mb-4" />
                <div class="text-2xl font-semibold mb-4 text-center">Navi Mumbai Municipal Corporation</div>

                <h1 class="text-lg sm:text-xl md:text-2xl text-center mb-4">
                    {{ currentStep === 0 ? 'Enter Username' : currentStep === 1 ? 'Verify OTP' : 'Reset Password' }}
                </h1>

                <form [formGroup]="form" (ngSubmit)="handleSubmit()" class="space-y-4">
                    <!-- Username Step -->
                    <div *ngIf="currentStep === 0">
                        <mat-form-field appearance="fill" class="w-full">
                            <mat-label>Username</mat-label>
                            <input matInput formControlName="username" placeholder="Enter your username">
                        </mat-form-field>

                        <button mat-raised-button type="button" (click)="requestOTP()" class="custom-button mt-4">
                            Request OTP
                        </button>

                        <button mat-raised-button color="accent" type="button" (click)="redirectToLogin()"
                            class="w-full mt-2">
                            Go Back to Login
                        </button>
                    </div>

                    <!-- OTP Verification Step -->
                    <div *ngIf="currentStep === 1">
                        <mat-form-field appearance="fill" class="w-full">
                            <mat-label>Enter OTP</mat-label>
                            <input matInput formControlName="otp" placeholder="Enter OTP">
                        </mat-form-field>
                        <button mat-raised-button type="button" (click)="verifyOTP()" class="custom-button mt-4">
                            Verify OTP
                        </button>

                        <button mat-raised-button type="button" (click)="requestOTP()" class="custom-button mt-2">
                            Resend OTP
                        </button>


                        <button mat-raised-button color="accent" type="button" (click)="goBack()" class="w-full mt-2">
                            Go Back
                        </button>
                    </div>

                    <!-- Password Reset Step -->
                    <div *ngIf="currentStep === 2">

                        <mat-form-field appearance="fill" class="w-full">
                            <mat-label>New Password</mat-label>
                            <input matInput formControlName="newPassword" type="password" placeholder="New Password">
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="w-full">
                            <mat-label>Confirm New Password</mat-label>
                            <input matInput formControlName="confirmNewPassword" type="password"
                                placeholder="Confirm New Password">
                            <mat-error *ngIf="form.get('confirmNewPassword')?.hasError('mismatch')">
                                Passwords do not match
                            </mat-error>
                        </mat-form-field>

                        <button mat-raised-button style="background-color: #015296; color: white;" class="w-full mt-4"
                            type="submit" [disabled]="form.invalid">
                            Reset Password
                        </button>


                        <button mat-raised-button color="accent" type="button" (click)="goBack()" class="w-full mt-2">
                            Go Back
                        </button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>