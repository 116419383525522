import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { MaterialManagementServiceService } from 'src/app/_services/Material-Management/material-management-service.service';

@Component({
  selector: 'app-add-edit-center-mm',
  templateUrl: './add-edit-center-mm.component.html',
  styleUrls: ['./add-edit-center-mm.component.scss']
})
export class AddEditCenterMmComponent {
// activeFlag : any = 'Y'
action : any = ''
rowData : any

form!: FormGroup;
userId  : any  = localStorage.getItem("userId")
centerType : any[] = []
constructor(private matServices: MaterialManagementServiceService,
  private fb: FormBuilder,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private _snackBar : MatSnackBar,
  private dialog : MatDialog,
  public dialogRef: MatDialogRef<AddEditCenterMmComponent>) {
}

ngOnInit(){
  this.form = this.fb.group({
    centerName: [""],
    centerTypeId: [""],
    activeFlag : ["Y"],
    createdUserId : [""],
    updateUserId : [""],
    id:[""]
    // sequenceNo : [""]
  });
  this.getCenterType()
  this.form.patchValue({"updateUserId" : this.userId})
  if(this.data.isEditMode == true){
    this.rowData = this.data.row
    this.form.patchValue({"centerName" : this.rowData.centerName})
    this.form.patchValue({"centerTypeId" : this.rowData.centerTypeId})
    this.form.patchValue({"id" : this.rowData.id})
    this.form.patchValue({"activeFlag" : this.rowData.activeFlag})
    this.form.patchValue({"createdUserId" : this.rowData.createdUserId})
    this.form.patchValue({"updateUserId" : this.rowData.updateUserId})
   
    this.action = 'Edit'
  }else {
   
    this.action = 'Add'
    this.form.patchValue({"createdUserId" : this.userId})

  }
}

getCenterType(){
  this.matServices.getByActiveFlagCenterType().subscribe(res=>{
    this.centerType = res['centerTypeList'] 
  })
}

saveCenter() {
  if (this.form.valid) {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: "550px",
      data: {
        mode : this.action,
        component : 'Center'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        this.form.disable()
        this.matServices.saveCenter(this.form.value).subscribe(
          (res) => {
            if(this.action == 'Add'){
              this.showSnackBar('Center added successfully', 'success');

            }else {
              this.showSnackBar('Center updated successfully', 'success');

            }
              this.dialogRef.close({data : 'Success'});

          },
          (err) => {
            if(this.action == 'Add'){
              this.showSnackBar('Error: Center addition failed', 'error');

            }else {
              this.showSnackBar('Error: Center updation failed', 'error');

            }
          }
      );
      }
    })
   
  }
}

showSnackBar(message: string, type: 'success' | 'error') {
this._snackBar.open(message, 'Close', {
    duration: 3000,
    panelClass: type === 'success' ? ['success-snackbar'] : ['error-snackbar'],
});
}
}

