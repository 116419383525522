import { Component } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { CommonService } from "src/app/_services/Common-Services/common.service";
import { CustomValidatorService } from "src/app/_services/custom-validator.service";
import { UsersService } from "src/app/_services/master-data/users.service";
import { ConfirmationPopupComponent } from "src/app/confirmation-popup/confirmation-popup.component";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  form: FormGroup;
  currentStep: number = 0;

  constructor(
    private fb: FormBuilder,
    private regService: UsersService,
    private _snackBar: SnackbarMsgService,
        private router: Router,
    private commonService: CommonService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    if(localStorage.getItem('userId') ){
      this.router.navigate(['/dashboard']); // Stay on dashboard if authenticated
    };
  
    this.form = this.fb.group({
      username: ['', Validators.required],
      otp: ['', Validators.required],
      // currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmNewPassword: ['', Validators.required],
    },
    {
      validators: this.passwordMatchValidator,
    }
  );
    
  }

  requestOTP() {
    const username = this.form.get("username")?.value;
    if (username) {
      this.regService.requestOTP({ userName: username }).subscribe((res: any) => {
        if (res["status"] === "Success") {
          this.currentStep = 1;
          console.log('OTP request successful');
          this._snackBar.success("OTP sent successfully.");
        } else {
          this._snackBar.error("Failed to send OTP.");
        }
      });
    }
  }
  

  verifyOTP() {
    const userName = this.form.get("username")?.value;
    const otp = this.form.get("otp")?.value;

    const payload = {
      userMobile: userName,
      otp: otp
    };

    this.regService.verifyMobileOTP(payload).subscribe((res: any) => {
      if (res["status"] === "Success") {
        this.currentStep = 2;
        console.log('OTP verification successful');
        this._snackBar.success("OTP verified successfully.");
      } else {
        this._snackBar.error("Invalid OTP.");
      }
    });
  }

  handleSubmit() {

    const userName = this.form.get("username")?.value;
    const newPassword = this.form.get("newPassword")?.value;
    const confirmNewPassword = this.form.get("confirmNewPassword")?.value;

    console.log(userName, newPassword, confirmNewPassword);

    const payload = {
      userName: userName,
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword
    };

    this.regService.updateCitizenPassword(payload).subscribe((res: any) => {
      if (res["status"] === "Success") {
        this.currentStep = 3;
        console.log('Password updated successfully');
        this._snackBar.success("Password updated successfully.");
        this.redirectToLogin();
      } else {
        this._snackBar.error(res["message"]);
      }
    });
  }

  goBack() {
    if (this.currentStep === 0) {
      this.redirectToLogin();
    } else {
      this.currentStep--;
    }
  }

  redirectToLogin() {
    this.router.navigate(['/login']);
  }

  passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const newPassword = control.get('newPassword');
    const confirmNewPassword = control.get('confirmNewPassword');
  
    if (newPassword && confirmNewPassword && newPassword.value !== confirmNewPassword.value) {
      control.get('confirmNewPassword')?.setErrors({ mismatch: true });
      return { mismatch: true };
    } else {
      control.get('confirmNewPassword')?.setErrors(null);
      return null;
    }
  }
  

  savePassword() {

    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: "550px",
      data: {
        component: "Update Password",
      },
      autoFocus: false,
      restoreFocus: false,
    });
    
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === "success") {
    const userName=this.form.get("userName")?.value;
    const usrPassword=this.form.get("usrPassword")?.value;
    this.commonService.savePassword(userName,usrPassword).subscribe((res) => {
      if (res["status"] === "success") {
        this._snackBar.success('Password Updated Successfully');

        setTimeout(() => {
          this.router.navigate(["/login"]);
        }, 1000);
      } else if(res["status"] === "Failure"){
        this._snackBar.error(res["message"]);
      } else {
        this._snackBar.error('Password Updation Failed');
      }

      });
    }
  });
  }


}
