<!-- in refrence with mapping -->
<!-- <div class="min-h-screen flex flex-col">
  <app-mapped-toolbar></app-mapped-toolbar>  
  <app-footer class="fixed bottom-0 left-0 w-full z-10"></app-footer>

</div> -->

  <div class="min-h-screen flex flex-col">
    <app-toolbar></app-toolbar>
    <app-footer class="fixed bottom-0 left-0 w-full z-10"></app-footer>
  </div>
