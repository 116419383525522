import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';

@Component({
  selector: 'app-add-calender-sheduling',
  templateUrl: './add-calender-sheduling.component.html',
  styleUrls: ['./add-calender-sheduling.component.scss']
})
export class AddCalenderShedulingComponent {
  calenderPrefix:any;
  event:any;
  date:any;
  remark:any;
  addedit:any;
  createdUserId:any;
  updateUserId:any;
  userId: string;
  id=null;
  errorMessage:string=""
  constructor(private msmService:MsmamServiceService,
   private _snackBar: SnackbarMsgService, 
  private dialog: MatDialog,
  public dialogRef: MatDialogRef<AddCalenderShedulingComponent>,
  @Inject(MAT_DIALOG_DATA) public data1: any){
   
}
ngOnInit() {
  this.addedit = this.data1['isEditMode'];
  this.userId = localStorage.getItem("userId");
    this.createdUserId=this.userId;
    this.updateUserId=this.userId;
  this.date=this.data1['date'];
  if(this.addedit == true){
    const data12 = this.data1['row'];
    this.createdUserId=data12['createdUserId'];
    this.updateUserId=this.userId;
    this.calenderPrefix=data12['calenderPrefix'];
    this.event=data12['event'];
    this.date=data12['date'];
    this.remark=data12['remark'];
    this.id=data12['id'];

  }
}


saveCaste() {
     const data = {
      "id":this.id,
      "calenderPrefix": this.calenderPrefix,
      "event":this.event,
      "date":this.date,
      "remark":this.remark,
      "createdUserId":this.createdUserId,
      "updateUserId":this.updateUserId,
      "activeFlag": "Y"
    };
  

    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: "550px",
      data: {
        mode: this.addedit?'Edit':'Add',
        component: 'Event'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        this.msmService.saveNewCalender(data).subscribe(
          res => {
            if(res['status'] === 'Success'){
              setTimeout(() => {
                this.dialogRef.close({data : 'Success'});
              }, 1000);
            }else{
              this.errorMessage=res['status']
            }
            // this._snackBar.success(this.addedit?'Event Edited successfully!':'Event Added successfully!')

          },
          err => {
            this._snackBar.error('Event not added')
          }
        )
      }
    })
   }
}
