import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomValidatorService {

  constructor() { }


  patternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');
      const valid = regex.test(control.value);
      return valid ? null : { invalidPassword: true };
    };
  }

  MatchPassword(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (confirmPasswordControl.errors && !confirmPasswordControl.errors['passwordMismatch']) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    }

  }

  userNameValidator(userControl: AbstractControl) {
    return new Promise(resolve => {
      setTimeout(() => {
        if (this.validateUserName(userControl.value)) {
          resolve({ userNameNotAvailable: true });
        } else {
          resolve(null);
        }
      }, 1000);
    });
  }

  validateUserName(userName: string) {
    const UserList = ['ankit', 'admin', 'user', 'superuser'];
    return (UserList.indexOf(userName) > -1);
  }

  customEmailValidator(): ValidatorFn {
    // return (control: FormControl): { [key: string]: any } | null => {
    //   const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    //   const valid = emailRegex.test(control.value);
    //   const validTLD = /\.(com|in|ai)$/i.test(control.value); // Check for valid top-level domain
    //   if (!validTLD) {
    //     return { 'invalidTLD': { value: control.value } };
    //   }
    //   return valid ? null : { 'invalidEmail': { value: control.value } };
    // };
    return (control: AbstractControl): ValidationErrors | null => {
      const validTLD = /\.[a-zA-Z]+$/i.test(control.value);
      return validTLD ? null : { invalidTLD: true };
    };
  }

  noLeadingWhiteSpaceValidator(): ValidatorFn {
   

    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
  
      if (value && /^\s/.test(value)) {
        return { whitespace: true };
      }
  
      return null;
    };

  }

  noLeadingAndExtraWhiteSpaceValidator(): ValidatorFn {
     return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
  
      if (value && (/^\s/.test(value) || /\s{2,}/.test(value))) {
        return { whitespace: true };
      }
  
      return null;
    };
  }
  


  

}
