<div class="bg-white p-12" style="max-height: 90vh; overflow-y: auto;">
  <mat-icon id="close-icon" style="float: right; cursor: pointer;" class="close-icon" [mat-dialog-close]="true">
    close
  </mat-icon>

  <h1 class="text-2xl font-bold mb-8 ">{{committeeMember.addedit?'Edit':'Add'}} Committee Member</h1>

  <form (ngSubmit)="saveMember()" #meetingForm="ngForm" >
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-5">


        <mat-form-field class="w-full">
          <mat-label>Enter First Name</mat-label>
          <input matInput [(ngModel)]="committeeMember.firstName" name="firstName" required>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label>Enter Middle Name</mat-label>
          <input matInput [(ngModel)]="committeeMember.middleName" name="middleName" required>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label>Enter Last Name</mat-label>
          <input matInput [(ngModel)]="committeeMember.lastName" name="lastName" required>
        </mat-form-field>

        <!-- Enter Email -->
        <mat-form-field class="w-full">
          <mat-label>Enter Email</mat-label>
          <input matInput [(ngModel)]="committeeMember.email" name="email" type="email" required pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}">
          <mat-error *ngIf="meetingForm.controls['email']?.hasError('pattern')">
            Please enter a valid email address.
          </mat-error>
        </mat-form-field>

        <!-- Enter Mobile Number -->
        <mat-form-field class="w-full">
          <mat-label>Enter Mobile Number</mat-label>
          <input matInput [(ngModel)]="committeeMember.mobile" name="mobile" type="tel" required pattern="^[0-9]{10}$">
          <mat-error *ngIf="meetingForm.controls['mobile']?.hasError('pattern')">
            Please enter a valid mobile number.
          </mat-error>
        </mat-form-field>
      

        <!-- Select Gender -->
        <mat-form-field class="w-full">
          <mat-label>Select Gender</mat-label>
          <mat-select [(ngModel)]="committeeMember.gender" name="gender" required>
            <mat-option value="male">Male</mat-option>
            <mat-option value="female">Female</mat-option>
            <mat-option value="other">Other</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="relative z-0 w-full mb-5">
          <label class="block text-gray-600">Date of Birth</label>
          <input [(ngModel)]="committeeMember.dob" type="date" name="dob"
            class="w-full border rounded-md px-4 py-2 focus:outline-none focus:border-blue-400" required="" />
                    
        </div>

        <mat-form-field class="w-full">
          <mat-label>Enter Address</mat-label>
          <input matInput [(ngModel)]="committeeMember.address" name="address" type="textarea" required>
        </mat-form-field>


        <mat-form-field class="w-full">
          <mat-label for="committeeSelect">Select Committee</mat-label>
          <mat-select [(ngModel)]="committeeMember.committeeId" name="committeeId" id="committeeSelect">
            <mat-option *ngFor="let committee of committees" [value]="committee.id">
              {{ committee.committeeName }}
            </mat-option>
            <mat-option *ngIf="committees?.length === 0" [value]="'noOptions'">No options
              available</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label for="wardId">Select Ward</mat-label>
          <mat-select [(ngModel)]="committeeMember.wardId" name="wardId" id="wardSelect" (ngModelChange)="wardChange($event)">
            <mat-option *ngFor="let ward of wards" [value]="ward.id">
              {{ ward.wardName }}
            </mat-option>
            <mat-option *ngIf="wards?.length === 0" [value]="'noOptions'">No options
              available</mat-option>
          </mat-select>
        </mat-form-field>


        <mat-form-field class="w-full">
          <mat-label for="religionId">Select Religion</mat-label>
          <mat-select [(ngModel)]="committeeMember.religionId" name="religionId" id="religionId">
            <mat-option *ngFor="let religion of religions" [value]="religion.id">
              {{religion.religion}}
            </mat-option>
            <mat-option *ngIf="religions?.length === 0" [value]="'noOptions'">No options
              available</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label for="casteId">Select Caste</mat-label>
          <mat-select [(ngModel)]="committeeMember.casteId" name="casteId" id="casteSelect">
            <mat-option *ngFor="let caste of castes" [value]="caste.id">
              {{ caste.cast }}
            </mat-option>
            <mat-option *ngIf="castes?.length === 0" [value]="'noOptions'">No options
              available</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label for="subCasteId">Select Sub-Caste</mat-label>
          <mat-select [(ngModel)]="committeeMember.subCasteId" name="subCasteId" id="subCasteSelect">
            <mat-option *ngFor="let subCaste of subCastes" [value]="subCaste.id">
              {{ subCaste.subCast }}
            </mat-option>
            <mat-option *ngIf="subCastes?.length === 0" [value]="'noOptions'">No options
              available</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Select Electoral Ward -->
        <mat-form-field class="w-full">
          <mat-label for="electoralWardId">Select Electoral Ward</mat-label>
          <mat-select [(ngModel)]="committeeMember.electoralWardId" name="electoralWardId" id="electoralWardSelect">
            <mat-option *ngFor="let electoralWard of electoralWards" [value]="electoralWard.id">
              {{ electoralWard.electoralWardName }}
            </mat-option>
            <mat-option *ngIf="electoralWards?.length === 0" [value]="'noOptions'">No options
              available</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label for="partyId">Select Party</mat-label>
          <mat-select [(ngModel)]="committeeMember.partyId" name="partyId" id="partySelect">
            <mat-option *ngFor="let party of parties" [value]="party.id">
              {{ party.partyName }}
            </mat-option>
            <mat-option *ngIf="parties?.length === 0" [value]="'noOptions'">No options
              available</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label for="identificationProofId">Select Identification Proof</mat-label>
          <mat-select [(ngModel)]="committeeMember.identificationProofId" name="identificationProofId"
            id="identificationProofSelect">
            <mat-option *ngFor="let identificationProof of identificationProofs" [value]="identificationProof.id">
              {{ identificationProof.identificationProofName }}
            </mat-option>
            <mat-option *ngIf="identificationProofs?.length === 0" [value]="'noOptions'">No options
              available</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label>Enter identificationProof No </mat-label>
          <input matInput [(ngModel)]="committeeMember.identificationProofNo" name="identificationProofNo" type="number" required>
        </mat-form-field>

        <div class="relative z-0 w-full mb-5">
          <label class="block text-gray-600">From Date</label>
          <input [(ngModel)]="committeeMember.fromDate" type="date" name="fromDate"
            class="w-full border rounded-md px-4 py-2 focus:outline-none focus:border-blue-400" required="" />
                    
        </div>

        <div class="relative z-0 w-full mb-5">
          <label class="block text-gray-600">To Date</label>
          <input [(ngModel)]="committeeMember.toDate" type="date" name="toDate"
            class="w-full border rounded-md px-4 py-2 focus:outline-none focus:border-blue-400" required="" />
                    
        </div>

        <div class="relative z-0 w-full mb-5">
          <label class="block text-gray-600">Elected Date</label>
          <input [(ngModel)]="committeeMember.electedDate" type="date" name="electedDate"
            class="w-full border rounded-md px-4 py-2 focus:outline-none focus:border-blue-400" required="" />
                    
        </div>

        <mat-form-field class="w-full">
          <mat-label>Enter Member Change</mat-label>
          <input matInput [(ngModel)]="committeeMember.memberChange" name="memberChange" required>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label>Enter Reason for Change</mat-label>
          <input matInput [(ngModel)]="committeeMember.reasonForChange" name="reasonForChange" required>
        </mat-form-field>

     


    </div>
    <div class="W-full flex justify-center ">
    <mat-checkbox [(ngModel)]="committeeMember.nominatedCorporator" color="primary" name="nominatedCorporator" class="p-2">
      Nominated Corporator
    </mat-checkbox>

    <mat-checkbox [(ngModel)]="committeeMember.nominatedChairperson" color="primary" name="nominatedChairperson" class="p-2">
      Nominated Chair Person
    </mat-checkbox>
  </div>
    <button type="submit"
      class="w-full disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
      [disabled]="!meetingForm.valid">
      Save
    </button>
    <div class="text-center text-red-500 mt-2">
      {{errorMessage}}
    </div>
  </form>
</div>