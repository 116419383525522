import { Component, Inject } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MsmamServiceService } from "src/app/_services/MSMAM/msmam-service.service";
import { ConfirmationPopupComponent } from "src/app/confirmation-popup/confirmation-popup.component";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";

@Component({
  selector: "app-add-clerk-to-committee",
  templateUrl: "./add-clerk-to-committee.component.html",
  styleUrls: ["./add-clerk-to-committee.component.scss"],
})
export class AddClerkToCommitteeComponent1 {
  form: FormGroup = new FormGroup({});
  mediaChannelName: string = "";
  addedit: any;
  id = null;
  committeeNameList: any[] = [];
  userList: any[] = [];
  clerkNameList: any[] = [];
  mediaList: any[] = [];
  errorMessage: string = "";
  createdUserId: any;
  updateUserId: any;
  userId: string;
  constructor(
    private msmService: MsmamServiceService,
    private _snackBar: SnackbarMsgService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddClerkToCommitteeComponent1>,
    @Inject(MAT_DIALOG_DATA) public data1: any
  ) {}
  ngOnInit() {
    this.addedit = this.data1["isEditMode"];
    this.userId = localStorage.getItem("userId");
    this.createdUserId = this.userId;
    this.updateUserId = this.userId;
    this.form = this.fb.group({
      // id : [""],
      committeeId: new FormControl(null, [Validators.required]),
      clerkId: new FormControl(null, [Validators.required]),
      createdUserId: [this.userId],
      updateUserId: [this.userId],
      activeFlag: ["Y"],
    });
    if (this.addedit == true) {
      const data12 = this.data1["row"];
      this.createdUserId = data12["createdUserId"];
      this.updateUserId = this.userId;
      this.form.get("clerkId").setValue(data12["clerkId"]);
      this.form.get("committeeId").setValue(data12["committeeId"]);

      this.id = data12["id"];
    }

    this.getCommitteeName();
    this.getClerkNameList();
  }

  getCommitteeName() {
    this.msmService.getCommitteeName().subscribe(
      (res) => {
        this.committeeNameList = res["committeeMasterList"];
      },
      (err) => {}
    );
  }

  getClerkNameList() {
    const designationId = "ac1f1f79-8c7d-1d2f-818c-80ade9540016";
    this.msmService.getClerkNameList(designationId).subscribe(
      (res) => {
        this.userList = res["userList"];
      },
      (err) => {}
    );
  }

  save() {
    if (this.form.valid) {
      const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
        width: "550px",
        data: {
          mode: this.addedit ? "Edit" : "Add",
          component: "Clerk to Committee ",
        },
        autoFocus: false,
        restoreFocus: false,
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result == "success") {
          let formData = this.form.value;

          if (this.addedit) {
            formData.id = this.id;
          } else {
            formData.id = null;
          }
          this.msmService.saveClerkToCommitteeDetail(formData).subscribe(
            (res) => {
              if (res["status"] === "Success") {
                setTimeout(() => {
                  this.dialogRef.close({ data: "Success" });
                }, 1000);
              } else {
                this.errorMessage = res["status"];
              }
            },
            (err) => {
              this._snackBar.error("Clerk to Committee not added");
            }
          );
        }
      });
    }
  }
}
