import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';

@Component({
  selector: 'app-add-subcast',
  templateUrl: './add-subcast.component.html',
  styleUrls: ['./add-subcast.component.scss']
})
export class AddSubcastComponent {
  religion: string = '';
  religionId:any;
  religions:any;
  cast:any;
  description:any;
  addedit:any;
  castes:any;
  castId:any;
  subCast:any;
  id=null;
  createdUserId:any;
  updateUserId:any;
  userId: string;
  errorMessage:string="";
  constructor(private msmService:MsmamServiceService,
   private _snackBar: SnackbarMsgService, 
  private dialog: MatDialog,
  public dialogRef: MatDialogRef<AddSubcastComponent>,
  @Inject(MAT_DIALOG_DATA) public data1: any){
 
}
ngOnInit() {
  this.addedit = this.data1['isEditMode'];
  this.userId = localStorage.getItem("userId");
  this.createdUserId=this.userId;
  this.updateUserId=this.userId;
  if(this.addedit == true){
    const data12 = this.data1['row']
    this.religionId=data12['religionId'];
    this.religion=data12['religion'];
    this.castId=data12['castId'];
    this.cast=data12['cast'];
    this.subCast=data12['subCast'];
    this.description=data12['description'];
    this.id=data12['id'];
    this.createdUserId=data12['createdUserId'];
    this.updateUserId=this.userId;
  }
 this.getReligion();
 this.getCaste();
}
getCaste(){
  this.msmService.getByActiveFlagCast().subscribe((data)=>{
    this.castes=data['castMasterList'];
  },(err)=>{
    this._snackBar.error("Cast List is Empty");
  })
}
getReligion(){
  this.msmService.getByActiveFlagReligion().subscribe((data)=>{
    this.religions=data['religion'];
  },(err)=>{
    this._snackBar.error("Religion List is Empty");
  })
}
religinChange($event){

}
saveCaste() {
     const data = {
      "id":this.id,
      "religionId": this.religionId,
      "castId":this.castId,
      "subCast":this.subCast,
      "description":this.description,
      "createdUserId":this.createdUserId,
      "updateUserId":this.updateUserId,
      "activeFlag": "Y"
    };
  

    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: "550px",
      data: {
        mode: this.addedit?'Edit':'Add',
        component: 'Sub Caste'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        this.msmService.saveSubcast(data).subscribe(
          res => {
            if(res['status'] === 'Success'){
              setTimeout(() => {
                this.dialogRef.close({data : 'Success'});
              }, 1000);
            }else{
              this.errorMessage=res['status']
            }
          },
          err => {
            this._snackBar.error('Subcaste not added')
          }
        )
      }
    })
   }
}
