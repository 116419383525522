import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(private http:HttpClient) { }

  getdepartment(){
    return this.http.get(`${environment.apiUrl}/api/department/getAll`)
  }

  getsubdepartment(data:any){
    return this.http.get(`${environment.apiUrl}/api/subDepartment/getBySubDepartment?department=${data}`)
  }

  getservice(depid:any,subid:any){
    return this.http.get(`${environment.apiUrl}/api/master/getServicesByDptIdAndSubDptId?dptId=${depid}&subDptId=${subid}`)
  }

  // Role Master Data Submit and get
  addroleMaster(data:any){
    return this.http.post(`${environment.commonUrl}/api/master/role/save`,data)
  }

  getroleMaster(){
    return this.http.get(`${environment.commonUrl}/api/master/role/getAllDashboard`)
  }

  activateInactivateRole(id,activeFlag){
    return this.http.post(`${environment.commonUrl}/api/master/role/changeFlag?id=${id}&activeFlag=${activeFlag}`,null)
  }
}
