<br/>
<br/>
<br/>
<br/>
<br/>

  <div class="department-card" >
    <mat-card class="card" *ngFor="let item of servicesData"  (click)="onCardClick(item)">
        <mat-card-content>
           <h3 style="font-weight: 800;">{{ item.serviceName }}</h3>
        </mat-card-content>
      </mat-card>
</div>
<!-- (click)="onCardClick(form.formId)"  -->
  
<!-- <app-service-list></app-service-list> -->