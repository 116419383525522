const serverUrl = "https://15-206-219-76.nip.io/backend/";

export const environment = {
  production: true,
  commonUrl: `${serverUrl}common`,
  rtsUrl: `${serverUrl}rts`,
  surveyDB: `${serverUrl}sdc`,
  gmUrl: `${serverUrl}gm`,
  cmsUrl: `${serverUrl}cms`,
  wtUrl: `${serverUrl}wt`,
  ewmUrl: `${serverUrl}ewm`,
  taxUrl: `${serverUrl}pt`,
  hrmsUrl: `${serverUrl}hrms`,
  wmUrl: `${serverUrl}wm`,
  faUrl: `${serverUrl}fm`,
  cwmUrl: `${serverUrl}cwm`,

  apiUrl: "https://15-206-219-76.nip.io:9002",
  disasterUrl: "https://15-206-219-76.nip.io:9007",
  landUrl: "https://15-206-219-76.nip.io:9006",
  msmamUrl: "https://15-206-219-76.nip.io:9008",
  assetUrl: "https://15-206-219-76.nip.io:9009",
  mmUrl: "https://15-206-219-76.nip.io:9012",
  // faUrl: "https://15-206-219-76.nip.io:9013",
  swmUrl: "https://15-206-219-76.nip.io:9014",
};
