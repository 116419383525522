import { Component, ViewChild } from '@angular/core';
import { AddElectoralWardComponent } from './add-electoral-ward/add-electoral-ward.component';
import { MatTableDataSource } from '@angular/material/table';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-electoral-ward-master',
  templateUrl: './electoral-ward-master.component.html',
  styleUrls: ['./electoral-ward-master.component.scss']
})
export class ElectoralWardMasterComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  data: any;

columnNames: any[] = [
 
  {
    columnDef: 'sno',
    header: 'Sr.No.',
  },
  {
    columnDef: 'electoralWardPrefix',
    header: 'Prefix',
  },
  {
    columnDef: 'electoralWardName',
    header: 'Electoral Ward Name',
  },
  {
    columnDef: 'electoralWardNumber',
    header: 'Electoral Ward Number',
  },
  {
    columnDef: 'fromDate',
    header: 'From Date',
  },
  {
    columnDef: 'toDate',
    header: 'To Date',
  },
  {
    columnDef: 'actions',
    header: 'Actions',
  },
];
departments = new MatTableDataSource<any>();
displayedColumns: any[] = []
contentLoaded = false;
constructor(private msmService: MsmamServiceService,
  private dialog: MatDialog,
  private _snackBar: SnackbarMsgService) {

}
ngOnInit() {
  this.displayedColumns = this.displayedColumns.concat(this.columnNames.map(x => x.columnDef));
  this.getElectoral()
}

getElectoral() {
  this.msmService.getElectoralward().subscribe(
    data => {
      this.departments.data = data['electoralWardMasterList'];
      this.departments.data = this.departments.data.sort((a, b) => a.id - b.id);
      setTimeout(
        () => {
          this.departments.sort = this.sort;
          this.departments.paginator = this.paginator;
        });

      setTimeout(() => {
        this.contentLoaded = true;
      }, 2000);

    },
    err => {
      this.contentLoaded = true;

    }
  )
}

addElectoral() {
  const dialogRef = this.dialog.open(AddElectoralWardComponent, {
    width: "550px",
    data: {
      isEditMode: false,
    },
  });

  dialogRef.afterClosed().subscribe((result: any) => {
    if (result.data == "Success") {
      this.contentLoaded = false;
      this.getElectoral()
    }
  });
}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.departments.filter = filterValue.trim().toLowerCase();
}

onSlideToggleChange(row: any, event: any): void {

  row.activeFlag = event.checked ? 'Y' : 'N';
  if(event.checked) {
  this.msmService.activeInactiveElectoralward(row.id, row.activeFlag).subscribe(
    res => {
      this._snackBar.success(row.electoralWardName + ' is Activated');
    },
    err => {
      this._snackBar.error('Activation Flag Failed to update')
    }
  )

} else {
  this.msmService.activeInactiveElectoralward(row.id, row.activeFlag).subscribe(
    res => {
      this._snackBar.error(row.electoralWardName + ' is Deactivated');
      this.getElectoral();
    },
    err => {
      this._snackBar.error('Activation Flag Failed to Delete')
    })
}
   
  }
onEdit(row: any){
  const dialogRef = this.dialog.open(AddElectoralWardComponent, {
    width: "550px",
    data: {
      isEditMode: true,
      row: row
    },
  });

  dialogRef.afterClosed().subscribe((result: any) => {
    if (result.data == "Success") {
      this.contentLoaded = false;
      this.getElectoral()
    }
  });
}

}
