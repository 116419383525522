import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {KeyValue} from '@angular/common';
import { FileUploadService } from 'src/app/_services/file-upload.service';
import * as FileSaver from "file-saver";

@Component({
  selector: 'app-application-modal',
  templateUrl: './application-modal.component.html',
  styleUrls: ['./application-modal.component.scss']
})
export class ApplicationModalComponent {

  docPath : any = ''
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private uploadService : FileUploadService) {
    this.docPath = this.data.docPath
    // delete this.data.docPath;

  }

  viewDocument(){
    // window.open(this.docPath, '_blank');
    this.uploadService.getFilePreviewSingle(this.docPath).then((data : any) => {
        const blob = new Blob([data],
          { type:".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"});
      const file = new File([blob], 'File',
      { type:".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"});
  
      FileSaver.saveAs(file);

    })
  }
}
