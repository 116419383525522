import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmationPopupComponent } from "src/app/confirmation-popup/confirmation-popup.component";
import { FileUploadService } from "src/app/_services/file-upload.service";
import { NodeMasterService } from "src/app/_services/master-data/node-master.service";
import { SectorMasterService } from "src/app/_services/master-data/sector-master.service";
import { PropertyTaxService } from "src/app/_services/property-tax/property-tax.service";
import { WardService } from "src/app/_services/ward.service";
import { ZoneService } from "src/app/_services/zone.service";
import { MatStepper } from "@angular/material/stepper";
import { Router } from "@angular/router";
import { CommonService } from "src/app/_services/Common-Services/common.service";

@Component({
  selector: "app-add-edit-property-tax",
  templateUrl: "./add-edit-property-tax.component.html",
  styleUrls: ["./add-edit-property-tax.component.scss"],
})
export class AddEditPropertyTaxComponent {
  activeFlag: any = "Y";
  nodes: any[] = [];
  zonesData: any[] = [];
  wards: any[] = [];
  sectors: any[] = [];
  plots: any[] = [];
  banks: any[] = [];
  area: any[] = [];
  building: any[] = [];
  usageData: any[] = [];
  subUsageData: any[] = [];
  loadingPayment: boolean = false;
  uploadedDocPath: any = "";
  form!: FormGroup;
  propertySubtypeData: any;
  propertyTypeData: any;
  constructor(
    private taxSErvice: PropertyTaxService,
    private fb: FormBuilder,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private _snackBar: MatSnackBar,
    private wardService: WardService,
    private nodeService: NodeMasterService,
    private zoneService: ZoneService,
    private sectorService: SectorMasterService,
    private uploadService: FileUploadService,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      step1: this.fb.group({
        title: ["", Validators.required],
        firstName: ["", Validators.required],
        middleName: [""],
        lastName: ["", Validators.required],
        gender: ["", Validators.required],
        mobile: [
          "",
          [
            Validators.required,
            Validators.pattern("^[0-9]*$"),
            Validators.minLength(10),
            Validators.maxLength(10),
          ],
        ],
        email: ["", [Validators.required, Validators.email]],
        adharNo: ["", Validators.required],
        panNo: ["", Validators.required],
        wardId: ["", Validators.required],
        sectorId: ["", Validators.required],
        nodeId: ["", Validators.required],
        plotId: ["", Validators.required],
        survey: ["", Validators.required],
        surveyNo: ["", Validators.required],
        flatNo: ["", Validators.required],
        buildingId: ["", Validators.required],
        societyName: ["", Validators.required],
        areaId: ["", Validators.required],
        landmark: ["", Validators.required],
        village: ["", Validators.required],
        city: ["", Validators.required],
        pinCode: ["", Validators.required],
        latitude: ["", Validators.required],
        longitude: ["", Validators.required],
        postalAddress: [""],

        // title: ["", Validators.nullValidator],
        // firstName: ["", Validators.nullValidator],
        // middleName: [""],
        // lastName: ["", Validators.nullValidator],
        // gender: ["", Validators.nullValidator],
        // mobile: [
        //   "",
        //   [
        //     Validators.nullValidator,
        //     Validators.nullValidator,
        //     Validators.nullValidator,
        //     Validators.nullValidator,
        //   ],
        // ],
        // email: ["", Validators.nullValidator],
        // adharNo: ["", Validators.nullValidator],
        // panNo: ["", Validators.nullValidator],
        // wardId: ["", Validators.nullValidator],
        // sectorId: ["", Validators.nullValidator],
        // nodeId: ["", Validators.nullValidator],
        // plotId: ["", Validators.nullValidator],
        // survey: ["", Validators.nullValidator],
        // surveyNo: ["", Validators.nullValidator],
        // flatNo: ["", Validators.nullValidator],
        // buildingId: ["", Validators.nullValidator],
        // societyName: ["", Validators.nullValidator],
        // areaId: ["", Validators.nullValidator],
        // landmark: ["", Validators.nullValidator],
        // village: ["", Validators.nullValidator],
        // city: ["", Validators.nullValidator],
        // pinCode: ["", Validators.nullValidator],
        // latitude: ["", Validators.nullValidator],
        // longitude: ["", Validators.nullValidator],
        // postalAddress: [""],
      }),

      step2: this.fb.group({
        usageTypeId: ["", Validators.required],
        subUsageTypeId: ["", Validators.required],
        areaSqMtr: ["", Validators.required],
        parking: [""],
        parkingAreaSqMtr: [""],
        totalArea: ["", Validators.required],
        constructionType: ["", Validators.required],
        propertyTypeId: ["", Validators.required],
        propertySubTypeId: ["", Validators.required],
        occupancy: ["", Validators.required],
        ownershipType: ["", Validators.required],
        bussinessType: ["", Validators.required],
        industryType: ["", Validators.required],
        buildingPermissionNo: ["", Validators.required],
        buildingPermissionDate: ["", Validators.required],
        buildingCompletionDate: ["", Validators.required],
        occupancyCertificateNo: ["", Validators.required],
        occupancyCertificateDate: ["", Validators.required],
        actualDateOfBuildingUsageTime: ["", Validators.required],
        amenities: ["", Validators.required],
        ammenitiesNames: [""],
        propertyStatus: ["", Validators.required],

        // usageTypeId: ["", Validators.nullValidator],
        // subUsageTypeId: ["", Validators.nullValidator],
        // areaSqMtr: ["", Validators.nullValidator],
        // parking: [""],
        // parkingAreaSqMtr: [""],
        // totalArea: ["", Validators.nullValidator],
        // constructionType: ["", Validators.nullValidator],
        // propertyTypeId: ["", Validators.nullValidator],
        // propertySubTypeId: ["", Validators.nullValidator],
        // occupancy: ["", Validators.nullValidator],
        // ownershipType: ["", Validators.nullValidator],
        // bussinessType: ["", Validators.nullValidator],
        // industryType: ["", Validators.nullValidator],
        // buildingPermissionNo: ["", Validators.nullValidator],
        // buildingPermissionDate: ["", Validators.nullValidator],
        // buildingCompletionDate: ["", Validators.nullValidator],
        // occupancyCertificateNo: ["", Validators.nullValidator],
        // occupancyCertificateDate: ["", Validators.nullValidator],
        // actualDateOfBuildingUsageTime: ["", Validators.nullValidator],
        // amenities: ["", Validators.nullValidator],
        // ammenitiesNames: [""],
        // propertyStatus: ["", Validators.nullValidator],
      }),
      step3: this.fb.group({
        waterConnectionType: ["", Validators.required],
        noOfWaterConnections: ["", Validators.required],
        waterConnectionNo: ["", Validators.required],
        diameter: ["", Validators.required],
        bankName: ["", Validators.required],
        bankAccountNumber: ["", Validators.required],
        bankIfsc: ["", Validators.required],
        bankBranch: ["", Validators.required],

        // waterConnectionType: ["", Validators.nullValidator],
        // noOfWaterConnections: ["", Validators.nullValidator],
        // waterConnectionNo: ["", Validators.nullValidator],
        // diameter: ["", Validators.nullValidator],
        // bankName: ["", Validators.nullValidator],
        // bankAccountNumber: ["", Validators.nullValidator],
        // bankIfsc: ["", Validators.nullValidator],
        // bankBranch: ["", Validators.nullValidator],
      }),
      // bankId: [""],
      // requiredDocuments: [""],
      // areaInSqMtr: [""],
      activeFlag: ["Y"],
    });

    this.getNodeData();
    this.getWard();
    this.getSectorData();
    this.getplot();
    this.getBankdata();
    this.getBuilding();
    this.getusage();
    this.getSubUsage();
    this.getPropertytype();
    this.getAmmenities();
    this.getArea();
  }

  nextClicked(stepper: MatStepper): void {
    const step1Form = this.form.get("step1") as FormGroup | null;

    if (step1Form && step1Form.invalid) {
      // console.log(step1Form)
      this.markFormGroupTouched(step1Form);
      return;
    }

    stepper.next();
  }

  nextClicked2(stepper: MatStepper): void {
    const step2Form = this.form.get("step2") as FormGroup | null;
    console.log(step2Form.valid);
    console.log(step2Form.value);
    if (step2Form && step2Form.invalid) {
      // console.log(step2Form)
      this.markFormGroupTouched(step2Form);
      return;
    }

    stepper.next();
  }

  nextClicked3(stepper: MatStepper): void {
    const step3Form = this.form.get("step3") as FormGroup | null;

    if (step3Form && step3Form.invalid) {
      // console.log(step3Form)
      this.markFormGroupTouched(step3Form);
      return;
    }

    stepper.next();
  }

  markFormGroupTouched(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  getNodeData() {
    this.nodeService.getActiveNode().then((data: any) => {
      this.nodes = data["nodeMasterList"];
    });
  }

  onNodeChange(event: any) {
    // console.log(event.value)
    this.form.patchValue({ nodeId: event.value });
  }

  getZoneData() {
    this.zoneService.getZone().then((data: any) => {
      this.zonesData = data["zoneMasterList"];
      this.zonesData = this.zonesData.sort((a, b) => a.id - b.id);
    });
  }
  getWard() {
    this.wardService.getWard().then((data: any) => {
      this.wards = data["wardMasterList"];
      // console.log(data)
    });
  }

  getArea() {
    this.commonService.getByActiveFlagArea().subscribe(
      (data: any) => {
        this.area = data["areaMasterList"];
        // console.log(data)
      },
      (error) => {
        console.error(error);
        // Handle error if needed
      }
    );
  }

  getSectorData() {
    this.sectorService.getSector().then((data: any) => {
      this.sectors = data["sectorMasterList"];
    });
  }

  getplot() {
    this.taxSErvice.getplotdata().subscribe(
      (data) => {
        this.plots = data["plotList"];
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getBankdata() {
    this.taxSErvice.getbank().subscribe(
      (res) => {
        this.banks = res["mstBankDaoList"];
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getBuilding() {
    this.taxSErvice.getbuilding().subscribe(
      (res) => {
        this.building = res["buildingList"];
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getusage() {
    this.taxSErvice.getusagetype().subscribe(
      (res) => {
        this.usageData = res["usagetypeList"];
      },
      (err) => {}
    );
  }

  getSubUsage() {
    this.taxSErvice.getsubusagetype().subscribe(
      (res) => {
        // console.log(res)
        this.subUsageData = res["subusagetypeList"];
      },
      (err) => {}
    );
  }

  getPropertytype() {
    this.taxSErvice.getpropertytype().subscribe((res) => {
      // console.log(res);
      this.propertyTypeData = res["propertyTypeList"];
    });
  }

  changepropertytype(data: any) {
    this.taxSErvice.getpropertysubtypebyid(data).subscribe(
      (res) => {
        // console.log(res)
        this.propertySubtypeData = res;
        // console.log(this.propertySubtypeData);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  AmmenitiesData: any;
  getAmmenities() {
    this.taxSErvice.getamenities().subscribe(
      (res) => {
        this.AmmenitiesData = res["ammenitiesList"];
        // console.log(this.AmmenitiesData)
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ammenitiesName(data: any) {
    const amenitiesNames: string[] = [];
    data.forEach((id) => {
      const foundAmenity = this.AmmenitiesData.find(
        (amenity) => amenity.id === id
      );

      if (foundAmenity) {
        amenitiesNames.push(foundAmenity.ammenitiesName);
      }
    });
    const data3 = amenitiesNames.join(",");
    // this.form.get('ammenitiesNames').setValue(data3);
  }

  radioChangeGender(event: MatRadioChange) {
    if (event.value == "Male") {
      this.form.patchValue({ gender: "Male" });
    } else if (event.value == "Female") {
      this.form.patchValue({ gender: "Female" });
    } else {
      this.form.patchValue({ gender: "Other" });
    }
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  uploadRequiredDocs(event: any) {
    this.loadingPayment = true;

    for (var i = 0; i < event.target.files.length; i++) {
      if (event.target.files[i].type.includes("image")) {
        this.uploadService
          .uploadFile(event.target.files[i])
          .then((data: any) => {
            this.uploadedDocPath = JSON.parse(data).filePath;
            this.form.patchValue({ requiredDocuments: this.uploadedDocPath });
          });
      }
    }
  }

  savePropTax(event: any) {
    const formDataArray = [
      {
        ...this.form.value.step1,
        ...this.form.value.step2,
        ...this.form.value.step3,
        activeFlag: this.form.value.activeFlag,
      },
    ];

    // Log errors for each control
    // Object.keys(this.form.controls).forEach(controlName => {
    //   const control = this.form.get(controlName);
    //   console.log(`${controlName} validity:`, control?.valid);
    //   console.log(`${controlName} errors:`, control?.errors);
    // });
    if (!this.form.valid) {
      this.form.markAllAsTouched();
    }
    if (this.form.valid) {
      // console.log(formDataArray)
      const convertForm = { ...formDataArray[0] };

      if (Array.isArray(convertForm["amenities"])) {
        if (convertForm["amenities"].length > 0) {
          convertForm["amenities"] = convertForm["amenities"].join(",");
        } else {
          convertForm["amenities"] = "";
        }
      } else {
        convertForm["amenities"] = "";
      }

      console.log(convertForm);

      const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
        width: "550px",
        data: {
          mode: "Save",
          component: "Property Tax Registration",
        },
        autoFocus: false,
        restoreFocus: false,
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result == "success") {
          // this.taxSErvice.savePropertyTaxRegistration(convertForm).subscribe(
          //   (res) => {
          //     if ((res["status"] = "Success")) {
          //       setTimeout(() => {
          //         this.router.navigateByUrl(
          //           "/property-tax/propertyTax-scrutiny"
          //         );
          //         // this.dialogRef.close({ data: 'Success' });
          //       }, 1000);
          //     }
          //     console.log(res);
          //   },
          //   (err) => {
          //     console.log(err);
          //   }
          // );
        }
      });
    }
  }
}
