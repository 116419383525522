


 <div class="grid grid-rows-2 grid-flow-col gap-2">
  <div  class="row-span-3 ... bg-slate-200">
    <div class="p-4">
      <h2 class="text-2xl font-semibold">Application Details</h2>
      <hr>

      <table mat-table [dataSource]="dataSource" *ngIf="dataSource.data.length > 0 && contentLoaded">
        <ng-container matColumnDef="key">
          <th mat-header-cell *matHeaderCellDef>Key</th>
          <td mat-cell *matCellDef="let element">{{element.key}}</td>
        </ng-container>
      
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>Value</th>
          <td mat-cell *matCellDef="let element">{{element.value}}</td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator 
      [pageSizeOptions]="[6,10,50]"
       showFirstLastButtons></mat-paginator>
  </div>
  </div>
  <div class="row-span-4 ...">
    <div class="p-4">
      <h2 class="text-2xl font-semibold">{{action}}</h2>
      <hr class="pb-2">
       <div class="w-full  " *ngIf="appStatus == 'SITE_VISIT_SCHEDULED'">
        <form  [formGroup]="form" (ngSubmit)="saveScrutinyDetailsForSiteSchedule()">
  
            <div >
            
            <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                        
                       
  
                        <label for="message" class=" block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Site Visit Images</label>
                        <button   (click)="fileSite.click()" [disabled]="showSiteVisitImageUpload" type="button" class="disabled:bg-gray-300 border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
                        Image upload 
                        </button>
                        <input #fileSite type="file" multiple (change)="uploadSiteVisitImages($event)" name="my_file" id="my-file" >
                        <p *ngIf="showSiteVisitImageUpload == true" class="text-sm font-light text-lime-500 dark:text-gray-400">
                            Site image uploaded successfully 
                        </p>
                        <p *ngIf="showSiteVisitImageUpload == false" class="text-sm font-dark text-red-600 dark:text-gray-400">
                            please select upto 3 images 
                        </p>
  
                     
                    </div>   
                </li>
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
  
                    <div class="mb-6 p-3">
                        
                       
  
                        <label for="message" class=" block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Jabab</label>
                        <button   (click)="fileJabab.click()" [disabled]="showJababUpload" type="button" class="disabled:bg-gray-300 border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
                        Jabab file upload 
                        </button>
                        <input #fileJabab type="file"  (change)="uploadJabab($event)" name="my_file" id="my-file" >
                        <p *ngIf="showJababUpload == true" class="text-sm font-light text-lime-500 dark:text-gray-400">
                           Jabab uploaded successfully 
                        </p>
                      
  
                     
                    </div>  
                </li>
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    
                    <div class="mb-6 p-3">
                        
                       
  
                        <label for="message" class=" block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Panchanama</label>
                        <button   (click)="filePanchanama.click()" [disabled]="showpanchanamaUpload" type="button" class="disabled:bg-gray-300 border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
                            panchanama file upload 
                        </button>
                        <input #filePanchanama type="file"  (change)="uploadpanchanama($event)" name="my_file" id="my-file" >
                        <p *ngIf="showpanchanamaUpload == true" class="text-sm font-light text-lime-500 dark:text-gray-400">
                            panchanama uploaded successfully 
                        </p>
                      
  
                     
                    </div>  
                </li>
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
  
                    <div class="mb-6 p-3">
                        
                       
  
                        <label for="message" class=" block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Pahaniahwal</label>
                        <button   (click)="filePahani.click()" [disabled]="showpahaniahwalUpload" type="button" class="disabled:bg-gray-300 border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
                            pahaniahwal file upload 
                        </button>
                        <input #filePahani type="file"  (change)="uploadpahaniahwal($event)" name="my_file" id="my-file" >
                        <p *ngIf="showpahaniahwalUpload == true" class="text-sm font-light text-lime-500 dark:text-gray-400">
                            pahaniahwal uploaded successfully 
                        </p>
                      
  
                     
                    </div>  
                   
                </li>
                
                
            </ul>
            </div>
  
  
            <div class="float-right bottom-0 pt-12	">
                <button
                id="button"
                type="submit"
                [disabled]="showSiteVisitImageUpload == false || showJababUpload == false || showpanchanamaUpload == false || showpahaniahwalUpload == false"
  
                class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
              >
                Submit
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
              id="button"
              (click)="cancel()"
              type="button"
              class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-red-500 hover:bg-red-600 hover:shadow-lg focus:outline-none"
            >
              Cancel
            </button>
            </div>
  
        </form>
        
    </div>
  
    <div class="w-full " *ngIf="appStatus == 'SITE_VISIT_COMPLETED'">
        <form  (ngSubmit)="saveScrutinyDetailsFoSiteVisitCompleted()">
  
            <div >
            
            <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                      
                        <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">1. Site Image 1</label><br><br>
                        <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Uploaded</div>
                          </div>
                          <br>
                          <br>
  
                          <div>
                            <a href="javascript:void(0);" (click)="openDocument(applicationData[0]['siteVisitImage1'])">View</a>
                          </div>
                    </div>   
  
                   
                   
                </li>
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                        <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">2. Site Image 2</label><br><br>
                        <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Uploaded</div>
                          </div>
                          <br>
                          <br>
  
                          <div>
                            <a href="javascript:void(0);" (click)="openDocument(applicationData[0]['siteVisitImage2'])">View</a>
                          </div>
                    </div>   
                </li>
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                        <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">3. Site Image 3</label><br><br>
                        <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Uploaded</div>
                          </div>
                          <br>
                          <br>
  
                          <div>
                            <a href="javascript:void(0);" (click)="openDocument(applicationData[0]['siteVisitImage3'])">View</a>
                          </div>
                    </div>   
                </li>
                
            </ul>
           
            </div>
  
  
  
            <div class="float-right bottom-0 pt-12">
                <button
                id="button"
                type="submit"
                class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
              >
                Approve
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
              id="button"
              (click)="rejectApplication()"
              type="button"
              class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-red-500 hover:bg-red-600 hover:shadow-lg focus:outline-none"
            >
              Reject
            </button>
            </div>
  
        </form>
        
    </div>
  
    <div class="w-full " *ngIf="appStatus == 'OS_APPROVED'">
        <form  [formGroup]="form" (ngSubmit)="saveScrutinyDetailsForOSApproved()">
  
            <div >
            
            <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
               
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
  
                    <div class="mb-6 p-3">
                        
                       
  
                        <label for="message" class=" block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Prastav</label>
                        <button   (click)="filePrastav.click()" [disabled]="showPrastavUpload" type="button" class="disabled:bg-gray-300 border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
                            Prastav file upload 
                        </button>
                        <input #filePrastav type="file"  (change)="uploadPrastav($event)" name="my_file" id="my-file" >
                        <p *ngIf="showPrastavUpload == true" class="text-sm font-light text-lime-500 dark:text-gray-400">
                           Prastav uploaded successfully 
                        </p>
                      
  
                     
                    </div>  
                </li>
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    
                    <div class="mb-6 p-3">
                        
                       
  
                        <label for="message" class=" block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Pratidnyapatra</label>
                        <button   (click)="filePratidnyapatra.click()" [disabled]="showPratidnyaPatraUpload" type="button" class="disabled:bg-gray-300 border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
                            Pratidnyapatra file upload 
                        </button>
                        <input #filePratidnyapatra type="file"  (change)="uploadPratidnyaPatra($event)" name="my_file" id="my-file" >
                        <p *ngIf="showPratidnyaPatraUpload == true" class="text-sm font-light text-lime-500 dark:text-gray-400">
                            Pratidnyapatra uploaded successfully 
                        </p>
                      
  
                     
                    </div>  
                </li>
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
  
                    <div class="mb-6 p-3">
                        
                       
  
                        <label for="message" class=" block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Vishesh Notice</label>
                        <button   (click)="filePahani.click()" [disabled]="showVisheshNoticeUpload" type="button" class="disabled:bg-gray-300 border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
                            Vishesh Notice file upload 
                        </button>
                        <input #filePahani type="file"  (change)="uploadVisheshNotice($event)" name="my_file" id="my-file" >
                        <p *ngIf="showVisheshNoticeUpload == true" class="text-sm font-light text-lime-500 dark:text-gray-400">
                            Vishesh Notice uploaded successfully 
                        </p>
                      
  
                     
                    </div>  
                   
                </li>
                
                
            </ul>
            </div>
  
      
  
       
  
            <div class="flex float-right bottom-0 pt-12 space-x-4">
              <button
              id="button"
              type="submit"
              [disabled]="showVisheshNoticeUpload == false || showPratidnyaPatraUpload == false || showPrastavUpload == false"
  
              class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
            >
              Submit
            </button>
            <button
            id="button"
            (click)="rejectOSApproval()"
            type="button"
            class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-red-500 hover:bg-red-600 hover:shadow-lg focus:outline-none"
          >
            Cancel
          </button>
          </div>
  
  
         
  
        </form>
        
    </div>
  
    <div class="w-full " *ngIf="appStatus == 'NOTICE_GENERATED'">
        <form  (ngSubmit)="saveScrutinyDetailsFoNoticeGenerated()">
  
            <div >
            
            <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                      
                        <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">1. Prastav</label><br><br>
                        <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Uploaded</div>
                          </div>
                          <br>
                          <br>
  
                          <div>
                            <a href="javascript:void(0);" (click)="openDocument(applicationData[0]['prastav'])">View</a>
                          </div>
                    </div>   
  
                   
                   
                </li>
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                        <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">2. Pratidnyapatra</label><br><br>
                        <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Uploaded</div>
                          </div>
                          <br>
                          <br>
  
                          <div>
                            <a href="javascript:void(0);" (click)="openDocument(applicationData[0]['pratidyapatr'])">View</a>
                          </div>
                    </div>   
                </li>
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                        <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">3. Vishesh Notice</label><br><br>
                        <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Uploaded</div>
                          </div>
                          <br>
                          <br>
  
                          <div>
                            <a href="javascript:void(0);" (click)="openDocument(applicationData[0]['visheshNotice'])">View</a>
                          </div>
                    </div>   
                </li>
                
            </ul>
           
            </div>
  
            <div class="mb-6 p-3">
                        
                       
  
                <label for="message" class=" block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Signed Pratidnya Patra</label>
                <button   (click)="signedpratidyapatr.click()" [disabled]="showSignedpratidyapatrUpload" type="button" class="disabled:bg-gray-300 border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
                   Signed Pratidnyapatra file upload 
                </button>
                <input #signedpratidyapatr type="file"  (change)="uploadSignedPratidyPatra($event)" name="my_file" id="my-file" >
                <p *ngIf="showSignedpratidyapatrUpload == true" class="text-sm font-light text-lime-500 dark:text-gray-400">
                    Signed Pratidnyapatra uploaded successfully 
                </p>
              
  
             
            </div>  
  
  
  
            <div class="float-right bottom-0 pt-12 pt-12">
                <button
                id="button"
                type="submit"
                class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
              >
                Approve
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
              id="button"
              (click)="rejectApplicationForNg()"
              type="button"
              class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-red-500 hover:bg-red-600 hover:shadow-lg focus:outline-none"
            >
              Reject
            </button>
            </div>
  
        </form>
        
    </div>
  
    <div class="w-full " *ngIf="appStatus == 'NOTICE_APPROVED'">
        <form  (ngSubmit)="saveScrutinyDetailsForForwardHod()">
  
            <div >
            
            <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                      
                        <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">1. Signed Pratidnya Patra</label><br><br>
                        <div *ngIf="applicationData[0]['signedpratidyapatr'] != '' && applicationData[0]['signedpratidyapatr'] != null && applicationData[0]['signedpratidyapatr'] != 'null'" class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Uploaded</div>
                          </div>
                          <div *ngIf="applicationData[0]['signedpratidyapatr'] == '' || applicationData[0]['signedpratidyapatr'] == null || applicationData[0]['signedpratidyapatr'] == 'null'" class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Not Uploaded</div>
                          </div>
                          <br>
                          <br>
  
                          <div>
                            <a href="javascript:void(0);" (click)="openDocument(applicationData[0]['signedpratidyapatr'])">View</a>
                          </div>
                    </div>   
  
                   
                   
                </li>
              
                
            </ul>
           
            </div>
  
  
  
            <div class="float-right bottom-0 pt-12">
                <button
                id="button"
                type="submit"
                class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
              >
                Approve
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
              id="button"
              (click)="rejectApplicationForwardHod()"
              type="button"
              class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-red-500 hover:bg-red-600 hover:shadow-lg focus:outline-none"
            >
              Reject
            </button>
            </div>
  
        </form>
        
    </div>
  
    <div class="w-full " *ngIf="appStatus == 'NOTICE_ISSUED'">
        <form  (ngSubmit)="saveScrutinyDetailsFoNoticeIssued()">
  
            <div >
            
            <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                      
                        <div>
                            <label for="sunavaniDate" class="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-2">Sunavani Date:</label>
                            <input required="" type="date" id="license-issue-date" formControlName="sunavaniDate"
                              class="w-80 border rounded py-2 px-3">
                          </div>
                         
                    </div>   
  
                   
                   
                </li>
              
                
            </ul>
           
            </div>
  
  
  
            <div class="float-right bottom-0 pt-12">
                <button
                id="button"
                type="submit"
                class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
              >
                Approve
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
              id="button"
              (click)="rejectApplicationForNoticeIssued()"
              type="button"
              class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-red-500 hover:bg-red-600 hover:shadow-lg focus:outline-none"
            >
              Reject
            </button>
            </div>
  
        </form>
        
    </div>
  
    <div class="w-full " *ngIf="appStatus == 'SUNAVANI_SCHEDULED'">
        <form  (ngSubmit)="saveScrutinyDetailsForSunavaniScheduled()">
  
         
  
            <div class="mb-6 p-3">
                        
                       
  
                <label for="message" class=" block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Aadesh</label>
                <button   (click)="Aadesh.click()" [disabled]="showAadeshUpload" type="button" class="disabled:bg-gray-300 border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
                   Aadesh file upload 
                </button>
                <input #Aadesh type="file"  (change)="uploadAadesh($event)" name="my_file" id="my-file" >
                <p *ngIf="showAadeshUpload == true" class="text-sm font-light text-lime-500 dark:text-gray-400">
                    Aadesh uploaded successfully 
                </p>
              
  
             
            </div>  
  
  
  
            <div class="float-right bottom-0 pt-12">
                <button
                id="button"
                type="submit"
                [disabled]="showAadeshUpload == false"
  
                class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
              >
                Submit
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
              id="button"
              (click)="cancel()"
              type="button"
              class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-red-500 hover:bg-red-600 hover:shadow-lg focus:outline-none"
            >
              Cancel
            </button>
            </div>
  
        </form>
        
    </div>
  
    <div class="w-full " *ngIf="appStatus == 'AADESH_GENERATED'">
        <form  (ngSubmit)="saveScrutinyDetailsForAadeshGenerated()">
  
            <div >
            
            <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="mb-6 p-3">
                      
                        <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">1. Aadesh</label><br><br>
                        <div *ngIf="applicationData[0]['aadesh'] != '' && applicationData[0]['aadesh'] != null && applicationData[0]['aadesh'] != 'null'" class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Uploaded</div>
                          </div>
                          <div *ngIf="applicationData[0]['aadesh'] == '' || applicationData[0]['aadesh'] == null || applicationData[0]['aadesh'] == 'null'" class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-teal-500 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white">
                            <div class="mt-px">Not Uploaded</div>
                          </div>
                          <br>
                          <br>
  
                          <div>
                            <a href="javascript:void(0);" (click)="openDocument(applicationData[0]['aadesh'])">View</a>
                          </div>
                    </div>   
  
                   
                   
                </li>
              
                
            </ul>
           
            </div>
  
  
  
            <div class="float-right bottom-0 pt-12">
                <button
                id="button"
                type="submit"
                class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
              >
                Approve
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
              id="button"
              (click)="rejectApplicationForAadeshGenerated()"
              type="button"
              class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-red-500 hover:bg-red-600 hover:shadow-lg focus:outline-none"
            >
              Reject
            </button>
            </div>
  
        </form>
        
    </div>
  
    <div class="w-full " *ngIf="appStatus == 'AADESH_APPROVED'">
        <form  (ngSubmit)="saveScrutinyDetailsForAadeshApproved()">
  
       
  
            <div class="float-right bottom-0 pt-12">
                <button
                id="button"
                type="submit"
                class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
              >
                Approve
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
              id="button"
              (click)="rejectApplicationForAadeshApproval()"
              type="button"
              class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-red-500 hover:bg-red-600 hover:shadow-lg focus:outline-none"
            >
              Reject
            </button>
            </div>
  
        </form>
        
    </div>
  
    <div class="w-full " *ngIf="appStatus == 'APPLICATION_APPROVED'">
        <form  (ngSubmit)="saveScrutinyDetailsForApplicationApproved()">
  
          <div class="flex justify-center items-center space-x-4">
            <button
            id="button"
            type="submit"
            class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
          >
            Approve
          </button>
          <button
          id="button"
          (click)="rejectApplicationForApplicatonApproved()"
          type="button"
          class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-red-500 hover:bg-red-600 hover:shadow-lg focus:outline-none"
        >
          Reject
        </button>
        </div>
  
         
  
        </form>
        
    </div>
    </div>
  
  </div>
  <!-- <div class="row-span-2 col-span-2 ...">03</div> -->

  
</div>