import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';

@Component({
  selector: 'app-add-committee-establishment',
  templateUrl: './add-committee-establishment.component.html',
  styleUrls: ['./add-committee-establishment.component.scss']
})
export class AddCommitteeEstablishmentComponent {
  createdUserId:any;
  updateUserId:any;
  userId: string;
  committeeName: string = '';
  establishmentDate:any;
  dismiisedDate:any;
  isWorking:boolean=false;
  addedit: any;
  id = null;
  errorMessage:string=""

  constructor(private msmService: MsmamServiceService, private _snackBar: SnackbarMsgService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddCommitteeEstablishmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data1: any) {
   
  }
  ngOnInit(){
    this.addedit = this.data1['isEditMode'];
    this.userId = localStorage.getItem("userId");
    this.createdUserId=this.userId;
    this.updateUserId=this.userId;
    if (this.addedit == true) {
      const data12 = this.data1['row'];
      this.createdUserId=data12['createdUserId'];
      this.updateUserId=this.userId;
      this.committeeName = data12['committeeName'];
      this.establishmentDate=data12['establishmentDate'];
      this.dismiisedDate=data12['dismiisedDate'];
      this.isWorking=data12['isWorking'];

      this.id = data12['id'];
    }
  }
  saveIncident() {
   
    const data = {
      "id": this.id,
      "committeeName": this.committeeName,
      "establishmentDate":this.establishmentDate ,
      "dismiisedDate":this.dismiisedDate,
      "isWorking":this.isWorking,
      "createdUserId":this.createdUserId,
      "updateUserId":this.updateUserId,
      "activeFlag": "Y"
    };


    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: "550px",
      data: {
        mode: this.addedit ? 'Edit' : 'Add',
        component: 'Meeting Type'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        this.msmService.saveCommitteeestablishment(data).subscribe(
          res => {
            if (res['status'] === 'Success') {
              setTimeout(() => {
                this.dialogRef.close({ data: 'Success' });
              }, 1000);
            }else{
              this.errorMessage=res['status']
            }
            // this._snackBar.success(this.addedit ? 'committee Edited successfully!' : 'committee Added successfully!')

          },
          err => {
            this._snackBar.error('committee not added')
          }
        )
      }
    })
  }
}
