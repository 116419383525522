<!-- <img [src]="data" alt="Image" style="max-width: 100%;"> -->
<div *ngIf="isImageType(data)" style="width:700px; min-height:300px">
  <img [src]="data" alt="Image">
</div>

<!-- <div *ngIf="isPdfType(data)">
    <div id="pdfContainer"></div>
    <iframe [src]="data | safe" width="100%" height="100%"></iframe>
  </div> -->
<!-- <embed *ngIf="isPdfType(data)" [src]="data | safe" type="application/pdf" width="100%" height="600"> -->
<div *ngIf="isPdfType(data)">
  <ngx-doc-viewer [url]="data" viewer="google" style="width:700px; height:500px">
  </ngx-doc-viewer>
</div>