<div class="bg-gray-100 p-6">
  <mat-icon
    id="close-icon"
    style="float: right; cursor: pointer"
    class="close-icon"
    [mat-dialog-close]="true"
  >
    close
  </mat-icon>

  <div class="mx-auto max-w-md">
    <h1 class="text-2xl font-bold mb-8">
      {{ addedit ? "Edit" : "Add" }} Clerk to Committee
    </h1>

    <form (ngSubmit)="save()" [formGroup]="form">
      <mat-form-field appearance="outline" class="w-full">
        <mat-select
          placeholder="Select Committee Name"
          formControlName="committeeId"
        >
          <mat-option
            *ngFor="let option of committeeNameList"
            [value]="option.id"
          >
            {{ option.committeeName }}
          </mat-option>
          <!-- *ngIf="mediaChannelNameList.length === 0" -->
          <mat-option [value]="'noOptions'">No options available</mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('committeeId').hasError('required')">
          Committee Name is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-full">
        <mat-select placeholder="Select Clerk Name" formControlName="clerkId">
          <mat-option *ngFor="let option of userList" [value]="option.id">
            {{ option.userFirstName }} {{ option.userMiddleName }}
            {{ option.userLastName }}
          </mat-option>
          <!-- *ngIf="mediaChannelNameList.length === 0" -->
          <mat-option [value]="'noOptions'">No options available</mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('clerkId').hasError('required')">
          Clerk Name is required.
        </mat-error>
      </mat-form-field>
      <button
        type="submit"
        class="w-full disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
        [disabled]="!form.valid"
      >
        Save
      </button>
      <div class="text-center text-red-500 mt-2">
        {{ errorMessage }}
      </div>
    </form>
  </div>
</div>
