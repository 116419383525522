
<div  class=" bg-gray-100 p-6">
    <mat-icon id="close-icon" style="float: right;cursor: pointer;" class="close-icon" [mat-dialog-close]="true">close</mat-icon>
  
      <div class="mx-auto max-w-md ">
        <h1 class="text-2xl font-bold mb-8">{{action}} Center</h1>
        <form [formGroup]="form" (ngSubmit)="saveCenter()">
  
            <div class="relative z-0 w-full">
                <mat-form-field appearance="outline" class="w-full">
                    <mat-select placeholder="Select Center Type" formControlName="centerTypeId"  >
                        <mat-option *ngFor="let option of centerType" [value]="option.id">
                           {{option.centerTypeName}}
                        </mat-option>
                        <mat-option *ngIf="centerType.length === 0" [value]="'noOptions'">No options available</mat-option>
                    </mat-select>
                </mat-form-field>
              </div>

          <div class="relative z-0 w-full mb-5">
            <label for="department" class="block text-gray-600">Center Name</label>
            <input
              type="text"
              id="centerName"
              name="centerName"
              class="w-full border rounded-md px-4 py-2 focus:outline-none focus:border-blue-400"
              formControlName="centerName"
              required=""
            />
            <!-- <input
              type="text"
              name="name"
              placeholder=" "
              required
              [(ngModel)]="department"
              class="pt-3 pb-2 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-black border-gray-200"
            /> -->
            <!-- <label for="name" class="absolute duration-300 top-3 -z-1 origin-0 text-gray-500">Department Name *</label> -->
          </div>
  
      
  
          <button
            id="button"
            type="submit"
            [disabled]="!form.valid"
            class="w-full disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
          >
            Save
          </button>
          </form>
      </div>
    </div>