import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MaterialManagementServiceService {
  getCategoryList(categoryId: number) {
    throw new Error("Method not implemented.");
  }

  constructor(private httpClient: HttpClient) {}

  getManufacture() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstmanufacturer/getAllDashboard`
    );
  }
  getByActiveFlagManufacture() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstmanufacturer/getAll`
    );
  }
  changeFlagManufacture(id, flag) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstmanufacturer/changeFlag?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveManufacture(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstmanufacturer/save`,
      data
    );
  }

  getMMCategory() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstmaterialcategory/getAllDashboard`
    );
  }
  getByActiveFlagMMCategory() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstmaterialcategory/getAll`
    );
  }
  changeFlagMMCategory(id, flag) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstmaterialcategory/changeFlag?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveMMCategory(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstmaterialcategory/save`,
      data
    );
  }

  getMaterial() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstMaterialName/getAllDashboard`
    );
  }
  getByActiveFlagMaterial() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstMaterialName/getAll`
    );
  }
  changeFlagMaterial(id, flag) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstMaterialName/activeInActive?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveMaterial(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstMaterialName/save`,
      data
    );
  }

  getMMSubCategory() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstsubmaterialcategory/getAllDashboard`
    );
  }
  getMMSubCategoryByCategoryId(categoryId) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstsubmaterialcategory/getById?id=${categoryId}`
    );
  }
  getByActiveFlagMMSubCategory() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstsubmaterialcategory/getAll`
    );
  }
  changeFlagMMSubCategory(id, flag) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstsubmaterialcategory/changeFlag?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveMMSubCategory(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstsubmaterialcategory/save`,
      data
    );
  }

  getMMGroup() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstmaterialgroup/getAllDashboard`
    );
  }
  getByActiveFlagMMGroup() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstmaterialgroup/getAll`
    );
  }
  changeFlagMMGroup(id, flag) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstmaterialgroup/changeFlag?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveMMGroup(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstmaterialgroup/save`,
      data
    );
  }

  getMMItem() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstitem/getAllDashboard`
    );
  }
  getByActiveFlagMMItem() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstitem/getAll`
    );
  }
  changeFlagMMItem(id, flag) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstitem/changeFlag?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveMMItem(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstitem/save`,
      data
    );
  }

  getStore() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mststore/getAllDashboard`
    );
  }
  getByActiveFlagStore() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mststore/getAll`
    );
  }
  changeFlagStore(id, flag) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mststore/changeFlag?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveStore(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mststore/save`,
      data
    );
  }

  getSupplier() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstsupplier/getAllDashboard`
    );
  }
  getByActiveFlagSupplier() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstsupplier/getAll`
    );
  }
  changeFlagSupplier(id, flag) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstsupplier/changeFlag?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveSupplier(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstsupplier/save`,
      data
    );
  }

  getUnit() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstunit/getAllDashboard`
    );
  }
  getByActiveFlagUnit() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstunit/getAll`
    );
  }
  changeFlagUnit(id, flag) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstunit/changeFlag?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveUnit(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstunit/save`,
      data
    );
  }

  getUnitRate() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstunitrate/getAllDashboard`
    );
  }
  getByActiveFlagUnitRate() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstunitrate/getAll`
    );
  }
  changeFlagUnitRate(id, flag) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstunitrate/changeFlag?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveUnitRate(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstunitrate/save`,
      data
    );
  }

  //center
  getCenter() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/center/getAllDashboard`
    );
  }
  getByActiveFlagCenter() {
    return this.httpClient.get(`${environment.mmUrl}/api/master/center/getAll`);
  }
  changeFlagCenter(id, flag) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/center/activeInActive?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveCenter(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/center/save`,
      data
    );
  }

  //center type
  getCenterType() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/centertype/getAllDashboard`
    );
  }
  getByActiveFlagCenterType() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/centertype/getAll`
    );
  }
  changeFlagCenterType(id, flag) {
    console.log("center type");
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/centertype/activeInActive?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveCenterType(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/centertype/save`,
      data
    );
  }

  //plot no
  getplotnumber() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/plotnumber/getAllDashboard`
    );
  }
  getByActiveFlagplotnumber() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/plotnumber/getAll`
    );
  }
  changeFlagplotnumber(id, flag) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/plotnumber/activeInActive?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveplotnumber(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/plotnumber/save`,
      data
    );
  }

  //challan no
  getmstchallan() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstchallan/getAllDashboard`
    );
  }
  getByActiveFlagmstchallan() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstchallan/getAll`
    );
  }
  changeFlagmstchallan(id, flag) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstchallan/changeFlag?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  savemstchallan(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstchallan/save`,
      data
    );
  }

  //plot type
  getplotType() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/plottype/getAllDashboard`
    );
  }
  getByActiveFlagplotType() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/plottype/getAll`
    );
  }
  changeFlagplotType(id, flag) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/plottype/activeInActive?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveplotType(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/plottype/save`,
      data
    );
  }

  //Material-Name
  getMaterialCategories() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstmaterialcategory/getAll`
    );
  }

  getMaterialSubCategories() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstsubmaterialcategory/getAll`
    );
  }

  getUnits() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstunit/getAll`
    );
  }
  getPackMaster() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstPack/getAll`
    );
  }

  changeActiveFlagMaterialName(id, flag) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstMaterialName/activeInActive?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveMaterialName(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstMaterialName/save`,
      data
    );
  }
  getAllMaterialName() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstMaterialName/getAll`
    );
  }

  // Hospital - ward
  getStoreList() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mststore/getAll`
    );
  }

  getCenterTypeList() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/centertype/getAll`
    );
  }

  getCenterList() {
    return this.httpClient.get(`${environment.mmUrl}/api/master/center/getAll`);
  }

  saveMstHospitalWard(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstHospitalWard/save`,
      data
    );
  }

  getMstHospitalWard() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstHospitalWard/getAll`
    );
  }
  changeFlagHospitalWard(id, flag) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstHospitalWard/activeInActive?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }

  //Dev Rohit 13-06-2024 Material Management |Transaction |  Demand Request
  getdemandrequest() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/materialDemandRequest/getAllUniqueDemandRequestNo`
    );
  }
  savedemandrequest(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/transaction/materialDemandRequest/saveAll`,
      data
    );
  }
  mstMaterialNamegetAll() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstMaterialName/getAll`
    );
  }
  mstPackgetAll() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstPack/getAll`
    );
  }
  changeFlagDemandRequest(id, flag) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/materialDemandRequest/activeInActive?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  getByDemandRequestNo(demandRequestNo) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/materialDemandRequest/getAllByDemandRequestNo?demandRequestNo=${demandRequestNo}`
    );
  }

  getworkOrderWithoutTender() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/workOrderWithoutTender/getAllUniqueWorkOrderWithoutTender`
    );
  }
  saveworkOrderWithoutTender(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/transaction/workOrderWithoutTender/saveAll`,
      data
    );
  }
  getByworkOrderWithoutTenderNo(workOrderNo) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/workOrderWithoutTender/getAllByWorkOrderWithoutTenderNo?workOrderWithoutTenderNo=${workOrderNo}`
    );
  }
  changeFlagworkOrderWithoutTender(id, flag) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/workOrderWithoutTender/activeInActive?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }

  
  getDeatilsByCatIdSubCatIdMaterialName(
    materialId,
    catId,
    subCatId,
  ) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/minimumReOrderLevel/getByMaterialIdAndCategoryIdAndSubCategoryId?materialId=${materialId}&categoryId=${catId}&subCategoryId=${subCatId}`
    );
  }

  getByMaterialCategoryId(id) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstsubmaterialcategory/getByMaterialCategoryId?materialCategoryId=${id}`
    );
  }
  getMaterialNames(materialCategoryId, subMaterialCategoryId) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstMaterialName/getAllByMaterialCategoryIdAndSubMaterialCategoryId?materialCategoryId=${materialCategoryId}&subMaterialCategoryId=${subMaterialCategoryId}`
    );

  }
  getAllByMaterialCategoryIdAndSubMaterialCategoryIdAndMaterialName(
    catId,
    subCatId,
    materialName
  ) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstMaterialName/getAllByMaterialCategoryIdAndSubMaterialCategoryIdAndMaterialName?materialCategoryId=${catId}&subMaterialCategoryId=${subCatId}&materialNameId=${materialName}`
    );
  }

  findAllByUnitId(id) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstPack/findAllByUnitId?unitId=${id}`
    );
  }

  getByDemandRequestNoDetails(demandRequestNo) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/materialDistributionAndIndentProcessing/getAllDemandRequestNo?demandRequestNo=${demandRequestNo}`
    );
  }

  // pack master

  getAllUnit() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstunit/getAll`
    );
  }

  savePack(data: any): Observable<any> {
    return this.httpClient.post(
      `${environment.mmUrl}/api/master/mstPack/save`,
      data
    );
  }

  getAllPack() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstPack/getAll`
    );
  }

  //purchase order
  getAllPurchaseOrders(): Observable<any> {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/trnPurchaseOrder/getAll`
    );
  }

  getPurchaseOrderById(id: string): Observable<any> {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/trnPurchaseOrder/getById`,
      { params: { id } }
    );
  }

  savePurchaseOrder(orderData: any): Observable<any> {
    return this.httpClient.post(
      `${environment.mmUrl}/api/transaction/trnPurchaseOrder/save`,
      orderData
    );
  }

  getByActiveFlagPOSupplier() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstsupplier/getAll`
    );
  }
  getBySupplierIdAndPurchaseOrderNo(purchaseOrderNo, SupplierId) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/trnPurchaseOrder/getDetailsBySupplierIdAndPurchaseOrderNo?supplierId=${SupplierId}&purchaseOrderNo=${purchaseOrderNo}`
    );
  }
  trnReceivingMaterialfromSupplier(purchaseOrderNo) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/trnReceivingMaterialfromSupplier/findByPurchaseOrderNo?purchaseOrderNo=${purchaseOrderNo}`
    );
  }

  // spot purchase
  getAllSpotPurchase(): Observable<any> {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/spotPurchase/getAllUniqueSpotPurchaseNo`
    );
  }

  saveSpotPurchase(spotPurchaseData: any): Observable<any> {
    return this.httpClient.post(
      `${environment.mmUrl}/api/transaction/spotPurchase/saveAll`,
      spotPurchaseData
    );
  }

  getAllUniqueSpotPurchaseNo(SpotPurchaseNo) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/spotPurchase/getAllBySpotPurchaseNo?spotPurchaseNo=${SpotPurchaseNo}`
    );
  }

  //Recivied Material

  saveRecivedMaterial(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/transaction/trnReceivingMaterialfromSupplier/save`,
      data
    );
  }

  getSupplierList() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstsupplier/getAll`
    );
  }

  getPurchaseOrderList(Sid: any) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/trnPurchaseOrder/getAllDistinctPurchaseOrderNoBySupplierId?supplierId=${Sid}`
    );
  }

  getBysupplierIdPurchaseorderNo(Sid: any, Pno: any) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/trnPurchaseOrder/getBySupplierIdAndPurchaseOrderNo?supplierId=${Sid}&purchaseOrderNo=${Pno}`
    );
  }
  getAllrecivedMaterial() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/trnReceivingMaterialfromSupplier/getAll`
    );
  }
  getMstmanufacturerAll() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/master/mstmanufacturer/getAll`
    );
  }
  getAllInventoryDetails() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/receivingMaterialInventory/getAll`
    );
  }
  getRecivedMaterialDetails(id: any) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/trnReceivingMaterialfromSupplier/getAllReceivingDetailsById?id=${id}`
    );
  }
  getAllInventoryDetailsById(mId: any) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/receivingMaterialInventory/getAllByReceivedMaterialId?receivedMaterialId=${mId}`
    );
  }

  //Minimum Re-Order

  saveMinimumReOrder(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/transaction/minimumReOrderLevel/saveAll`,
      data
    );
  }

  getUpdateByIdMinimumReOrder(id: any) {
    console.log(id);
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/minimumReOrderLevel/getById?id=${id}`,
      
    );
  }
  updateMinimumReOrder(data: any) {
    console.log(data);
    return this.httpClient.post(
      `${environment.mmUrl}/api/transaction/minimumReOrderLevel/save`,
      data
    );
  }

  //  getPurchaseOrderList(Sid: any) {
  //   return this.httpClient.get(
  //     `${environment.mmUrl}/api/transaction/trnPurchaseOrder/getAllDistinctPurchaseOrderNoBySupplierId?supplierId=${Sid}`
  //   );
  // }

  // getAllMinimumReOrder() {
  //   return this.httpClient.get(
  //     `${environment.mmUrl}/api/transaction/minimumReOrderLevel/getAll`
  //   );
  // }

  getAllMinimumReOrder() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/minimumReOrderLevel/getAllMaterialNameAndMinimumReOrderLevel`
    );
  }


  ///api/transaction/minimumReOrderLevel/getAllMaterialNameAndMinimumReOrderLevel
  //demand request

  saveAllDemandReqNoDetails(data: any) {
    console.log(data)
    return this.httpClient.post(
      `${environment.mmUrl}/api/transaction/materialDistributionAndIndentProcessing/save`,
      data
    );
  }

  getAllDemandReqNo() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/materialDemandRequest/getAllUniqueDemandRequestNo`
    );
  }

  getAllDemandReqNoDetails(demandReqNo: any) {
    return this.httpClient.get(
      //`${environment.mmUrl}/api/transaction/materialDemandRequest/getAllByDemandRequestNo?demandRequestNo=${demandReqNo}`
      //http://10.0.0.69:9090/api/transaction/materialDemandRequest/getAllByDemandRequestNo?demandRequestNo=DR/2024-25/00000038
            `${environment.mmUrl}/api/transaction/materialDemandRequest/getAllByDemandRequestNo?demandRequestNo=${demandReqNo}`
    );
  }

  getAllMaterialDistributionAndIndentProcessing() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/materialDistributionAndIndentProcessing/getAll`
    );
  }

  getMaterialDistributionDetails(id: any) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/materialDistributionAndIndentProcessing/getById?id=${id}`
    );
  }
  getDistributionDetailsInDemand(deamndReqNo: any) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/materialDistributionAndIndentProcessing/getAllDemandRequestNo?demandRequestNo=${deamndReqNo}`
    );
  }

  //Expired Material Disposal

  getAllExpiredMaterialDetails(
    materialCategoryId: any,
    subMaterialCategoryId: any,
    materialNameId: any,
    fromDate: any,
    toDate: any
  ) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/trnReceivingMaterialfromSupplier/getAllReceivingDetailsByFromDateAndToDate?materialCategoryId=${materialCategoryId}&subMaterialCategoryId=${subMaterialCategoryId}&materialNameId=${materialNameId}&fromDate=${fromDate}&toDate=${toDate}`
    );
  }

  saveExpiredMaterialStock(data: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/transaction/expiredMaterialDisposal/saveAll`,
      data
    );
  }

  getAllExpiredMaterialStock() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/expiredMaterialDisposal/getAllUniqueExpiredNo`
    );
  }

  getAllExpiredStockByExpiredDisposalNo(expNo: any) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/expiredMaterialDisposal/getByExpiredDisposalNo?expiredDisposalNo=${expNo}`
    );
  }

  //Return Material to Supplier

  getAllBysupplierIdPurchaseorderNo(SId: any, Pno: any) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/trnReceivingMaterialfromSupplier/getAllReceivingDetailsBySupplierIdAndPurchaseOrderNo?supplierId=${SId}&purchaseOrderNo=${Pno}`
    );
  }

  // getAllBysupplierIdPurchaseorderNo(SId: any, Pno: any) {
  //   console.log(SId,Pno)
  //   return this.httpClient.get(
  //     `${environment.mmUrl}api/transaction/trnReceivingMaterialfromSupplier/getAllReceivingDetailsBySupplierIdAndPurchaseOrderNo?supplierId=${SId}&purchaseOrderNo=${Pno}`
  //   );
  // }
  //getAllReceivingDetailsBySupplierIdAndPurchaseOrderNo

  //localhost:9012/api/transaction/trnReceivingMaterialfromSupplier/getAllReceivingDetailsBySupplierIdAndPurchaseOrderNo?supplierId=0190918d-6f3f-778f-9259-47d80ca96927&purchaseOrderNo=PO/2024-25/00000001


  getAllSupplierList() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/trnReceivingMaterialfromSupplier/getAllUniqueSupplierId`
    );
  }

  getAllPurchaseOrderList(supplierId: any) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/trnReceivingMaterialfromSupplier/getAllUniquePurchaseOrderNoBySupplierId?supplierId=${supplierId}`
    );
  }

  savereturnMaterialToSupplier(AllData: any) {
    return this.httpClient.post(
      `${environment.mmUrl}/api/transaction/returnMaterialToSupplier/save`,
      AllData
    );
  }

  getAllReturnMaterial() {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/returnMaterialToSupplier/getAll`
    );
  }

  getReturnMaterialDetails(id: any) {
    return this.httpClient.get(
      `${environment.mmUrl}/api/transaction/returnMaterialToSupplier/getById?id=${id}`
    );
  }
}
