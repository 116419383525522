import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MsmamServiceService {
  constructor(private http: HttpClient) {}
  getMeetingType() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/meetingtype/getAllDashboard`
    );
  }
  getByActiveFlagMeetingType() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/meetingtype/getAll`
    );
  }
  activeInactiveMeetingType(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/meetingtype/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveMeetingType(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/meetingtype/save`,
      data
    );
  }

  getCast() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/cast/getAllDashboard`
    );
  }
  getByActiveFlagCast() {
    return this.http.get(`${environment.msmamUrl}/api/master/cast/getAll`);
  }
  activeInactiveCast(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/cast/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveCast(data: any) {
    return this.http.post(`${environment.msmamUrl}/api/master/cast/save`, data);
  }

  // https://15-206-219-76.nip.io:9008/api/master/subcast/save
  getSubcast() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/subcast/getAllDashboard`
    );
  }
  getByActiveFlagSubcast() {
    return this.http.get(`${environment.msmamUrl}/api/master/subcast/getAll`);
  }
  activeInactiveSubcast(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/subcast/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveSubcast(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/subcast/save`,
      data
    );
  }

  getCommitteeestablishment() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/committeeestablishment/getAllDashboard`
    );
  }
  getByActiveFlagCommitteeestablishment() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/committeeestablishment/getAll`
    );
  }
  activeInactiveCommitteeestablishment(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/committeeestablishment/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveCommitteeestablishment(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/committeeestablishment/save`,
      data
    );
  }

  // https://15-206-219-76.nip.io:9008/api/master/committee/save
  getCommittee() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/committee/getAllDashboard`
    );
  }
  getByActiveFlagCommittee() {
    return this.http.get(`${environment.msmamUrl}/api/master/committee/getAll`);
  }
  activeInactiveCommittee(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/committee/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveCommittee(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/committee/save`,
      data
    );
  }

  // https://15-206-219-76.nip.io:9008/api/master/committeemembers/save
  getCommitteemembers() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/committeemembers/getAllDashboard`
    );
  }
  getByActiveFlagCommitteemembers() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/committeemembers/getAll`
    );
  }
  activeInactiveCommitteemembers(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/committeemembers/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveCommitteemembers(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/committeemembers/save`,
      data
    );
  }

  // https://15-206-219-76.nip.io:9008/api/master/corporate/save
  getCorporate() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/corporate/getAllDashboard`
    );
  }
  getByActiveFlagCorporate() {
    return this.http.get(`${environment.msmamUrl}/api/master/corporate/getAll`);
  }
  activeInactiveCorporate(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/corporate/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveCorporate(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/corporate/save`,
      data
    );
  }

  getCorporatorList(id) {
    return this.http.get(
      `${environment.msmamUrl}/api/master/corporate/getCorporatorByElectoralWardId?electoralWardId=${id}`
    );
  }

  // https://15-206-219-76.nip.io:9008/api/master/electoralward/save
  getElectoralward() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/electoralward/getAllDashboard`
    );
  }
  getByActiveFlagElectoralward() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/electoralward/getAll`
    );
  }
  activeInactiveElectoralward(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/electoralward/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveElectoralward(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/electoralward/save`,
      data
    );
  }

  // https://15-206-219-76.nip.io:9008/api/master/identificationproof/save
  getIdentificationproof() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/identificationproof/getAllDashboard`
    );
  }
  getByActiveFlagIdentificationproof() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/identificationproof/getAll`
    );
  }
  activeInactiveIdentificationproof(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/identificationproof/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveIdentificationproof(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/identificationproof/save`,
      data
    );
  }

  // https://15-206-219-76.nip.io:9008/api/master/meetingschedule/save
  getMeetingschedule() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/meetingschedule/getAllDashboard`
    );
  }
  getByActiveFlagMeetingschedule() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/meetingschedule/getAll`
    );
  }
  activeInactiveMeetingschedule(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/meetingschedule/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveMeetingschedule(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/meetingschedule/save`,
      data
    );
  }

  // https://15-206-219-76.nip.io:9008/api/master/offices/save
  getOffices() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/offices/getAllDashboard`
    );
  }
  getByActiveFlagOffices() {
    return this.http.get(`${environment.msmamUrl}/api/master/offices/getAll`);
  }
  activeInactiveOffices(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/offices/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveOffices(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/offices/save`,
      data
    );
  }

  // https://15-206-219-76.nip.io:9008/api/master/party/save
  getParty() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/party/getAllDashboard`
    );
  }
  getByActiveFlagParty() {
    return this.http.get(`${environment.msmamUrl}/api/master/party/getAll`);
  }
  activeInactiveParty(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/party/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveParty(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/party/save`,
      data
    );
  }

  // https://15-206-219-76.nip.io:9008/api/master/religion/save
  getReligion() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/religion/getAllDashboard`
    );
  }
  getByActiveFlagReligion() {
    return this.http.get(`${environment.msmamUrl}/api/master/religion/getAll`);
  }
  activeInactiveReligion(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/religion/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveReligion(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/religion/save`,
      data
    );
  }
  getCalender() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/calenderschedulingandholiday/getAllDashboard`
    );
  }
  getByActiveFlagNewCalender() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/calenderschedulingandholiday/getAll`
    );
  }
  activeInactiveNewCalender(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/calenderschedulingandholiday/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveNewCalender(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/calenderschedulingandholiday/save`,
      data
    );
  }

  // Transactions

  // https://15-206-219-76.nip.io:9008/api/master/religion/save
  getNewDocketentry() {
    return this.http.get(
      `${environment.msmamUrl}/api/transaction/newdocketentry/getAllDashboard`
    );
  }
  getByActiveFlagNewDocketentry() {
    return this.http.get(
      `${environment.msmamUrl}/api/transaction/newdocketentry/getAll`
    );
  }
  activeInactiveNewDocketentry(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/transaction/newdocketentry/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveNewDocketentry(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/transaction/newdocketentry/save`,
      data
    );
  }

  // https://15-206-219-76.nip.io:9008/api/transaction/preparemeetingagendaandfinalization/save
  getPrepareMeetingAgenda() {
    return this.http.get(
      `${environment.msmamUrl}/api/transaction/preparemeetingagendaandfinalization/getAllDashboard`
    );
  }
  getByActiveFlagPrepareMeetingAgenda() {
    return this.http.get(
      `${environment.msmamUrl}/api/transaction/preparemeetingagendaandfinalization/getAll`
    );
  }
  activeInactivePrepareMeetingAgenda(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/transaction/preparemeetingagendaandfinalization/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  savePrepareMeetingAgenda(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/transaction/preparemeetingagendaandfinalization/save`,
      data
    );
  }

  getDepartment() {
    return this.http.get(
      `https://15-206-219-76.nip.io:8090/api/master/department/getAllDashboard`
    );
  }

  // https://15-206-219-76.nip.io:9008/api/master/Media Channel/save
  saveMediaChannelDetail(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/mediachannel/save`,
      data
    );
  }

  getMediaChannelList() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/mediachannel/getAllDashboard`
    );
  }

  activeInactiveMediaChannel(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/mediachannel/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }

  // https://15-206-219-76.nip.io:9008/api/master/Media/save

  getMediaList() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/media/getAllDashboardData`
    );
  }

  saveMediaDetail(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/media/save`,
      data
    );
  }

  getChannelName() {
    var url =
      `${environment.msmamUrl}` + "/api/master/media/getMediaChannelList";
    return this.http.get(url);
  }

  activeInactiveMedia(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/media/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }

  // https://15-206-219-76.nip.io:9008/api/master/Journalist/save

  getMediaAndChannelName() {
    var url =
      `${environment.msmamUrl}` +
      "/api/master/journalist/getMediaAndMediaChannelList";
    return this.http.get(url);
  }

  getJournalistList() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/journalist/getAllDashboard`
    );
  }

  saveJournalistDetail(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/journalist/save`,
      data
    );
  }

  activeInactiveJournalist(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/journalist/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }

  // https://15-206-219-76.nip.io:9008/api/master/Clerk to Committee/save

  saveClerkToCommitteeDetail(data: any) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/clerkToCommitteeMapping/save`,
      data
    );
  }

  getClerkToCommitteeList() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/clerkToCommitteeMapping/getAllDashboard`
    );
  }

  getCommitteeName() {
    return this.http.get(
      `${environment.msmamUrl}/api/master/clerkToCommitteeMapping/getAllCommitee`
    );
  }

  getClerkNameList(designationId: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/user/getUsersByDesignation?designationId=${designationId}`
    );
  }

  activeInactiveClerkToCommittee(id, flag) {
    return this.http.post(
      `${environment.msmamUrl}/api/master/clerkToCommitteeMapping/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }

  // https://15-206-219-76.nip.io:9008/api/transaction/new prastav entry
  getNewPrastavList() {
    return this.http.get(
      `${environment.msmamUrl}/api/transaction/newprastaventry/getAll`
    );
  }
  getCommitteeAndMeetingType() {
    return this.http.get(
      `${environment.msmamUrl}/api/transaction/newprastaventry/getCommitteeAndMeetingType`
    );
  }

  saveNewPrastav(data: FormData) {
    return this.http.post(
      `${environment.msmamUrl}/api/transaction/newprastaventry/save`,
      data
    );
  }

  getNewPrastavDetailsById(id) {
    return this.http.get(
      `${environment.msmamUrl}/api/transaction/newprastaventry/getPrasById?id=${id}`
    );
  }

  // https://15-206-219-76.nip.io:9008/api/transaction/counsellor proposal
  getCommiteeMemberAndCorporator() {
    return this.http.get(
      `${environment.msmamUrl}/api/transaction/counsellorProposal/getCommitteeAndCommiteeMemberAndCorporate`
    );
  }

  saveCounsellorProposal(data: FormData) {
    return this.http.post(
      `${environment.msmamUrl}/api/transaction/counsellorProposal/save`,
      data
    );
  }

  getCounsellorProposalList() {
    return this.http.get(
      `${environment.msmamUrl}/api/transaction/counsellorProposal/getAll`
    );
  }
}
