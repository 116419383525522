<div class="bg-gray-100 p-6">
    <mat-icon id="close-icon" style="float: right; cursor: pointer;" class="close-icon" [mat-dialog-close]="true">
      close
    </mat-icon>
  
    <div class="mx-auto max-w-md">
      <h1 class="text-2xl font-bold mb-8">{{addedit?'Edit':'Add'}} Party</h1>
  
      <form (ngSubmit)="saveParty()" #meetingForm="ngForm">
         <mat-form-field class="w-full">
          <mat-label>Enter Party Name</mat-label>
          <input matInput [(ngModel)]="partyName" name="partyName" required>
        </mat-form-field>
     
        <button type="submit" class="w-full disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none" 
        [disabled]="!meetingForm.valid">
          Save
        </button>
        <div class="text-center text-red-500 mt-2">
          {{errorMessage}}
        </div>
      </form>
    </div>
  </div>
  