<div class="grid" style="margin: 0; padding: 0">
  <div class="col-12" style="margin: 0; padding: 0; width: 100%; text-align: center; margin-top: 2rem">
    <svg class="cross" width="100" height="100" viewBox="0 0 100 100">
      <circle class="cross-disc" cx="50" cy="50" r="50" fill="red"></circle>
      <path
        class="cross-line"
        d="M65 33L35 66"
        fill="none"
        stroke="white"
        stroke-width="3"
        stroke-linecap="round"
      ></path>
      <path
        class="cross-line"
        d="M65 66L35 33"
        fill="none"
        stroke="white"
        stroke-width="3"
        stroke-linecap="round"
      ></path>
    </svg>
  </div>
  <div
    class="col-12"
    style="
      text-align: center;
      font-size: 20px;
      letter-spacing: 1px;
      color: red;
      margin-top: 1rem;
      margin-bottom: 2rem;
      width: 100%;
      margin-left: -0rem;
    "
  >
    FAILURE
  </div>
</div>
<div class="grid" style="text-align: center; background-color: red; height: 180px">
  <div class="col-12" style="font-size: 20px; color: white; margin-top: 2rem">Transaction Failed</div>
  <!-- <div class="col-12">
    <button
      mat-raised-button
      mat-dialog-close
      [autofocus]="false"
      style="
        background-color: white;
        color: black;
        border-radius: 20px;
        width: 200px;
        font-weight: normal;
        line-height: 40px;
        font-size: 20px;
        margin-top: 1rem;
      "
    >
      View Status
    </button>
  </div> -->
  <div class="col-12">
    <a routerLink="/dashboard" style="text-decoration: underline; color: white; font-size: 10px" mat-dialog-close>
      GO TO DASHBOARD
    </a>
  </div>
</div>
