import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';

@Component({
  selector: 'app-add-party',
  templateUrl: './add-party.component.html',
  styleUrls: ['./add-party.component.scss']
})
export class AddPartyComponent {
  partyName: string = '';
  addedit:any;
  id=null;
  errorMessage:string="";
  createdUserId:any;
  updateUserId:any;
  userId: string;

  constructor(private msmService:MsmamServiceService,
    private _snackBar: SnackbarMsgService, 
  private dialog: MatDialog,
  public dialogRef: MatDialogRef<AddPartyComponent>,
  @Inject(MAT_DIALOG_DATA) public data1: any){
    this.addedit = data1['isEditMode'];
    this.userId = localStorage.getItem("userId");
    this.createdUserId=this.userId;
    this.updateUserId=this.userId;
    if(this.addedit == true){
      const data12 = data1['row']
      this.partyName=data12['partyName'];
      this.id=data12['id'];
      this.createdUserId=data12['createdUserId'];
      this.updateUserId=this.userId;

    }


}
saveParty() {
     const data = {
      "id":this.id,
      "partyName": this.partyName,
      "createdUserId":this.createdUserId,
      "updateUserId":this.updateUserId,
      "activeFlag": "Y"
    };
  

    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: "550px",
      data: {
        mode: this.addedit?'Edit':'Add',
        component: 'Party'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        this.msmService.saveParty(data).subscribe(
          res => {
            if(res['status'] === 'Success'){
              setTimeout(() => {
                this.dialogRef.close({data : 'Success'});
              }, 1000);
            }else{
                this.errorMessage=res['status']
              }
          },
          err => {
            this._snackBar.error('party not added')
          }
        )
      }
    })
   }

}
