<!-- <div class="flex flex-wrap drop-shadow-lg p-5 custom">
    <div *ngFor="let item of masterItems" class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 px-2 " >
      <div class="bg-white p-8 rounded-lg shadow-md" routerLink="{{item.routerLink}}">
        <h3 class="text-xl font-semibold">{{ item.title }}</h3>
      </div>
    </div>
  </div> -->


  <div class="min-w-0 flex-1 m-3">
    <h1 class="text-2xl font-bold"> Welcome To HR & Payroll Management ..!</h1>
  </div>