import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { TokenAuthGuard } from '../guard/auth.guard';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';


@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

  constructor(private location: Location,private authGuardService : TokenAuthGuard,
    private router : Router) {
   
  }



  ngOnInit(): void {
   
  }

  goBack(): void {
    const previousUrl = this.authGuardService.getPreviousUrl();
    console.log(previousUrl)
    if (previousUrl) {
      this.router.navigateByUrl(previousUrl);
    } else {
      this.router.navigate(['/mapped-dashboard']); // Default to home page or another fallback page
    }  }

 
}
