import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';

@Component({
  selector: 'app-add-meeting-shedule',
  templateUrl: './add-meeting-shedule.component.html',
  styleUrls: ['./add-meeting-shedule.component.scss']
})
export class AddMeetingSheduleComponent {
  committeeId:number;
  committee:string;
  committees:any;
  date:any;
  time:any;
  addedit: any;
  id = null;
  createdUserId:any;
  updateUserId:any;
  userId: string;
  errorMessage:string="";
  constructor(private msmService: MsmamServiceService,
     private _snackBar: SnackbarMsgService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddMeetingSheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data1: any) {
  

  }
  ngOnInit(){
    this.userId = localStorage.getItem("userId");
    this.createdUserId=this.userId;
    this.updateUserId=this.userId;
    this.addedit = this.data1['isEditMode']
    if (this.addedit == true) {
      const data12 = this.data1['row'];
      this.createdUserId=data12['createdUserId'];
      this.updateUserId=this.userId;
      this.committee = data12['committee'];
      this.committeeId = data12['committeeId'];
      this.date=data12['date'];
      this.time=data12['time'];
      this.id = data12['id'];

    }
    this.getCommittees();
  }

  getCommittees(){
    this.msmService.getByActiveFlagCommittee().subscribe(
      (data) => this.committees = data['committeeMasterList'],
      (err) => this._snackBar.error('committe List is Empty')
    );
  }
  committeeChange(data){
    this.committeeId = data;
    this.committee= this.committees.find((item) => item.id === data)?.committeeName || '';
  }
  saveMeeting() {
   
    const data = {
      "id": this.id,
      "committeeId":this.committeeId,
      "committee":this.committee,
      "date":this.date,
      "time":this.time,
      "createdUserId":this.createdUserId,
      "updateUserId":this.updateUserId,
      "activeFlag": "Y"
    };


    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: "550px",
      data: {
        mode: this.addedit ? 'Edit' : 'Add',
        component: 'Meeting Shedule'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        this.msmService.saveMeetingschedule(data).subscribe(
          res => {
            if (res['status'] === 'Success') {
              setTimeout(() => {
                this.dialogRef.close({ data: 'Success' });
              }, 1000);
            }else{
              this.errorMessage=res['status']
            }
          },
          err => {
            this._snackBar.error('Meeting Shedule not added')
          }
        )
      }
    })
  }
}
