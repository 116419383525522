import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileUploadService } from 'src/app/_services/file-upload.service';
import { PropertyTaxService } from 'src/app/_services/property-tax/property-tax.service';
import {Location} from '@angular/common';
import * as FileSaver from "file-saver";
import { environment } from 'src/environments/environment';
import { ViewImgDocComponent } from 'src/app/RTS/view-rts-application/view-img-doc/view-img-doc.component';
import { ScrutinyActionsConfirmationComponent } from 'src/app/CWM/cwm-dashboard/cwm-app-scrutiny/scrutiny-actions-confirmation/scrutiny-actions-confirmation.component';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-property-tax-scrutiny-actions',
  templateUrl: './property-tax-scrutiny-actions.component.html',
  styleUrls: ['./property-tax-scrutiny-actions.component.scss']
})
export class PropertyTaxScrutinyActionsComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  
  applicationData : any[] = []
  form!: FormGroup;
  scheduledForm! : FormGroup
  visitedForm! : FormGroup
  osApprovedForm! : FormGroup
  noticeGeneratedForm! : FormGroup
  forwardNoticeForm! : FormGroup
  noticeIssuedForm! : FormGroup
  sunavaniScheduledForm! : FormGroup
  aadeshGeneratedForm! : FormGroup
  aadeshApprovedForm! : FormGroup
  applicationApprovedForm! : FormGroup


  appId : any = ''
  appStatus: any = ''
  state : any
  action : any = ''
  approveRemark : any = ''
  showSiteVisitImageUpload : boolean = false
  showJababUpload : boolean =  false
  showpanchanamaUpload : boolean = false
  showpahaniahwalUpload : boolean = false
  showPrastavUpload : boolean = false
  showPratidnyaPatraUpload : boolean = false
  showVisheshNoticeUpload : boolean = false
  showSignedpratidyapatrUpload : boolean = false
  showAadeshUpload : boolean = false
  contentLoaded = false;

  image1 = ''
       image2 = ''
       image3 = ''
       applicationDataInfo : any[] = []
      //  dataSource: RowElement[];
       dataSource = new MatTableDataSource<any>();

       displayedColumns: string[] = ["key", "value"];



  constructor(
    private propertyService: PropertyTaxService,
      private fb: FormBuilder,
      private _snackBar : MatSnackBar,
      private dialog : MatDialog,
      private location : Location,
      private router:Router,
      private uploadService : FileUploadService,
      ) { 
       
      }


      ngOnInit(): void {
        this.form = this.fb.group({
          siteVisitDate: ["",Validators.required],
          siteVisitStartTime: ["",Validators.required],
          siteVisitEndTime: ["",Validators.required],
          id: [""],
         
    
        })

        this.scheduledForm = this.fb.group({
          siteVisitImage1: ["",Validators.required],
          siteVisitImage2: ["",Validators.required],
          siteVisitImage3: ["",Validators.required],
          jabab: ["",Validators.required],
          panchanama: ["",Validators.required],
          pahaniahwal: ["",Validators.required],
          id: [""],
         
    
        })

        this.osApprovedForm = this.fb.group({
          approveRemark: [""],
          rejectRemark : [""],
          id:[""],
          prastav: ["",Validators.required],
          pratidyapatr: ["",Validators.required],
          visheshNotice: ["",Validators.required],


        })

        this.visitedForm = this.fb.group({
          approveRemark: [""],
          rejectRemark : [""],
          id:[""]
        })

        this.noticeGeneratedForm = this.fb.group({
          approveRemark: [""],
          rejectRemark : [""],
          id:[""],
          signedpratidyapatr:[""]
        })

        this.forwardNoticeForm = this.fb.group({
          approveRemark: [""],
          rejectRemark : [""],
          id:[""],
        })

        this.noticeIssuedForm = this.fb.group({
          approveRemark: [""],
          rejectRemark : [""],
          id:[""],
          sunavaniDate:[""]
        })

        this.sunavaniScheduledForm = this.fb.group({
          aadesh: [""],
          id:[""]
        })

        this.aadeshGeneratedForm = this.fb.group({
          approveRemark: [""],
          rejectRemark: [""],
          id:[""]
        })

        this.aadeshApprovedForm = this.fb.group({
          approveRemark: [""],
          rejectRemark: [""],
          id:[""]
        })

        this.applicationApprovedForm = this.fb.group({
          approveRemark: [""],
          rejectRemark: [""],
          id:[""]
        })



    
      
        this.state = history.state;
        this.applicationData.push(this.state['applicationData'])
       
        this.appStatus = this.applicationData[0]['status']
        this.approveRemark = this.applicationData[0]['approveRemark']
        this.appId = this.applicationData[0]['id']

        this.applicationDataInfo.push(
          {
            'Title' :  this.applicationData[0].title,
            'First Name' : this.applicationData[0].firstName,
            'Actual Building Usage Time' :  this.applicationData[0].actualDateOfBuildingUsageTime,
            'Aadhar No.' :  this.applicationData[0].adharNo,
            'Application Date' :  this.applicationData[0].applicationDate,
            'Application No.' :  this.applicationData[0].applicationNo,
            'Area' :  this.applicationData[0].area,
            'Area SqMtr' :  this.applicationData[0].areaSqMtr,
            'Bank Account No.' :  this.applicationData[0].bankAccountNumber,
            'Bank Branch' :  this.applicationData[0].bankBranch,
            'IFSC' :  this.applicationData[0].bankIfsc,
            'Bank Name' :  this.applicationData[0].bankName,
            'Building Completion Date' :  this.applicationData[0].building,
            'Building Permission Date' :  this.applicationData[0].buildingPermissionDate,
            'Business Type' :  this.applicationData[0].bussinessType,
            'City' :  this.applicationData[0].city,
            'Construction Type' :  this.applicationData[0].constructionType,
            'Diameter' :  this.applicationData[0].diameter,
            'email' :  this.applicationData[0].email,
            'diameter' :  this.applicationData[0].diameter,
            'Flat No.' :  this.applicationData[0].flatNo,
            'landmark' :  this.applicationData[0].landmark,
            'lastname' :  this.applicationData[0].lastName,
            'middlename' :  this.applicationData[0].middleName,
            'mobile' :  this.applicationData[0].mobile,
            'Water Connections' :  this.applicationData[0].noOfWaterConnections,
            'Occupancy' :  this.applicationData[0].occupancy,
            'Occupancy Certificate Date' :  this.applicationData[0].occupancyCertificateDate,
            'Occupancy Certificate No.' :  this.applicationData[0].occupancyCertificateNo,
            'Ownership Type' :  this.applicationData[0].ownershipType,
            'PAN No.' :  this.applicationData[0].panNo,
            'Parking' :  this.applicationData[0].parking,
            'Parking Area' :  this.applicationData[0].parkingAreaSqMtr,
            'Pincode' :  this.applicationData[0].pinCode,
            'Plot Id' :  this.applicationData[0].plotId,
            'Plot Name' :  this.applicationData[0].plotName,
            'Postal Address' :  this.applicationData[0].postalAddress,
            'Property Status' :  this.applicationData[0].propertyStatus,
            'Property Sub Type' :  this.applicationData[0].propertySubType,
            'Property Type' :  this.applicationData[0].propertyType,
            'Society Name' :  this.applicationData[0].societyName,
            'Sub Usage Type' :  this.applicationData[0].subUsageType,
            'Survey' :  this.applicationData[0].survey,
            'Survey No.' :  this.applicationData[0].surveyNo,
            'Total Area' :  this.applicationData[0].totalArea,
            'Usage Type' :  this.applicationData[0].usageType,
            'Village' :  this.applicationData[0].village,
            'Water Connection No.' :  this.applicationData[0].waterConnectionNo,
            'Water Connection Type' :  this.applicationData[0].waterConnectionType,





          }
        )

        for (const key in this.applicationDataInfo[0]) {
          console.log(key)
          console.log(this.applicationDataInfo[0][key])
          this.dataSource.data.push({ key : key, value: this.applicationDataInfo[0][key] });

        }

      

        setTimeout(
          () => {
            this.dataSource.paginator = this.paginator;
           });
    
           setTimeout(() => {
            this.contentLoaded = true;
        }, 1000);
       
        // if(this.appStatus  == 'APPROVED'){
        //   this.action = "Upload required documents"
        // }

        if(this.appStatus == 'SITE_VISIT_SCHEDULED'){
          this.action = "Upload required documents"
          this.scheduledForm.patchValue({'id':this.applicationData[0].id})
          this.scheduledForm.patchValue({'approveRemark':this.applicationData[0].approveRemark})
          this.scheduledForm.patchValue({'rejectRemark':this.applicationData[0].rejectRemark})
          
        }

        if(this.appStatus == 'SITE_VISIT_COMPLETED'){
          this.action = "Document approval"
          this.visitedForm.patchValue({'id':this.applicationData[0].id})
        
        }

        if(this.appStatus == 'OS_APPROVED'){
          this.action = "OS Approval"
          this.osApprovedForm.patchValue({'id':this.applicationData[0].id})
        }

        if(this.appStatus == 'NOTICE_GENERATED'){
          this.action = "Notice generation"
          this.noticeGeneratedForm.patchValue({'id':this.applicationData[0].id})

          

        }

        if(this.appStatus == 'NOTICE_APPROVED'){
          this.action = "Notice Approved"
          this.forwardNoticeForm.patchValue({'id':this.applicationData[0].id})

        }

        if(this.appStatus == 'NOTICE_ISSUED'){
          this.action = "Notice issued"
          this.noticeIssuedForm.patchValue({'id':this.applicationData[0].id})

        }

        if(this.appStatus == 'SUNAVANI_SCHEDULED'){
          this.action = "Sunavani Scheduled"
          this.sunavaniScheduledForm.patchValue({'id':this.applicationData[0].id})

        }

        if(this.appStatus == 'AADESH_GENERATED'){
          this.action = "Aadesh Generated"
          this.aadeshGeneratedForm.patchValue({'id':this.applicationData[0].id})

        }

        if(this.appStatus == 'AADESH_APPROVED'){
          this.action = "Aadesh Approved"
          this.aadeshApprovedForm.patchValue({'id':this.applicationData[0].id})

        }

        if(this.appStatus == 'APPLICATION_APPROVED'){
          this.action = "Application Approved"
          this.applicationApprovedForm.patchValue({'id':this.applicationData[0].id})

        }
    

        console.log(this.applicationData[0].status)
    
      
    
    
       
    
      }

     

      uploadSiteVisitImages(event : any){
        if (event.target.files.length > 3) {
         
          event.preventDefault();
          event.target.value = ""; 
        } else {
            for(var i = 0; i < event.target.files.length;i++){
              
              if(i == 0){
                this.uploadService.uploadFile(event.target.files[0]).then((data : any) => {
              
                    this.scheduledForm.patchValue({'siteVisitImage1':JSON.parse(data).filePath})
                    console.log(this.scheduledForm.value)
    
                })
              }
    
              if(i == 1){
                this.uploadService.uploadFile(event.target.files[1]).then((data : any) => {
              
                    this.scheduledForm.patchValue({'siteVisitImage2':JSON.parse(data).filePath})
                    console.log(this.scheduledForm.value)
    
                })
              }
    
              if(i == 2){
                this.uploadService.uploadFile(event.target.files[2]).then((data : any) => {
              
                    this.scheduledForm.patchValue({'siteVisitImage3':JSON.parse(data).filePath})
                    console.log(this.scheduledForm.value)
    
                })
              }

            }
            this.showSiteVisitImageUpload = true
        }
       


      }

      uploadJabab(event : any){
        for(var i = 0; i < event.target.files.length;i++){
          console.log(event.target.files[i])

          this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
              
            this.scheduledForm.patchValue({'jabab':JSON.parse(data).filePath})
            this.showJababUpload = true
            console.log(data)
        })
        }
      }

      uploadpanchanama(event : any){
        for(var i = 0; i < event.target.files.length;i++){
          this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
              
            this.scheduledForm.patchValue({'panchanama':JSON.parse(data).filePath})
            this.showpanchanamaUpload = true

        })
        }
      }

      uploadpahaniahwal(event : any){
        for(var i = 0; i < event.target.files.length;i++){
          this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
              
            this.scheduledForm.patchValue({'pahaniahwal':JSON.parse(data).filePath})
            this.showpahaniahwalUpload = true

        })
        }
      }


      saveScrutinyDetailsForSiteSchedule(){

            this.propertyService.applicationSubmitPropertyTaxScrutiny(this.scheduledForm.value).subscribe(
              (res) => {
                console.log(res)
                if(res.status == "SUCCESS"){
                  this._snackBar.open('Required documents uploaded successfully', 'Close', {
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                    duration: 3000,
                  });
                 this.location.back()

                }
               
              },
              (err) => {
          
              }
            );
      }

      saveScrutinyDetailsFoSiteVisitCompleted(){
        this.approveApplication(this.applicationData)
       
      }

      approveApplication(element : any){
        const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
          width: "550px",
          data: {
            text : 'Are you sure you want to approve the application for site visit.',
            status : 'Approval for visited site',
            component : 'Tax scrutiny'
          },
          autoFocus: false,
          restoreFocus: false,
        });
    
    
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result.status == "success") {
            console.log(element)
            // this.propertyService.approveApplication(element[0].id,result.remark).then((data : any) => {
              this.visitedForm.patchValue({'approveRemark':result.remark})
              this.visitedForm.patchValue({'rejectRemark':''})

               this.propertyService.applicationSubmitPropertyTaxScrutiny(this.visitedForm.value).subscribe(
          (res) => {
            console.log(res)
            if(res.status == "SUCCESS"){
              this._snackBar.open('Approval Successful', 'Close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 3000,
              });
this.location.back()
            }
           
          },
          (err) => {
      
          }
        );
            // })        
          }
        })
    
      }

      rejectApplication(){
        const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
          width: "550px",
          data: {
            text : 'Are you sure you want to reject the application for site visit.',
            status : 'Reject visited site',
            component : 'Tax scrutiny'
          },
          autoFocus: false,
          restoreFocus: false,
        });
    
    
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result.status == "success") {
            // this.propertyService.rejectApplication(this.applicationData[0].id,result.remark).then((data : any) => {
              this.visitedForm.patchValue({'rejectRemark':result.remark})
              this.visitedForm.patchValue({'approveRemark':''})

               this.propertyService.applicationSubmitPropertyTaxScrutiny(this.visitedForm.value).subscribe(
          (res) => {
            if(res.status == "SUCCESS"){
              this._snackBar.open('Rejected', 'Close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 3000,
              });
this.location.back()
            }
           
          },
          (err) => {
      
          }
        );
            // })        
          }
        })
    
      }

      uploadPrastav(event : any){
        for(var i = 0; i < event.target.files.length;i++){
          this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
              
            this.osApprovedForm.patchValue({'prastav':JSON.parse(data).filePath})
            this.showPrastavUpload = true
        })
        }
      }

      uploadPratidnyaPatra(event : any){
        for(var i = 0; i < event.target.files.length;i++){
          this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
              
            this.osApprovedForm.patchValue({'pratidyapatr':JSON.parse(data).filePath})
            this.showPratidnyaPatraUpload = true

        })
        }
      }


      uploadVisheshNotice(event : any){
        for(var i = 0; i < event.target.files.length;i++){
          this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
              
            this.osApprovedForm.patchValue({'visheshNotice':JSON.parse(data).filePath})
            this.showVisheshNoticeUpload = true

        })
        }
      }

      saveScrutinyDetailsForOSApproved(){
        console.log(this.osApprovedForm.value)
        const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
          width: "550px",
          data: {
            text : 'Are you sure you want to approve the application for OS Approval.',
            status : 'Approval for OS Approval',
            component : 'Tax scrutiny'
          },
          autoFocus: false,
          restoreFocus: false,
        });
    
    
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result.status == "success") {
            // this.propertyService.approveApplication(this.applicationData[0].id,result.remark).then((data : any) => {
              this.osApprovedForm.patchValue({'approveRemark':result.remark})
              this.propertyService.applicationSubmitPropertyTaxScrutiny(this.osApprovedForm.value).subscribe(
                (res) => {
                  console.log(res)
                  if(res.status == "SUCCESS"){
                    this._snackBar.open('Required documents uploaded successfully', 'Close', {
                      horizontalPosition: 'right',
                      verticalPosition: 'top',
                      duration: 3000,
                    });
                   this.location.back()
      
                  }
                 
                },
                (err) => {
            
                }
              );
            // })        
          }
        })
       
      }

      rejectOSApproval(){
        const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
          width: "550px",
          data: {
            text : 'Are you sure you want to reject the application for OS Approval.',
            status : 'Reject OS Approval',
            component : 'Tax scrutiny'
          },
          autoFocus: false,
          restoreFocus: false,
        });
    
    
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result.status == "success") {
            // this.propertyService.rejectApplication(this.applicationData[0].id,result.remark).then((data : any) => {
              this.osApprovedForm.patchValue({'rejectRemark':result.remark})
              this.propertyService.applicationSubmitPropertyTaxScrutiny(this.osApprovedForm.value).subscribe(
                (res) => {
                  console.log(res)
                  if(res.status == "SUCCESS"){
                    this._snackBar.open('Required documents uploaded successfully', 'Close', {
                      horizontalPosition: 'right',
                      verticalPosition: 'top',
                      duration: 3000,
                    });
                   this.location.back()
      
                  }
                 
                },
                (err) => {
            
                }
              );
            
            // })        
          }
        })
      }

      uploadSignedPratidyPatra(event : any){
        for(var i = 0; i < event.target.files.length;i++){
          this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
              
            this.noticeGeneratedForm.patchValue({'signedpratidyapatr':JSON.parse(data).filePath})
            this.showSignedpratidyapatrUpload = true

        })
        }
      }

      saveScrutinyDetailsFoNoticeGenerated(){
        this.approveApplicationForNg(this.applicationData)
        console.log(this.applicationData)
       
      }

      approveApplicationForNg(element : any){
        const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
          width: "550px",
          data: {
            text : 'Are you sure you want to approve the application for Notice Generation.',
            status : 'Approval for Notice Generation',
            component : 'Tax scrutiny'
          },
          autoFocus: false,
          restoreFocus: false,
        });
    
    
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result.status == "success") {
            // this.propertyService.approveApplication(element[0].id,result.remark).then((data : any) => {
              this.noticeGeneratedForm.patchValue({'approveRemark':result.remark})
               this.propertyService.applicationSubmitPropertyTaxScrutiny(this.noticeGeneratedForm.value).subscribe(
          (res) => {
            if(res.status == "SUCCESS"){
              this._snackBar.open('Approval Successful', 'Close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 3000,
              });
              // this.router.navigate(['/propertyTax-scrutiny'])
              this.location.back()

            }
           
          },
          (err) => {
      
          }
        );
            // })        
          }
        })
    
      }

      rejectApplicationForNg(){
        const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
          width: "550px",
          data: {
            text : 'Are you sure you want to reject the application for Notice Generation.',
            status : 'RejectNotice Generation',
            component : 'Tax scrutiny'
          },
          autoFocus: false,
          restoreFocus: false,
        });
    
    
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result.status == "success") {
            // this.propertyService.rejectApplication(this.applicationData[0].id,result.remark).then((data : any) => {
              this.noticeGeneratedForm.patchValue({'rejectRemark':result.remark})

               this.propertyService.applicationSubmitPropertyTaxScrutiny(this.noticeGeneratedForm.value).subscribe(
          (res) => {
            if(res.status == "SUCCESS"){
              this._snackBar.open('Rejected', 'Close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 3000,
              });
              this.location.back()

            }
           
          },
          (err) => {
      
          }
        );
            // })        
          }
        })
    
      }

     

      saveScrutinyDetailsForForwardHod(){
        this.approveApplicationForForwardHod(this.applicationData)
       
      }

      approveApplicationForForwardHod(element : any){
        const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
          width: "550px",
          data: {
            text : 'Are you sure you want to approve the application for notice approved.',
            status : 'Approval for notice approved',
            component : 'Tax scrutiny'
          },
          autoFocus: false,
          restoreFocus: false,
        });
    
    
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result.status == "success") {
            // this.propertyService.approveApplication(element[0].id,result.remark).then((data : any) => {
              this.forwardNoticeForm.patchValue({'approveRemark':result.remark})
               this.propertyService.applicationSubmitPropertyTaxScrutiny(this.forwardNoticeForm.value).subscribe(
          (res) => {
            if(res.status == "SUCCESS"){
              this._snackBar.open('Approval Successful', 'Close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 3000,
              });
              this.location.back()

            }
           
          },
          (err) => {
      
          }
        );
            // })        
          }
        })
    
      }

      rejectApplicationForwardHod(){
        const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
          width: "550px",
          data: {
            text : 'Are you sure you want to reject the application for notice approved.',
            status : 'Reject notice approved',
            component : 'Tax scrutiny'
          },
          autoFocus: false,
          restoreFocus: false,
        });
    
    
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result.status == "success") {
            // this.propertyService.rejectApplication(this.applicationData[0].id,result.remark).then((data : any) => {
              this.forwardNoticeForm.patchValue({'rejectRemark':result.remark})

               this.propertyService.applicationSubmitPropertyTaxScrutiny(this.forwardNoticeForm.value).subscribe(
          (res) => {
            if(res.status == "SUCCESS"){
              this._snackBar.open('Rejected', 'Close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 3000,
              });
              this.location.back()

            }
           
          },
          (err) => {
      
          }
        );
            // })        
          }
        })
    
      }

     

      saveScrutinyDetailsFoNoticeIssued(){
        this.approveApplicationForNoticeIssued(this.applicationData)
        console.log(this.applicationData)
       
      }

      approveApplicationForNoticeIssued(element : any){
        const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
          width: "550px",
          data: {
            text : 'Are you sure you want to approve the application for Notice Issual.',
            status : 'Approval for Notice Issual',
            component : 'Tax scrutiny'
          },
          autoFocus: false,
          restoreFocus: false,
        });
    
    
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result.status == "success") {
              this.noticeIssuedForm.patchValue({'approveRemark':result.remark})
               this.propertyService.applicationSubmitPropertyTaxScrutiny(this.noticeIssuedForm.value).subscribe(
          (res) => {
            if(res.status == "SUCCESS"){
              this._snackBar.open('Approval Successful', 'Close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 3000,
              });
              // this.router.navigate(['/propertyTax-scrutiny'])
              this.location.back()

            }
           
          },
          (err) => {
      
          }
        );
            // })        
          }
        })
    
      }

      rejectApplicationForNoticeIssued(){
        const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
          width: "550px",
          data: {
            text : 'Are you sure you want to reject the application for Notice Issual.',
            status : 'Reject Notice Issual',
            component : 'Tax scrutiny'
          },
          autoFocus: false,
          restoreFocus: false,
        });
    
    
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result.status == "success") {
            // this.propertyService.rejectApplication(this.applicationData[0].id,result.remark).then((data : any) => {
              this.noticeIssuedForm.patchValue({'rejectRemark':result.remark})

               this.propertyService.applicationSubmitPropertyTaxScrutiny(this.noticeIssuedForm.value).subscribe(
          (res) => {
            if(res.status == "SUCCESS"){
              this._snackBar.open('Rejected', 'Close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 3000,
              });
              this.location.back()

            }
           
          },
          (err) => {
      
          }
        );
            // })        
          }
        })
    
      }

      openDocument(docPath : any){
        console.log(docPath)
        const imageUrl = environment.commonUrl + '/api/file/preview?filePath=' + docPath;
        console.log(imageUrl)
        this.dialog.open(ViewImgDocComponent, {
          data: imageUrl,
          maxWidth: '100%',
        });
        // this.documentService.viewDocs(fileName).then((data  : any) => {
        //   if(fileName.includes('csv') || fileName.includes('xls') || fileName.includes('xlsx')){
        //     const blob = new Blob([data],
        //       { type:".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"});
        //   const file = new File([blob], fileName,
        //   { type:".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"});
      
        //   FileSaver.saveAs(file);
        //   }else {
        //     let file = new Blob([data], { type: "application/pdf,image/jpg,application/octet-stream" });            
        //     var fileURL = URL.createObjectURL(file);
        //     importedSaveAs(file, fileName);
      
        //   }
         
        // })

        this.uploadService.getFilePreviewSingle(docPath).then((data : any) => {
          console.log(data)
        //   const blob = new Blob([data],
        //     { type:".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"});
        // const file = new File([blob], 'File',
        // { type:".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"});
    
        
        // FileSaver.saveAs(file);
  
      })
       
      
      }

      uploadAadesh(event : any){
        for(var i = 0; i < event.target.files.length;i++){
          this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
              
            this.sunavaniScheduledForm.patchValue({'aadesh':JSON.parse(data).filePath})
            this.showAadeshUpload = true

        })
        }
      }

      saveScrutinyDetailsForSunavaniScheduled(){
        console.log(this.sunavaniScheduledForm.value)
        this.propertyService.applicationSubmitPropertyTaxScrutiny(this.sunavaniScheduledForm.value).subscribe(
          (res) => {
            console.log(res)
            if(res.status == "SUCCESS"){
              this._snackBar.open('Required documents uploaded successfully', 'Close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 3000,
              });
             this.location.back()

            }
           
          },
          (err) => {
      
          }
        );
       
      }

      saveScrutinyDetailsForAadeshGenerated(){
        this.approveApplicationForAadeshGenerated(this.applicationData)
       
      }

      approveApplicationForAadeshGenerated(element : any){
        const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
          width: "550px",
          data: {
            text : 'Are you sure you want to approve the application for Aadesh Generated.',
            status : 'Approval for Aadesh Generated',
            component : 'Tax scrutiny'
          },
          autoFocus: false,
          restoreFocus: false,
        });
    
    
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result.status == "success") {
            // this.propertyService.approveApplication(element[0].id,result.remark).then((data : any) => {
              this.aadeshGeneratedForm.patchValue({'approveRemark':result.remark})
               this.propertyService.applicationSubmitPropertyTaxScrutiny(this.aadeshGeneratedForm.value).subscribe(
          (res) => {
            if(res.status == "SUCCESS"){
              this._snackBar.open('Approval Successful', 'Close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 3000,
              });
              this.location.back()

            }
           
          },
          (err) => {
      
          }
        );
            // })        
          }
        })
    
      }

      rejectApplicationForAadeshGenerated(){
        const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
          width: "550px",
          data: {
            text : 'Are you sure you want to reject the application for Aadesh Generated.',
            status : 'Reject Aadesh Generated',
            component : 'Tax scrutiny'
          },
          autoFocus: false,
          restoreFocus: false,
        });
    
    
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result.status == "success") {
            // this.propertyService.rejectApplication(this.applicationData[0].id,result.remark).then((data : any) => {
              this.aadeshGeneratedForm.patchValue({'rejectRemark':result.remark})

               this.propertyService.applicationSubmitPropertyTaxScrutiny(this.aadeshGeneratedForm.value).subscribe(
          (res) => {
            if(res.status == "SUCCESS"){
              this._snackBar.open('Rejected', 'Close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 3000,
              });
              this.location.back()

            }
           
          },
          (err) => {
      
          }
        );
            // })        
          }
        })
    
      }

      saveScrutinyDetailsForAadeshApproved(){
        this.approveApplicationForAadehApproved(this.applicationData)
       
      }

      approveApplicationForAadehApproved(element : any){
        const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
          width: "550px",
          data: {
            text : 'Are you sure you want to approve the application for aadesh approval.',
            status : 'Approval for aadesh approval',
            component : 'Tax scrutiny'
          },
          autoFocus: false,
          restoreFocus: false,
        });
    
    
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result.status == "success") {
            // this.propertyService.approveApplication(element[0].id,result.remark).then((data : any) => {
              this.aadeshApprovedForm.patchValue({'approveRemark':result.remark})
              this.aadeshApprovedForm.patchValue({'rejectRemark':''})

               this.propertyService.applicationSubmitPropertyTaxScrutiny(this.aadeshApprovedForm.value).subscribe(
          (res) => {
            if(res.status == "SUCCESS"){
              this._snackBar.open('Approval Successful', 'Close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 3000,
              });
                this.location.back()
            }
           
          },
          (err) => {
      
          }
        );
            // })        
          }
        })
    
      }

      rejectApplicationForAadeshApproval(){
        const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
          width: "550px",
          data: {
            text : 'Are you sure you want to reject the application for aadesh approval.',
            status : 'Reject aadesh approval',
            component : 'Tax scrutiny'
          },
          autoFocus: false,
          restoreFocus: false,
        });
    
    
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result.status == "success") {
            // this.propertyService.rejectApplication(this.applicationData[0].id,result.remark).then((data : any) => {
              this.aadeshApprovedForm.patchValue({'rejectRemark':result.remark})
              this.aadeshApprovedForm.patchValue({'approveRemark':''})

               this.propertyService.applicationSubmitPropertyTaxScrutiny(this.aadeshApprovedForm.value).subscribe(
          (res) => {
            if(res.status == "SUCCESS"){
              this._snackBar.open('Rejected', 'Close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 3000,
              });
                this.location.back()
            }
           
          },
          (err) => {
      
          }
        );
            // })        
          }
        })
    
      }

      saveScrutinyDetailsForApplicationApproved(){
        this.approveApplicationForApplicationApproved(this.applicationData)
       
      }

      approveApplicationForApplicationApproved(element : any){
        const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
          width: "550px",
          data: {
            text : 'Are you sure you want to approve the application.',
            status : 'Approval',
            component : 'Tax scrutiny'
          },
          autoFocus: false,
          restoreFocus: false,
        });
    
    
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result.status == "success") {
            // this.propertyService.approveApplication(element[0].id,result.remark).then((data : any) => {
              this.applicationApprovedForm.patchValue({'approveRemark':result.remark})
              this.applicationApprovedForm.patchValue({'rejectRemark':''})

               this.propertyService.applicationSubmitPropertyTaxScrutiny(this.applicationApprovedForm.value).subscribe(
          (res) => {
            if(res.status == "SUCCESS"){
              this._snackBar.open('Approval Successful', 'Close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 3000,
              });
                this.location.back()
            }
           
          },
          (err) => {
      
          }
        );
            // })        
          }
        })
    
      }

      rejectApplicationForApplicatonApproved(){
        const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
          width: "550px",
          data: {
            text : 'Are you sure you want to reject the application.',
            status : 'Reject',
            component : 'Tax scrutiny'
          },
          autoFocus: false,
          restoreFocus: false,
        });
    
    
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result.status == "success") {
            // this.propertyService.rejectApplication(this.applicationData[0].id,result.remark).then((data : any) => {
              this.applicationApprovedForm.patchValue({'rejectRemark':result.remark})
              this.applicationApprovedForm.patchValue({'approveRemark':''})

               this.propertyService.applicationSubmitPropertyTaxScrutiny(this.applicationApprovedForm.value).subscribe(
          (res) => {
            if(res.status == "SUCCESS"){
              this._snackBar.open('Rejected', 'Close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 3000,
              });
                this.location.back()
            }
           
          },
          (err) => {
      
          }
        );
            // })        
          }
        })
    
      }
    

      cancel(){
        this.location.back()
    
      }
}

export interface RowElement {
  key: string;
  value: string;
}