import { Component, ViewChild } from '@angular/core';
import { AddMeetingSheduleComponent } from './add-meeting-shedule/add-meeting-shedule.component';
import { MatTableDataSource } from '@angular/material/table';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-meeting-schedule-master',
  templateUrl: './meeting-schedule-master.component.html',
  styleUrls: ['./meeting-schedule-master.component.scss']
})
export class MeetingScheduleMasterComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  data: any;
 

columnNames: any[] = [
 
  {
    columnDef: 'sno',
    header: 'Sr.No.',
  },
  {
    columnDef: 'committee',
    header: 'Committee',
  },
  {
    columnDef: 'date',
    header: 'Date',
  },
  {
    columnDef: 'time',
    header: 'Time',
  },
  {
    columnDef: 'actions',
    header: 'Actions',
  },
];
departments = new MatTableDataSource<any>();
displayedColumns: any[] = []
contentLoaded = false;
constructor(private msmService: MsmamServiceService,
  private dialog: MatDialog,
  private _snackBar: SnackbarMsgService) {

}
ngOnInit() {
  this.displayedColumns = this.displayedColumns.concat(this.columnNames.map(x => x.columnDef));
  this.getShedule()
}

getShedule() {
  this.msmService.getMeetingschedule().subscribe(
    data => {
      this.departments.data = data['meetingScheduleMasterList'];
      this.departments.data = this.departments.data.sort((a, b) => a.id - b.id);
      setTimeout(
        () => {
          this.departments.sort = this.sort;
          this.departments.paginator = this.paginator;
        });

      setTimeout(() => {
        this.contentLoaded = true;
      }, 2000);

    },
    err => {
      this.contentLoaded = true;

    }
  )
}

addShedule() {
  const dialogRef = this.dialog.open(AddMeetingSheduleComponent, {
    width: "550px",
    data: {
      isEditMode: false,
    },
  });

  dialogRef.afterClosed().subscribe((result: any) => {
    if (result.data == "Success") {
      this.contentLoaded = false;
      this.getShedule()
    }
  });
}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.departments.filter = filterValue.trim().toLowerCase();
}

onSlideToggleChange(row: any, event: any): void {

  row.activeFlag = event.checked ? 'Y' : 'N';
  if(event.checked) {
  this.msmService.activeInactiveMeetingschedule(row.id, row.activeFlag).subscribe(
    res => {
      this._snackBar.success('Metting Shedule is Activated');
    },
    err => {
      this._snackBar.error('Activation Flag Failed to update')
    }
  )

} else {
  this.msmService.activeInactiveMeetingschedule(row.id, row.activeFlag).subscribe(
    res => {
      this._snackBar.error('Metting Shedule is Deactivated');
      this.getShedule();
    },
    err => {
      this._snackBar.error('Activation Flag Failed to Delete')
    })
}
   
  }
onEdit(row: any){
  const dialogRef = this.dialog.open(AddMeetingSheduleComponent, {
    width: "550px",
    data: {
      isEditMode: true,
      row: row
    },
  });

  dialogRef.afterClosed().subscribe((result: any) => {
    if (result.data == "Success") {
      this.contentLoaded = false;
      this.getShedule()
    }
  });
}

}
