import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationScrutinyService } from 'src/app/_services/application-scrutiny.service';
import { FileUploadService } from 'src/app/_services/file-upload.service';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ScrutinyActionsConfirmationComponent } from './scrutiny-actions-confirmation/scrutiny-actions-confirmation.component';
import { ScrutinyService } from 'src/app/_services/CWM/scrutiny.service';

@Component({
  selector: 'app-cwm-app-scrutiny',
  templateUrl: './cwm-app-scrutiny.component.html',
  styleUrls: ['./cwm-app-scrutiny.component.scss']
})
export class CwmAppScrutinyComponent {
  activatedRoute: any;
  state : any
  applicationData : any[] = []
  form!: FormGroup;
  showonAccept : boolean = true
  takeAction : boolean = false
  appId : any = ''
  fileLocation : any = '';
  showPayment:boolean = false;
  data:any;
  status : any = ''
  text : any = ''
  competitiveQuotes3 : any = ''
  competitiveQuotes2 : any = ''
  competitiveQuotes1 : any = ''
  selectedQuote : any = ''
  allQuotesUploaded : boolean = false
  showWorkOrder : boolean = false
  workUpload : any = ''
  formDisabled : boolean = true
  paymentReceipt : any  = ''
  invoice : any = ''
  showReceipt : boolean = false
  showInvoice : boolean = false
  approveRemark : any = ''
  remarkForApprove : any = ''
  vehicleId : any = ''
  driverId: any = ''
  receipt: any = ''
  description: any = ''
  activeFlag: any = ''
  driverName: any = ''
  ammount: any = ''
  appStatus: any = ''
  vehicleRegistrationNo: any = ''
  rejectRemark : any = ''
  contentLoadedSuccess1 : any = true
  loading1 : any = false;
  contentLoadedSuccess2 : any = true
  loading2 : any = false;
  contentLoadedSuccess3 : any = true
  loading3 : any = false;
  showImage1 : any = 'N'
  showImage2 : any = 'N'
  showImage3 : any = 'N'
  quote1Url : any = ''
  quote2Url : any = ''
  quote3Url : any = ''

  constructor(private appScrutinyService: ScrutinyService,
    private fb: FormBuilder,
    private _snackBar : MatSnackBar,
    private dialog : MatDialog,
    private uploadService : FileUploadService,
    private location : Location){}
    

    ngOnInit(): void {
      this.state = history.state;
      this.applicationData = this.state['applicationData']
      this.vehicleId = this.applicationData['vehicleId']
      this.driverId = this.applicationData['driverId']
      this.receipt = this.applicationData['receipt']
      this.description = this.applicationData['description']
      this.activeFlag = this.applicationData['activeFlag']
      this.driverName = this.applicationData['driverName']
      this.ammount = this.applicationData['ammount']
      this.appStatus = this.applicationData['status']
      this.vehicleRegistrationNo = this.applicationData['vehicleRegistrationNo']
      this.remarkForApprove = this.applicationData['approveRemark']
      this.approveRemark = this.applicationData['status']

      if(this.applicationData['status'] == null){
        this.showonAccept = true
        this.approveRemark = 'APPROVED'
      }
     
      this.appId = this.applicationData['id']
  
     
  
    }


    handleActionChange(event : any){
      var action = event.target.value
      if(action == 'A'){
        // this.form.patchValue({'accepted' : 'A'}) 
        this.showonAccept = true
        this.status = 'Action'
        this.text = 'Are you sure you want to approve the application'
        this.approveRemark = 'APPROVED'
  
      }else if(action == 'R') {
        // this.form.patchValue({'accepted' : 'R'}) 
        this.showonAccept = false
        this.status = 'Action'
        this.text = 'Are you sure you want to reject the application'
        this.approveRemark = 'REJECTED'


      }

      this.checkScrutiny()
  
    }
  
    // saveScrutiny(){
    
    //   if(this.showonAccept == true){
    //     this.appScrutinyService.approveApplication(this.appId,this.form.get('remark')?.value,this.form.get('payment')?.value,this.fileLocation).then((data : any) => {
    //       if(JSON.parse(data).status=="Success"){
    //         this._snackBar.open('Approval success', 'Close', {
    //           horizontalPosition: 'right',
    //           verticalPosition: 'top',
    //           duration: 3000,
    //         });
    //         this.location.back()
  
          
    //       }else {
    //         this._snackBar.open('Approval failure', 'Close', {
    //           horizontalPosition: 'right',
    //           verticalPosition: 'top',
    //           duration: 3000,
    //         });
    //       }
    //     })
    //   }else if(this.showonAccept == false) {
    //     this.appScrutinyService.rejectApplication(this.appId,this.form.get('remark')?.value,this.fileLocation).then((data : any) => {
    //       if(JSON.parse(data).status=="Success"){
    //         this._snackBar.open('Rejected success', 'Close', {
    //           horizontalPosition: 'right',
    //           verticalPosition: 'top',
    //           duration: 3000,
    //         });
    //         this.location.back()
  
  
          
    //       }else {
    //         this._snackBar.open('Rejected failure', 'Close', {
    //           horizontalPosition: 'right',
    //           verticalPosition: 'top',
    //           duration: 3000,
    //         });
    //       }
    //     })
    //   }
  
    // }

    enterRemark(){
      if(this.showonAccept == true){
        this.remarkForApprove = this.rejectRemark
        this.rejectRemark = ''
      }else {
        if(this.rejectRemark != '') {
          this.formDisabled = false
        }      
      }
     
    }

    checkScrutiny(){
      
      const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
        width: "550px",
        data: {
          text : this.text,
          status : this.status
        },
        autoFocus: false,
        restoreFocus: false,
      });
  
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result == "success") {
        }
      })
    }


  
    upload(event : any){
      if(event.target.files.length > 3){
        this._snackBar.open('You can select only 3 files', 'Close', {
              horizontalPosition: 'right',
              verticalPosition: 'top',
              duration: 3000,
            });
      }else if(event.target.files.length < 3){
        this._snackBar.open('Please select upto 3 files', 'Close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 3000,
        });
      }else {
        for(var i = 0; i < event.target.files.length;i++){
          let count = i + 1
          if(event.target.files[i].type.includes('image')){
            this.showImage1 = 'Y'
            this.showImage2 = 'Y'
            this.showImage3 = 'Y'

          }else {
            this.showImage1 = 'P'
            this.showImage2 = 'P'
            this.showImage3 = 'P'
          }
          this.uploadCompetetiveQuotesFiles(event.target.files[i],count)
        }
      }
    
      
  
    }

    uploadCompetetiveQuotesFiles(file : any,count : any){
      // this.loading1 = true
      // this.loading2 = true
      // this.loading3 = true
    this.uploadService.uploadFile(file).then((data : any) => {
           
            if(count == 1){
              this.competitiveQuotes1 = JSON.parse(data).filePath
              this.quote1Url = 'https://15-206-219-76.nip.io:8090' + '/api/file/preview?filePath=' + this.competitiveQuotes1
             

            }else if(count == 2){
              this.competitiveQuotes2 = JSON.parse(data).filePath
              this.quote2Url = 'https://15-206-219-76.nip.io:8090' + '/api/file/preview?filePath=' + this.competitiveQuotes2


            }else {
              this.competitiveQuotes3 = JSON.parse(data).filePath
              this.quote3Url = 'https://15-206-219-76.nip.io:8090' + '/api/file/preview?filePath=' + this.competitiveQuotes3


            }

            if(this.competitiveQuotes1 != '' && this.competitiveQuotes2 != '' && this.competitiveQuotes3 != ''){
              this.allQuotesUploaded = true
             
              this.approveRemark = 'QUATATION_SUBMITTED'
              this._snackBar.open('Competitive Quotes uploaded successfully', 'Close', {
                horizontalPosition: 'right',
                verticalPosition: 'top',
                duration: 3000,
              });
            }
          
          })

         

    }

    handleQuoteChange(quote : any){
      if(quote == 'Quote1'){
        this.selectedQuote = this.competitiveQuotes1

      }else if(quote == 'Quote2'){
        this.selectedQuote = this.competitiveQuotes2

      }else if(quote == 'Quote3'){
        this.selectedQuote = this.competitiveQuotes3
        
      }
      this.showWorkOrder = true
    }

    uploadWorkOrder(event : any){
      for(var i = 0; i < event.target.files.length; i++){
        this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
          this._snackBar.open('Work order uploaded successfully', 'Close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 3000,
          });
          this.workUpload = JSON.parse(data).filePath
          this.showInvoice = true
          this.approveRemark = 'WORKORDER_SUBMITTED'
        })
      }

    }

    uploadInvoice(event : any){
      for(var i = 0; i < event.target.files.length; i++){
        this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
          this._snackBar.open('Invoice uploaded successfully', 'Close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 3000,
          });
          this.invoice = JSON.parse(data).filePath
          this.showReceipt = true
          this.approveRemark = 'INVOICE_SUBMITTED'

        })
      }
      
    }

    uploadPaymentReceipt(event : any){
      for(var i = 0; i < event.target.files.length; i++){
        this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
          this._snackBar.open('Payment receipt uploaded successfully', 'Close', {
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: 3000,
          });
          this.paymentReceipt = JSON.parse(data).filePath
          this.approveRemark = 'PAYMENT_SUBMITTED'
          this.formDisabled = false
          // this.checkAllFields()
        })
      }
      
    }

    checkAllFields(){
    
      if(this.approveRemark == 'APPROVED'){
        if(this.competitiveQuotes1 != '' &&
        this.competitiveQuotes2 != '' &&
        this.competitiveQuotes3 != '' &&
        this.selectedQuote != '' &&
        this.workUpload != '' &&
        this.invoice != '' &&
        this.paymentReceipt != ''){
          this.formDisabled = false
        }
      }else if(this.approveRemark == 'REJECTED'){
        this.competitiveQuotes1 = ''
        this.competitiveQuotes2 = ''
        this.competitiveQuotes3 = ''
        this.selectedQuote = ''
        this.workUpload = ''
        this.invoice = ''
        this.paymentReceipt = ''
        if(this.rejectRemark != ''){
          this.formDisabled = false
        }

      }


      this.appScrutinyService.saveScrutiny(this.appId,this.vehicleId,this.driverId,this.receipt,this.description,this.activeFlag,
        this.driverName,this.ammount,this.remarkForApprove,this.appStatus,this.vehicleRegistrationNo,this.competitiveQuotes1,
        this.competitiveQuotes2,this.competitiveQuotes3,this.selectedQuote,this.workUpload,this.invoice,this.paymentReceipt,this.rejectRemark).then((data : any) => {
          if(data.status == 'SUCCES'){
            this._snackBar.open('Application scrutiny successfull', 'Close', {
              horizontalPosition: 'right',
              verticalPosition: 'top',
              duration: 3000,
            });
            this.location.back()

          }
        })
    }
  
    cancel() {
      // this.form.reset();
      this.location.back()
     }
  
    

    saveScrutiny(){
      this.checkAllFields()
    }

    contentLoaded3(){
      this.contentLoadedSuccess3 = true
      this.loading3 = false;
    }

    contentLoaded2(){
      this.contentLoadedSuccess2 = true
      this.loading2 = false;
    }

    contentLoaded1(){
      this.contentLoadedSuccess1 = true
      this.loading1 = false;
    }
     
}
