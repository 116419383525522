import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { CategoryComponent } from "./EWM/Masters/category/category.component";
import { EwmManageWorflowComponent } from "./EWM/Masters/manage-workflow-table/ewm-manage-worflow/ewm-manage-worflow.component";
import { ModulesComponent } from "./EWM/Masters/modules/modules.component";
import { WidgetsComponent } from "./EWM/Masters/widgets/widgets.component";
import { WorkflowStagesComponent } from "./EWM/Masters/workflow-stages/workflow-stages.component";
import { WorkflowComponent } from "./EWM/Masters/workflow/workflow.component";
import { EnterpriceWorkflowManagementDashboardComponent } from "./EWM/enterprice-workflow-management-dashboard/enterprice-workflow-management-dashboard.component";
import { HrmsDashboardComponent } from "./HRMS/hrms-dashboard/hrms-dashboard.component";
import { CalenderSchedulingAndHolidayComponent } from "./MSMAM/calender-scheduling-and-holiday/calender-scheduling-and-holiday.component";
import { CasteMasterComponent } from "./MSMAM/caste-master/caste-master.component";
import { CommitteeEstablishmentMasterComponent } from "./MSMAM/committee-establishment-master/committee-establishment-master.component";
import { CommitteeMasterComponent } from "./MSMAM/committee-master/committee-master.component";
import { CommitteeMembersMasterComponent } from "./MSMAM/committee-members-master/committee-members-master.component";
import { CorporatorMasterComponent } from "./MSMAM/corporator-master/corporator-master.component";
import { ElectoralWardMasterComponent } from "./MSMAM/electoral-ward-master/electoral-ward-master.component";
import { IdentifiactionproofMasterComponent } from "./MSMAM/identifiactionproof-master/identifiactionproof-master.component";
import { MeetingScheduleMasterComponent } from "./MSMAM/meeting-schedule-master/meeting-schedule-master.component";
import { MeetingTypeMasterComponent } from "./MSMAM/meeting-type-master/meeting-type-master.component";
import { MsmamDashboardComponent } from "./MSMAM/msmam-dashboard/msmam-dashboard.component";
import { OfiicesMasterComponent } from "./MSMAM/ofiices-master/ofiices-master.component";
import { PartyMasterComponent } from "./MSMAM/party-master/party-master.component";
import { ReligionMasterComponent } from "./MSMAM/religion-master/religion-master.component";
import { SubcastMasterComponent } from "./MSMAM/subcast-master/subcast-master.component";
import { NewDocketentryComponent } from "./MSMAM/transactions/new-docketentry/new-docketentry.component";
import { PrepareMeetingAgendaAndFinalizationComponent } from "./MSMAM/transactions/prepare-meeting-agenda-and-finalization/prepare-meeting-agenda-and-finalization.component";
import { CenterMmComponent } from "./Material-Management/Masters/center-mm/center-mm.component";
import { CenterTypeMmComponent } from "./Material-Management/Masters/center-type-mm/center-type-mm.component";
import { ManufactureComponent } from "./Material-Management/Masters/manufacture/manufacture.component";
import { MaterialCategoryComponent } from "./Material-Management/Masters/material-category/material-category.component";
import { MaterialGroupComponent } from "./Material-Management/Masters/material-group/material-group.component";
import { MaterialItemComponent } from "./Material-Management/Masters/material-item/material-item.component";
import { MaterialSubCategoryComponent } from "./Material-Management/Masters/material-sub-category/material-sub-category.component";

import { AddEditPropertyTaxComponent } from "./PROPERTY-TAX/property-master/add-edit-property-tax/add-edit-property-tax.component";
import { PropertyTaxDashboardComponent } from "./PROPERTY-TAX/property-tax-dashboard/property-tax-dashboard.component";
import { PropertyTaxScrutinyActionsComponent } from "./PROPERTY-TAX/property-tax-scrutiny/property-tax-scrutiny-actions/property-tax-scrutiny-actions.component";

import { ApplicationFormDocumentsComponent } from "./application-form-documents/application-form-documents.component";
import { ApplicationFormComponent } from "./application-form/application-form.component";
import { AssetDashboardComponent } from "./assetManagement/asset-dashboard/asset-dashboard.component";
import { AssetAssetComponent } from "./assetManagement/master/asset-asset/asset-asset.component";
import { AssetCategoryComponent } from "./assetManagement/master/asset-category/asset-category.component";
import { AssetEqpBreakdownTypeComponent } from "./assetManagement/master/asset-eqp-breakdown-type/asset-eqp-breakdown-type.component";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { DocumentMasterComponent } from "./document-master/document-master.component";
import { FileUploadComponent } from "./file-upload/file-upload/file-upload.component";
import { TokenAuthGuard } from "./guard/auth.guard";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./login/register/register.component";
import { ReligionComponent } from "./master-data/religion/religion.component";
import { MasterServiceComponent } from "./master-service/master-service.component";
import { CheckPaymentStatusComponent } from "./shared/component/payment/check-payment-status/check-payment-status.component";
import { DynamicEditableTableComponent } from "./dynamic-editable-table/dynamic-editable-table.component";
import { CwmAppScrutinyComponent } from "./CWM/cwm-dashboard/cwm-app-scrutiny/cwm-app-scrutiny.component";
import { ScrutinyFlowComponent } from "./CWM/cwm-dashboard/cwm-app-scrutiny/scrutiny-flow/scrutiny-flow.component";
import { DriverDashboardComponent } from "./CWM/driver-dashboard/driver-dashboard.component";
import { QrCodeComponent } from "./qr-code/qr-code.component";
import { MaterialComponent } from "./Material-Management/Masters/material/material.component";
import { TitleMasterCommonComponent } from "./master-data/title-master-common/title-master-common.component";
import { UserModuleMappingComponent } from "./common/user-module-mapping/user-module-mapping.component";
import { MappedDashboardComponent } from "./mapped-dashboard/mapped-dashboard.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { MediaChannelMasterComponent } from "./MSMAM/media-channel-master/media-channel-master-component.component";
import { MediaMasterComponent } from "./MSMAM/media-master/media-master.component";
import { JournalistMasterComponent } from "./MSMAM/journalist-master/journalist-master.component";
import { ClerkToCommitteeMaster1Component } from "./MSMAM/clerk-to-committee-master1/clerk-to-committee-master1.component";
import { RolesPermissionsMasterComponent } from "./roles-permissions/roles-permissions-master/roles-permissions-master.component";
import { ForgotPasswordComponent } from "./login/forgot-password/forgot-password.component";
import { UserProfileComponent } from "./master-data/user-profile/user-profile.component";

const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "register",
    component: RegisterComponent,
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "mapped-dashboard",
    component: MappedDashboardComponent,
    canActivate: [TokenAuthGuard],
  },

  {
    path: "404",
    component: NotFoundComponent,
  },
  // {
  //   path: "dynamic-form",
  //   component: DynamicFormComponent,
  //   canActivate: [TokenAuthGuard],
  // },
  {
    path: "master-service",
    component: MasterServiceComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "application-form/:id",
    component: ApplicationFormComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "application-form-documents/:id",
    component: ApplicationFormDocumentsComponent,
    canActivate: [TokenAuthGuard],
  },

  {
    path: "title-master",
    component: TitleMasterCommonComponent,
    canActivate: [TokenAuthGuard],
  },

  {
    path: "file-upload",
    component: FileUploadComponent,
    canActivate: [TokenAuthGuard],
  }, 
  {
    path: "user-profile",
    component: UserProfileComponent,
  },

  {
    path: "paymentStatus",
    component: CheckPaymentStatusComponent,
    canActivate: [TokenAuthGuard],
  },

  {
    path: "cwm-appScrutiny",
    component: CwmAppScrutinyComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "maintenance-scrutiny",
    component: ScrutinyFlowComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "driver-Dash",
    component: DriverDashboardComponent,
    canActivate: [TokenAuthGuard],
  },

  {
    path: "property-tax-dashboard",
    component: PropertyTaxDashboardComponent,
    canActivate: [TokenAuthGuard],
  },

  // {
  //   path:'disaster-document-upload/:id',
  //   component:AddDocumentComponent,
  //   canActivate:[TokenAuthGuard]
  // },
  {
    path: "msmam-dashboard",
    component: MsmamDashboardComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "msmam-caste-master",
    component: CasteMasterComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "msmam-committee-establishment-master",
    component: CommitteeEstablishmentMasterComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "msmam-committee-master",
    component: CommitteeMasterComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "msmam-committee-members-master",
    component: CommitteeMembersMasterComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "msmam-corporator-master",
    component: CorporatorMasterComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "msmam-electoral-ward-master",
    component: ElectoralWardMasterComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "msmam-identifiactionproof-master",
    component: IdentifiactionproofMasterComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "msmam-meeting-schedule-master",
    component: MeetingScheduleMasterComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "msmam-meeting-type-master",
    component: MeetingTypeMasterComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "msmam-offices-master",
    component: OfiicesMasterComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "msmam-party-master",
    component: PartyMasterComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "msmam-religion-master",
    component: ReligionMasterComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "msmam-subcast-master",
    component: SubcastMasterComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "msmam-calender-scheduling-master",
    component: CalenderSchedulingAndHolidayComponent,
    canActivate: [TokenAuthGuard],
  },
  // {
  //   path: "msmam-new-docketentry-transaction",
  //   component: NewDocketentryComponent,
  //   canActivate: [TokenAuthGuard],
  // },
  // {
  //   path: "msmam-prepare-meeting-agenda-transaction",
  //   component: PrepareMeetingAgendaAndFinalizationComponent,
  //   canActivate: [TokenAuthGuard],
  // },
  {
    path: "msmam-media-channel-master",
    component: MediaChannelMasterComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "msmam-media-master",
    component: MediaMasterComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "msmam-journalist-master",
    component: JournalistMasterComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "msmam-clerk-to-committee-master",
    component: ClerkToCommitteeMaster1Component,
    canActivate: [TokenAuthGuard],
  },

  {
    path: "property-tax-registration",
    component: AddEditPropertyTaxComponent,
    canActivate: [TokenAuthGuard],
  },

  {
    path: "hrms-dashboard",
    component: HrmsDashboardComponent,
    canActivate: [TokenAuthGuard],
  },

  {
    path: "religion",
    component: ReligionComponent,
    canActivate: [TokenAuthGuard],
  },

  {
    path: "propertyTax-scrutiny-actions",
    component: PropertyTaxScrutinyActionsComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "cfc",
    loadChildren: () => import("./CFC/cfc.module").then((m) => m.CFCModule),
  },
  {
    path: "core-module",
    loadChildren: () =>
      import("./master-data/core-module.module").then(
        (m) => m.CoreModuleModule
      ),
  },
  {
    path: "solid-waste-management",
    loadChildren: () =>
      import("./solid-waste-management/solid-waste-management.module").then(
        (m) => m.SolidWasteManagementModule
      ),
  },
  {
    path: "work-management",
    loadChildren: () =>
      import("./work-management/work-management.module").then(
        (m) => m.WorkManagementModule
      ),
  },
  {
    path: "land-Estate",
    loadChildren: () =>
      import("./land-and-estate/land-and-estate.module").then(
        (m) => m.LandAndEstateModule
      ),
  },
  {
    path: "property-tax",
    loadChildren: () =>
      import("./PROPERTY-TAX/property-tax.module").then(
        (m) => m.PropertyTaxModule
      ),
  },
  {
    path: "enterprise-workflow-management",
    loadChildren: () =>
      import("./EWM/enterprice-workflow-management.module").then(
        (m) => m.EnterpriceWorkflowManagementModule
      ),
  },
  {
    path: "material-management",
    loadChildren: () =>
      import("./Material-Management/material-management.module").then(
        (m) => m.MaterialManagementModule
      ),
  },
  {
    path: "financial-accounting",
    loadChildren: () =>
      import("./Financial-Accounting/financial-accounting.module").then(
        (m) => m.FinancialAccounting
      ),
  },
  {
    path: "survey-database",
    loadChildren: () =>
      import("./survey-database-creation/survey-database.module").then(
        (m) => m.SurveyDatabase
      ),
  },
  {
    path: "central-workshop",
    loadChildren: () =>
      import("./CWM/central-workshop-management.module").then(
        (m) => m.CentralWorkshop
      ),
  },
  {
    path: "disaster-management",
    loadChildren: () =>
      import("./DisasterManagementMaster/disaster-management.module").then(
        (m) => m.DisasterManagement
      ),
  },
  {
    path: "content-management",
    loadChildren: () =>
      import("./CMS/content-management.module").then((m) => m.ContentMangement),
  },
  {
    path: "hrms-module",
    loadChildren: () =>
      import("./HRMS/hrms/hrms.module").then((m) => m.HrmsModule),
  },

  {
    path: "center",
    component: CenterMmComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "centerType",
    component: CenterTypeMmComponent,
    canActivate: [TokenAuthGuard],
  },

  {
    path: "asset-asset",
    component: AssetAssetComponent,
    canActivate: [TokenAuthGuard],
  },

  {
    path: "edit-table",
    component: DynamicEditableTableComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "asset-management",
    loadChildren: () =>
      import("./assetManagement/asset-management.module").then(
        (m) => m.AssetManagementModule
      ),
  },
  {
    path: "solid-waste-management",
    loadChildren: () =>
      import("./solid-waste-management/solid-waste-management.module").then(
        (m) => m.SolidWasteManagementModule
      ),
  },
  {
    path: "work-management",
    loadChildren: () =>
      import("./work-management/work-management.module").then(
        (m) => m.WorkManagementModule
      ),
  },
  {
    path: "grievance-management",
    loadChildren: () =>
      import("./grievance-management/grievance-management.module").then(
        (m) => m.GrievanceManagementModule
      ),
  },

  {
    path: "water-tax",
    loadChildren: () =>
      import("./WATER-TAX/water-tax.module").then((m) => m.WaterTaxModule),
  },
  {
    path: "online-rts",
    loadChildren: () =>
      import("./RTS/online-rts.module").then((m) => m.onlinertsModule),
  },
  {
    path: "msmam",
    loadChildren: () =>
      import("./MSMAM/msmam.module").then((m) => m.MsmamModule),
  },
  // {
  //   path:"application-service-form/:id",
  //   component:DynamicFormComponent,
  //   canActivate:[TokenAuthGuard]
  // },
  {
    path: "qr-code",
    component: QrCodeComponent,
    canActivate: [TokenAuthGuard],
  },
  {
    path: "user-module-mapping",
    component: UserModuleMappingComponent,
    canActivate: [TokenAuthGuard],
  },

  { path: "**", redirectTo: "/404" }, // Redirect to 404 page for unmatched routes
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
