import { CurrencyPipe } from "@angular/common";
import { Component } from "@angular/core";
import { PropertyTaxService } from "src/app/_services/property-tax/property-tax.service";

@Component({
  selector: "app-property-tax-dashboard",
  templateUrl: "./property-tax-dashboard.component.html",
  styleUrls: ["./property-tax-dashboard.component.scss"],
  providers: [CurrencyPipe],
})
export class PropertyTaxDashboardComponent {
  propertyNo: string = "";
  selectedCycle: string = "";
  selectedFinancialYear: string;
  financialYears: string[];
  billDetails: any;

  totalResidentialAuthorized: number = 0;
  totalResidentialUnauthorized: number = 0;
  totalCommercialAuthorized: number = 0;
  totalCommercialUnauthorized: number = 0;
  totalOfAllTaxes: number = 0;

  constructor(
    private propertyService: PropertyTaxService,
    private currencyPipe: CurrencyPipe
  ) {
    this.selectedFinancialYear = "";
    this.financialYears = [
      "2018-19",
      "2020-21",
      "2021-22",
      "2022-23",
      "2023-24",
      "2024-25",
    ];
  }

  onSearch() {
    if (this.propertyNo && this.selectedCycle && this.selectedFinancialYear) {
      this.propertyService
        .getBillDetailsByPropertyNoAndFinancialYear(
          this.propertyNo,
          this.selectedFinancialYear,
          this.selectedCycle
        )
        .subscribe(
          (result) => {
            this.billDetails = result;
            console.log(this.billDetails);
            this.calculateTotals();
          },
          (error) => {
            console.error(error);
          }
        );
    }
  }

  getTaxNames(): string[] {
    if (!this.billDetails) {
      return [];
    }

    const taxCategories = [
      this.billDetails.data.residentialAuthorized,
      this.billDetails.data.residentialUnauthorized,
      this.billDetails.data.commercialAuthorized,
      this.billDetails.data.commercialUnauthorized,
    ];

    const taxNamesSet = new Set<string>();
    taxCategories.forEach((category) => {
      Object.keys(category).forEach((taxName) => taxNamesSet.add(taxName));
    });

    return Array.from(taxNamesSet);
  }

  formatCurrency(value: number): string {
    if (value !== null && value !== undefined) {
      const roundedValue = Math.round(value);
      return (
        this.currencyPipe.transform(roundedValue, "INR", "symbol", "1.0-0") ||
        ""
      );
    }
    return "";
  }

  formatPercentage(value: number): string {
    if (value !== null && value !== undefined) {
      const roundedValue = Math.round(value * 100) / 100;
      return roundedValue.toFixed(2) + "%";
    }
    return "";
  }

  calculateTotals() {
    this.totalResidentialAuthorized = this.calculateTotal(
      this.billDetails.data.residentialAuthorized
    );
    this.totalResidentialUnauthorized = this.calculateTotal(
      this.billDetails.data.residentialUnauthorized
    );
    this.totalCommercialAuthorized = this.calculateTotal(
      this.billDetails.data.commercialAuthorized
    );
    this.totalCommercialUnauthorized = this.calculateTotal(
      this.billDetails.data.commercialUnauthorized
    );

    this.totalOfAllTaxes =
      this.totalResidentialAuthorized +
      this.totalResidentialUnauthorized +
      this.totalCommercialAuthorized +
      this.totalCommercialUnauthorized;
  }

  calculateTotal(category: any): number {
    let total = 0;
    for (let tax in category) {
      if (category[tax]?.taxRate) {
        total += Math.round(category[tax].taxRate);
      }
    }
    return total;
  }
}
