import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, Observer, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HrmsServiceService {


  constructor(private http : HttpClient) {
   }

  getBank(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstBank/getAll`)
  }
  getByActiveFlagBank(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstBank/getAllActiveList`)
  }
  activeInactiveBank(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstBank/activeInactive?id=${id}&activeFlag=${flag}`,null)
  }
  saveBank(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstBank/save`,data)
  }

 

  getBankBranch(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstBankBranch/getAll`)
  }
  getByActiveFlagBankBranch(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstBankBranch/getAllActiveList`)
  }
  activeInactiveBankBranch(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstBankBranch/activeInactive?id=${id}&activeFlag=${flag}`,null)
  }
  saveBankBranch(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstBankBranch/save`,data)
  }

  getConfidentialReviewRating(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstConfidentialReviewRating/getAll`)
  }
  getByActiveFlagConfidentialReviewRating(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstConfidentialReviewRating/getAllActiveList`)
  }
  activeInactiveConfidentialReviewRating(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstConfidentialReviewRating/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  saveConfidentialReviewRating(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstConfidentialReviewRating/save`,data)
  }
  getByIdConfidentialReviewRating(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstConfidentialReviewRating/getById?id=${id}`)
  }


  getDeptHead(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstDeptHead/getAll`)
  }

  getByIdDeptHead(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstDeptHead/getById?id=${id}`)
  } 

  getByActiveFlagDeptHead(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstDeptHead/getAllActiveList`)
  }
  activeInactiveDeptHead(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstDeptHead/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  saveDeptHead(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstDeptHead/save`,data)
  }


  getEmployee(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstEmployee/getAll`)
  }
  getByActiveFlagEmployee(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstEmployee/getAllActiveList`)
  }
  activeInactiveEmployee(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstEmployee/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }

  getByIdEmployee(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstEmployee/getById?id=${id}`)
  }

  saveEmployee(data:any,docs : any){
    const formData = new FormData
    formData.append('mstEmployeeString',JSON.stringify(data))
    // formData.append('previousEmploymentList',JSON.stringify(MstPreviousEmpolyeementDao))
    // formData.append('propertyDeclarationList',JSON.stringify(MstPropertyDeclarationDao))
    // formData.append('vehicleDetailsList',JSON.stringify(MstVehicleDetailsDao))
    // formData.append('familyInformationList',JSON.stringify(MstFamilyInformationDao))


    docs.forEach(file => {
      formData.append('documents', file);
    });


    return this.http.post(`${environment.hrmsUrl}/api/master/mstEmployee/save`,formData)
  }

  getDepartment(){
    var url = `${environment.commonUrl}` + '/api/master/department/getAllDashboard'
    return this.http.get(url)
  }

  getByActiveFlagDepartment(){
    var url = `${environment.commonUrl}` + '/api/master/department/getAll'
    return this.http.get(url)
  }

  //week off

  getWeekOff(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstWeekOff/getAll`)
  }

  getByIdWeekOff(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstWeekOff/getById?id=${id}`)
  } 

  getByActiveFlagWeekOff(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstWeekOff/getAllActiveList`)
  }
  activeInactiveWeekOff(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstWeekOff/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  saveWeekOff(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstWeekOff/save`,data)
  }

  //holiday

  getHoliday(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstHoliday/getAll`)
  }

  getByIdHoliday(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstHoliday/getById?id=${id}`)
  } 

  getByActiveFlagHoliday(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstHoliday/getAllActiveList`)
  }
  activeInactiveHoliday(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstHoliday/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  saveHoliday(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstHoliday/save`,data)
  }

  getByEmployeeIdCalendar(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/attendance/getByEmployeeId?employeeId=${id}`)
  }
  //shift 

  getShift(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstShift/getAll`)
  }

  getByIdShift(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstShift/getById?id=${id}`)
  } 

  getByActiveFlagShift(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstShift/getAllActiveList`)
  }
  activeInactiveShift(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstShift/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  saveShift(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstShift/save`,data)
  }

  //roster

  getRosterSetting(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstRosterSetting/getAll`)
  }

  getByIdRosterSetting(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstRosterSetting/getById?id=${id}`)
  } 


  getByActiveFlagRosterSetting(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstRosterSetting/getAllActiveList`)
  }
  activeInactiveRosterSetting(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstRosterSetting/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  saveRosterSetting(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstRosterSetting/save`,data)
  }

  //Leave Application
  getLeaveApplication(){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/leaveApplication/getAll`)
  }
  getLeaveApplicationForDashboard(){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/leaveApplication/getAllDashboard`)
  }

  getLeaveApplicationById(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/leaveApplication/getById?id=${id}`)
  }

  getByActiveFlagLeaveApplication(){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/leaveApplication/getAllActiveList`)
  }
  activeInactiveLeaveApplication(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/leaveApplication/activeInactive?id=${id}&activeFlag=${flag}`,null)
  }
  saveLeaveApplication(data:any,uploadedDocs : any,docList : any){
      // data.docList = "[" +  data.docLIst.toString() + "]"
      const formData = new FormData
      formData.append('trnLeaveApplicationDaoString',JSON.stringify(data))
      formData.append('documentsList',docList)
      // formData.append('documents',JSON.stringify(docs))
      uploadedDocs.forEach(file => {
        formData.append('documents', file);
      });
    return this.http.post(`${environment.hrmsUrl}/api/transaction/leaveApplication/save`,formData)
  }

 

  saveLeaveApplicationScrutiny(data:any){
    console.log(data)
    return this.http.post(`${environment.hrmsUrl}/api/transaction/leaveApplication/scrutiny`,data)
  }

  leaveApplictionPositiveScrutiny(data : any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/leaveApplication/positveScrutiny`,data)

  }

  leaveApplictionNegativeScrutiny(data : any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/leaveApplication/negativeScrutiny`,data)

  }

  


  getLeaveType(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstLeaveType/getAll`)
  }
  getByIdLeaveType(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstLeaveType/getById?id=${id}`)
  } 
  getByActiveFlagLeaveType(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstLeaveType/getAllActiveList`)
  }
  activeInactiveLeaveType(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstLeaveType/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  saveLeaveType(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstLeaveType/save`,data)
  }

  //DPC 
  getDPC(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstDpc/getAll`)
  }

  getByIdDPC(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstDpc/getById?id=${id}`)
  } 


  getAllDPC(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstDpc/getAllDashboard`)
  }

  getByActiveFlagDPC(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstDpc/getAllActiveList`)
  }
  activeInactiveDPC(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstDpc/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  saveDPC(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstDpc/save`,data)
  }


  getSalaryItem(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstSalaryItem/getAll`)
  }
  getByActiveFlagSalaryItem(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstSalaryItem/getAllActiveList`)
  }
  activeInactiveSalaryItem(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstSalaryItem/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  saveSalaryItem(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstSalaryItem/save`,data)
  }

  getDesignation(){
    return this.http.get(`${environment.commonUrl}/api/master/designation/getAll`)
  }
  getByActiveFlagDesignation() {
    return this.http.get(
      `${environment.commonUrl}/api/master/designation/getAll`
    );
  }
  getByIdCommonDepartmentDesignationMapping(id: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstDepartmentDesignationMapping/getByDepartmentId?departmentId=${id}`
    );
  }
  
  getLoan(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstLoan/getAll`)
  }
  getByIdLoan(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstLoan/getById?id=${id}`)
  }
  getByActiveFlaeLoan(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstLoan/getAllActiveList`)
  }
  activeInactiveLoan(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstLoan/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  saveLoan(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstLoan/save`,data)
  }

  getDetailsByEmployeeId(empId : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstEmployee/getDetailsByEmployeeId?employeeId=${empId}`)

  }

  //pay scale

  getPayScale(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstPayScale/getAll`)
  }
  getByActiveFlagPayScale(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstPayScale/getAllActiveList`)
  }
  activeInactivePayScale(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstPayScale/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  savePayScale(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstPayScale/save`,data)
  }
  getByIdPayScale(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstPayScale/getById?id=${id}`)
  }

  //pay band
  getPayBand(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstPayBand/getAll`)
  }
  getByIdPayBand(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstPayBand/getById?id=${id}`)
  }
  getByActiveFlagPayBand(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstPayBand/getAllActiveList`)
  }
  activeInactivePayBand(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstPayBand/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  savePayBand(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstPayBand/save`,data)
  }

  //pay scale to pay band

  getPayScaleToPayBand(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstPayScaleToPayBand/getAll`)
  }

  getByIdPayScaleToPayBand(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstPayScaleToPayBand/getById?id=${id}`)
  }


  getByActiveFlagPayScaleToPayBand(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstPayScaleToPayBand/getAllActiveList`)
  }
  activeInactivePayScaleToPayBand(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstPayScaleToPayBand/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  savePayScaleToPayBand(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstPayScaleToPayBand/save`,data)
  }

  // pay commission
  getPayCommission(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstPayCommission/getAll`)
  }
  getByActiveFlagPayCommission(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstPayCommission/getAllActiveList`)
  }
  activeInactivePayCommission(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstPayCommission/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  savePayCommission(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstPayCommission/save`,data)
  }

  getByIdPayCommission(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstPayCommission/getById?id=${id}`)
  }


  //increement

  getIncrement(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstIncrement/getAll`)
  }
  getByActiveFlagIncrement(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstIncrement/getAllActiveList`)
  }
  activeInactiveIncrement(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstIncrement/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  saveIncrement(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstIncrement/save`,data)
  }
  getByIdIncrement(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstIncrement/getById?id=${id}`)
  }

  //group
  getGroup(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstGroup/getAll`)
  }

  getByIdGroup(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstGroup/getById?id=${id}`)
  } 
  getByActiveFlagGroup(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstGroup/getAllActiveList`)
  }
  activeInactiveGroup(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstGroup/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  saveGroup(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstGroup/save`,data)
  }

  //exam details 
  getExaminationDetails(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstExaminationDetails/getAll`)
  }
  getByIdExaminationDetails(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstExaminationDetails/getById?id=${id}`)
  } 
  getByActiveFlagExaminationDetails(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstExaminationDetails/getAllActiveList`)
  }
  activeInactiveExaminationDetails(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstExaminationDetails/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  saveExaminationDetails(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstExaminationDetails/save`,data)
  }

  //handicap type

  getHandicapType(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstHandicapType/getAll`)
  }
  getByActiveFlagHandicapType(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstHandicapType/getAllActiveList`)
  }
  activeInactiveHandicapType(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstHandicapType/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  saveHandicapType(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstHandicapType/save`,data)
  }
  getByIdHandicapType(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstHandicapType/getById?id=${id}`)
  } 


  // getlifeCertificate(status : String[]){
  //   return this.http.get(`${environment.hrmsUrl}/api/transaction/lifeCertificate/getAllByStatus?status=${status}`)
  // }

  getlifeCertificate(){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/lifeCertificate/getAll?`)
  }

  getByIdlifeCertificate(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/lifeCertificate/getById?id=${id}`)
  }
  getByActiveFlaglifeCertificate(){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/lifeCertificate/getAllActiveList`)
  }
  activeInactivelifeCertificate(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/lifeCertificate/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  // savelifeCertificate(data:any,docLIst : any,docs : any){

  //   // data.docList = "[" +  data.docLIst.toString() + "]"
  //   const formData = new FormData
  //   formData.append('trnLifeCertificateOfPensionerDaoString',JSON.stringify(data))
  //   formData.append('documentsList',docLIst)
  //   // formData.append('documents',JSON.stringify(docs))
  //   docs.forEach(file => {
  //     formData.append('documents', file);
  //   });


  //   return this.http.post(`${environment.hrmsUrl}/api/transaction/lifeCertificate/save`,formData)
  // }

  savelifeCertificate(mergedData : any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/lifeCertificate/saveV1`,mergedData)
  }

  lifeCertificateScrutinyApproval(data:any){
    const formData = new FormData
    formData.append('trnLifeCertificateOfPensionerDaoString',JSON.stringify(data))
    return this.http.post(`${environment.hrmsUrl}/api/transaction/lifeCertificate/scrutinyForApprove`,formData)
  }

  lifeCertificateScrutinyReject(data:any){
    const formData = new FormData
    formData.append('trnLifeCertificateOfPensionerDaoString',JSON.stringify(data))
    return this.http.post(`${environment.hrmsUrl}/api/transaction/lifeCertificate/scrutinyForReject`,formData)
  }

  generateLifeCertificatePdf(lifeCrerId : any){
    return this.http.get(`${environment.hrmsUrl}/api/reports/generateLifeCertificate?lifeCrerId=${lifeCrerId}`)

  }

  //Loan Application

  getHomeloanApplication(){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/loanApplication/getAll`)
  }
  getByIdHomeloanApplication(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/loanApplication/getById?id=${id}`)
  }

  saveHomeloanApplication(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/loanApplication/save`,data)
  }

  saveHomeloanApplicationProcess(data:any,document : any){
    let body = {}
    console.log(JSON.stringify(data))
    const formData = new FormData();
    formData.append("trnLoanApplicationDaoString",data);
    document.forEach(file => {
      formData.append('documents', file);
    });
   
   
    return this.http.post(`${environment.hrmsUrl}/api/transaction/loanApplication/save`,formData)
  }

  getHomeloanApplicationCalculations(data:any){
    const formData = new FormData();
    formData.append("trnLoanApplicationDaoString",data);
  
   
    return this.http.post(`${environment.hrmsUrl}/api/transaction/loanApplication/getHomeLoanCalculation`,formData)
  }

  homeLoanApplicationScrutiny(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/loanApplication/scrutiny`,data)
  }

  homeLoanApplicationPositiveScrutiny(data:any,docs : any){
    const formData = new FormData
    formData.append('trnLoanApplicationDaoString',JSON.stringify(data))
    console.log(docs)
    if(docs != undefined){
        formData.append('documents', docs);
    }else {
      formData.append('documents', null);

    }
    

    return this.http.post(`${environment.hrmsUrl}/api/transaction/loanApplication/positiveScrutiny`,formData)
  }

  homeLoanApplicationNegativeScrutiny(data:any,docs : any){
    const formData = new FormData
    formData.append('trnLoanApplicationDaoString',JSON.stringify(data))
 
    docs.forEach(file => {
      formData.append('documents', file);
    });

    return this.http.post(`${environment.hrmsUrl}/api/transaction/loanApplication/negativeScrutiny`,data)
  }

   //confidential report Application

   getconfidentialReport(){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/confidentialReport/getAll`)
  }
  
  getByIdconfidentialReport(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/confidentialReport/getById?id=${id}`)
  }

  getByClassAndSectionConfidentialReportQuestions(classes : any,section : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/questionMaster/getByClassAndSection?classes=${classes}&section=${section}`)
  }

  saveconfidentialReport(data:any){

    const formData = new FormData
    formData.append('trnConfidentialReportDaoString',JSON.stringify(data))
    return this.http.post(`${environment.hrmsUrl}/api/transaction/confidentialReport/save`,formData)
  }

  saveCrAllocationForm(data : any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/confidentialReport/crFormAllocation`,data)
  }

  saveconfidentialReportSections(data : any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/confidentialReport/saveQuestionSections`,data)

  }
  confidentialReportScrutiny(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/confidentialReport/positiveScrutinyV1`,data)
  }

  //leave Allocation

  getleaveAllocation(){
    return this.http.get(`${environment.hrmsUrl}/api/master/leaveAllocation/getAllDasboard`)
  }

  getAllleaveAllocation(){
    return this.http.get(`${environment.hrmsUrl}/api/master/leaveAllocation/getAll`)
  }

  getByIdleaveAllocation(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/leaveAllocation/getById?id=${id}`)
  }
  
  getleaveAllocationEmployee(){
    return this.http.get(`${environment.hrmsUrl}/api/master/leaveAllocation/getAllEmployee`)
  }

  saveleaveAllocation(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/leaveAllocation/save`,data)
  }

  activeInactiveleaveAllocation(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/leaveAllocation/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }

  //departmental posts

  getdepartmentalPosts(){
    return this.http.get(`${environment.hrmsUrl}/api/master/departmentalPosts/getAll`)
  }
  
  getByIddepartmentalPosts(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/departmentalPosts/getById?id=${id}`)
  }

  savedepartmentalPosts(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/departmentalPosts/save`,data)
  }

  activeInactivedepartmentalPosts(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/departmentalPosts/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }


    //pensioner flow

    getpensionerFlow(){
      return this.http.get(`${environment.hrmsUrl}/api/transaction/pensionerFlow/getAll`)
    }
    
    getByIdpensionerFlow(id : any){
      return this.http.get(`${environment.hrmsUrl}/api/transaction/pensionerFlow/getById?id=${id}`)
    }
  
    savepensionerFlow(data:any){
      return this.http.post(`${environment.hrmsUrl}/api/transaction/pensionerFlow/save`,data)
    }
  
    pensionerFlowScrutiny(data:any){
      return this.http.post(`${environment.hrmsUrl}/api/transaction/pensionerFlow/scrutiny`,data)
    }
  
    //pensioner withdrawal flow

    getpensionWithdrawalFlowService(){
      return this.http.get(`${environment.hrmsUrl}/api/transaction/withDrawal/getAllForService`)
    }
    getpensionWithdrawalFlowFamily(){
      return this.http.get(`${environment.hrmsUrl}/api/transaction/withDrawal/getAllForFamily`)
    }
    getpensionWithdrawalFlowDoctor(){
      return this.http.get(`${environment.hrmsUrl}/api/transaction/withDrawal/getAllForDoctor`)
    }
    
    getByIdpensionWithdrawalFlow(id : any){
      return this.http.get(`${environment.hrmsUrl}/api/transaction/withDrawal/getById?id=${id}`)
    }
    
  
    savepensionWithdrawalFlow(data:any){
      return this.http.post(`${environment.hrmsUrl}/api/transaction/withDrawal/save`,data)
    }
  
    pensionWithdrawalFlowScrutiny(data:any){
      return this.http.post(`${environment.hrmsUrl}/api/transaction/withDrawal/scrutiny`,data)
    }

    withdrawalProcessScrutinyPositive(data:any){
      return this.http.post(`${environment.hrmsUrl}/api/transaction/withDrawal/scrutinyPositiveV1`,data)
    }

    withdrawalProcessScrutinyNegative(data:any){
      return this.http.post(`${environment.hrmsUrl}/api/transaction/withDrawal/scrutinyNegativeV1`,data)
    }
  
     //recruitment process

     getrecruitmentProcess(){
      return this.http.get(`${environment.hrmsUrl}/api/transaction/recruitmentProcess/getAll`)
    }
    
    getByIdrecruitmentProcess(id : any){
      return this.http.get(`${environment.hrmsUrl}/api/transaction/recruitmentProcess/getById?id=${id}`)
    }
  
    saverecruitmentProcess(data:any){
      return this.http.post(`${environment.hrmsUrl}/api/transaction/recruitmentProcess/save`,data)
    }

    recruitmentProcessScrutiny(data:any){
      return this.http.post(`${environment.hrmsUrl}/api/transaction/recruitmentProcess/scrutiny`,data)
    }

    // ref book 

    saverefBook(data:any){
      return this.http.post(`${environment.hrmsUrl}/api/master/mstRefBook/save`,data)
    }

    getRefBook(){
      return this.http.get(`${environment.hrmsUrl}/api/master/mstRefBook/getAll`)
    }
   
    activeInactiveRefBook(id,flag){
      return this.http.post(`${environment.hrmsUrl}/api/master/mstRefBook/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
    }
   
  
    getByIdRefBook(id : any){
      return this.http.get(`${environment.hrmsUrl}/api/master/mstRefBook/getById?id=${id}`)
    }
  
      // application form

      saveApplicationForm(data:any,uploadedDocs : any,docList : any){
      

        const formData = new FormData();
        formData.append("trnApplicationFormDaoString",JSON.stringify(data));
        formData.append("documents", uploadedDocs);
       
        formData.append("documentsList", docList);

    
       
       
        return this.http.post(`${environment.hrmsUrl}/api/transaction/applicationform/save`,formData)
       
      
      }
  
      getApplicationForm(){
        return this.http.get(`${environment.hrmsUrl}/apii/transaction/applicationform/getAll`)
      }
     
      activeInactiveApplicationForm(id,flag){
        return this.http.post(`${environment.hrmsUrl}/api/transaction/applicationform/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
      }
     
    
      getByIdApplicationForm(id : any){
        return this.http.get(`${environment.hrmsUrl}/api/transaction/applicationform/getById?id=${id}`)
      }
    
  

      //transfer process

      gettransferProcess(){
        return this.http.get(`${environment.hrmsUrl}/api/transaction/transferProcess/getAll`)
      }
      
      getByIdtransferProcess(id : any){
        return this.http.get(`${environment.hrmsUrl}/api/transaction/transferProcess/getById?id=${id}`)
      }
    
      savetransferProcess(data:any){
        return this.http.post(`${environment.hrmsUrl}/api/transaction/transferProcess/save`,data)
      }
  
     
    
      transferProcessScrutiny(data:any){
        return this.http.post(`${environment.hrmsUrl}/api/transaction/transferProcess/scrutiny`,data)
      }

      //Pay Hike 6TH

      getpayHikeProcess(){
        return this.http.get(`${environment.hrmsUrl}/api/transaction/payHikeProcess/getAll`)
      }
      
      getByIdpayHikeProcess(id : any){
        return this.http.get(`${environment.hrmsUrl}/api/transaction/payHikeProcess/getById?id=${id}`)
      }
    
      savepayHikeProcess(data:any){
        return this.http.post(`${environment.hrmsUrl}/api/transaction/payHikeProcess/save`,data)
      }
  
     
    
      payHikeProcessScrutiny(data:any){
        return this.http.post(`${environment.hrmsUrl}/api/transaction/payHikeProcess/scrutiny`,data)
      }

       //Pay Hike 7TH

       getpayHikeProcessSevenCommision(){
        return this.http.get(`${environment.hrmsUrl}/api/transaction/payHikeProcessSevenCommision/getAll`)
      }
      
      getByIdpayHikeProcessSevenCommision(id : any){
        return this.http.get(`${environment.hrmsUrl}/api/transaction/payHikeProcessSevenCommision/getById?id=${id}`)
      }
    
      savepayHikeProcessSevenCommision(data:any){
        return this.http.post(`${environment.hrmsUrl}/api/transaction/payHikeProcessSevenCommision/save`,data)
      }
  
     
    
      payHikeProcessSevenCommisionScrutiny(data:any){
        return this.http.post(`${environment.hrmsUrl}/api/transaction/payHikeProcessSevenCommision/scrutiny`,data)
      }
    
   //shift process

   getshiftProcess(){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/shiftProcess/getAll`)
  }
  
  getByIdshiftProcess(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/shiftProcess/getById?id=${id}`)
  }

  saveshiftProcess(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/shiftProcess/save`,data)
  }

 
  shiftProcessScrutiny(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/shiftProcess/scrutiny`,data)
  }

  //New Permission
  getNewPermission(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstNewPermission/getAll`)
  }
  getByActiveFlagNewPermission(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstNewPermission/getAllActiveList`)
  }
  activeInactiveNewPermission(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstNewPermission/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  saveNewPermission(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstNewPermission/save`,data)
  }

  getByIdNewPermission(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstNewPermission/getById?id=${id}`)
  }

  //New Role
  getNewRole(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstNewRole/getAll`)
  }
  getByActiveFlagNewRole(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstNewRole/getAllActiveList`)
  }
  activeInactiveNewRole(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstNewRole/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  saveNewRole(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstNewRole/save`,data)
  }
  getByIdNewRole(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstNewRole/getById?id=${id}`)
  }

     //vehicle loan

     getVehicleLoan(){
      return this.http.get(`${environment.hrmsUrl}/api/transaction/vehicleLoan/getAll`)
    }
    
    getByIdVehicleLoan(id : any){
      return this.http.get(`${environment.hrmsUrl}/api/transaction/vehicleLoan/getById?id=${id}`)
    }
  
    saveVehicleLoan(data:any,docList : any,document : any){
      let body = {}
      console.log(JSON.stringify(data))
      const formData = new FormData();
      formData.append("trnLoanApplicationDaoString",data);
      document.forEach(file => {
        formData.append('documents', file);
      });

      return this.http.post(`${environment.hrmsUrl}/api/transaction/vehicleLoan/save`,formData)
    }

    getVehicleloanApplicationCalculations(data:any){
      const formData = new FormData();
      formData.append("trnLoanApplicationDaoString",data);
    
     
      return this.http.post(`${environment.hrmsUrl}/api/transaction/vehicleLoan/getVehicleLoanCalculation`,formData)
    }
  
  
   
    vehicleLoanScrutiny(data:any){
      return this.http.post(`${environment.hrmsUrl}/api/transaction/vehicleLoan/scrutiny`,data)
    }

    vehicleLoanApplicationPositiveScrutiny(data:any){
      return this.http.post(`${environment.hrmsUrl}/api/transaction/vehicleLoan/positveScrutiny`,data)
    }
  
    vehicleLoanApplicationNegativeScrutiny(data:any){
      return this.http.post(`${environment.hrmsUrl}/api/transaction/vehicleLoan/negativeScrutiny`,data)
    }

     //computer loan

     getcomputerLoan(){
      return this.http.get(`${environment.hrmsUrl}/api/transaction/computerLoan/getAll`)
    }
    
    getByIdcomputerLoan(id : any){
      return this.http.get(`${environment.hrmsUrl}/api/transaction/computerLoan/getById?id=${id}`)
    }
  
    savecomputerLoan(data:any){
      return this.http.post(`${environment.hrmsUrl}/api/transaction/computerLoan/save`,data)
    }

    saveComputerLoan(data:any,document : any,doc2 : any){
      let body = {}
      console.log(JSON.stringify(data))
      const formData = new FormData();
      formData.append("trnLoanApplicationDaoString",data);
      document.forEach(file => {
        formData.append('documents', file);
      });


      return this.http.post(`${environment.hrmsUrl}/api/transaction/computerLoan/save`,formData)
    }
  
   
    computerLoanScrutiny(data:any){
      return this.http.post(`${environment.hrmsUrl}/api/transaction/computerLoan/scrutiny`,data)
    }

    computerLoanApplicationPositiveScrutiny(data:any){
      return this.http.post(`${environment.hrmsUrl}/api/transaction/computerLoan/positveScrutiny`,data)
    }
  
    computerLoanApplicationNegativeScrutiny(data:any){
      return this.http.post(`${environment.hrmsUrl}/api/transaction/computerLoan/negativeScrutiny`,data)
    }

    //Questions
  getQuestions(){
    return this.http.get(`${environment.hrmsUrl}/api/master/questionMaster/getAll`)
  }
  getByActiveFlagQuestions(){
    return this.http.get(`${environment.hrmsUrl}/api/master/questions/getAllActiveList`)
  }
  activeInactiveNewQuestions(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/questionMaster/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  

  getByIdQuestionMaster(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/questionMaster/getById?id=${id}`)
  }

  saveQuestions(data:any){
    const formData = new FormData
    formData.append('mstConfidentialReportQuestionString',JSON.stringify(data))
    // return this.http.post(`${environment.hrmsUrl}/api/master/questions/save`,data)
    return this.http.post(`${environment.hrmsUrl}/api/master/questionMaster/save`,formData)

    
  }
  getByIdQuestions(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/questions/getById?id=${id}`)
  }
  getByGradeQuestions(grade : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/questions/getQustionsByGrade?grade=${grade}`)
  }

  getQuestionsByGroupSection(groupId : any,section : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/questions/getAllBySectionAndGroupId?section=${section}&groupId=${groupId}`)
  }

   //service pension proposal
   getServiePensionPropsal(){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/servicepension/getAll`)
  }
  
  getByIdServiePensionPropsal(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/servicepension/getById?id=${id}`)
  }

  saveServiePensionPropsal(data:any,docs : any,docList : any){
    const formData = new FormData
    formData.append('trnServicePensionProposalandCalculationDaoString',JSON.stringify(data))
    formData.append('documentsList',docList)
   
    docs.forEach(file => {
      formData.append('documents', file);
    });

    return this.http.post(`${environment.hrmsUrl}/api/transaction/servicepension/save`,formData)
  }

  serviePensionPropsalScrutinyPositive(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/servicepension/scrutinyPositiveV1`,data)
  }

  serviePensionPropsalScrutinyNegtaive(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/servicepension/scrutinyNegativeV1`,data)
  }

  getSalaryDetails(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/mstPayRoll/getLastTenPayroll?employeeId=${id}`)

  }

  // Appendix A
  getmstAppendixA(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixA/getAllDashboard`)
  }
  getByActiveFlagmstAppendixA(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixA/getAllActive`)
  }
  activeInactivemstAppendixA(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstAppendixA/changeFlag?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }

  getByIdmstAppendixA(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixA/getById?id=${id}`)
  }

  savemstAppendixA(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstAppendixA/save`,data)
  }

  getBiduNoByCategoryId(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixAForCaste/getBiduNoByCategoryId?categoryId=${id}`)

  }

  // Appendix B
  getmstAppendixB(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixB/getAllDashboard`)
  }
  getByActiveFlagmstAppendixB(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixB/getAllActive`)
  }
  activeInactivemstAppendixB(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstAppendixB/changeFlag?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }

  getByIdmstAppendixB(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixB/getById?id=${id}`)
  }

  savemstAppendixB(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstAppendixB/save`,data)
  }

 


  // Appendix A for caste
  getmstAppendixAForCaste(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixAForCaste/getAllDashboard`)
  }
  getByActiveFlagmstAppendixAForCaste(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixAForCaste/getAllActive`)
  }
  activeInactivemstAppendixAForCaste(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstAppendixAForCaste/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }

  getByIdmstAppendixAForCaste(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixAForCaste/getById?id=${id}`)
  }

  savemstAppendixAForCaste(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstAppendixAForCaste/save`,data)
  }

  // Appendix B for caste
  getmstAppendixBForCaste(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixBForCaste/getAllDashboard`)
  }
  getByActiveFlagmstAppendixBForCaste(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixBForCaste/getAllActive`)
  }
  activeInactivemstAppendixBForCaste(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstAppendixBForCaste/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }

  getByIdmstAppendixBForCaste(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixBForCaste/getById?id=${id}`)
  }

  savemstAppendixBForCaste(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstAppendixBForCaste/save`,data)
  }



  // Appendix C for caste
  getmstAppendixCForCaste(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixCForCaste/getAllDashboard`)
  }
  getByActiveFlagmstAppendixCForCaste(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixCForCaste/getAllActive`)
  }
  activeInactivemstAppendixCForCaste(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstAppendixCForCaste/changeFlag?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }

  getByIdmstAppendixCForCaste(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixCForCaste/getById?id=${id}`)
  }

  savemstAppendixCForCaste(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstAppendixCForCaste/save`,data)
  }

    // Appendix C
    getmstAppendixC(){
      return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixC/getAllDashboard`)
    }
    getByActiveFlagmstAppendixC(){
      return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixC/getAllActive`)
    }
    activeInactivemstAppendixC(id,flag){
      return this.http.post(`${environment.hrmsUrl}/api/master/mstAppendixC/changeFlag?id=${id}&activeFlag=${flag.activeFlag}`,null)
    }
  
    getByIdmstAppendixC(id : any){
      return this.http.get(`${environment.hrmsUrl}/api/master/mstAppendixC/getById?id=${id}`)
    }

    savemstAppendixC(data:any){
      return this.http.post(`${environment.hrmsUrl}/api/master/mstAppendixC/save`,data)
    }

    //Payroll process

       getPayrollProcess(){
        return this.http.get(`${environment.hrmsUrl}/api/transaction/mstPayRoll/getAll`)
      }
      
      getByIdPayrollProcess(id : any){
        return this.http.get(`${environment.hrmsUrl}/api/transaction/mstPayRoll/getById?id=${id}`)
      }
    
      savePayrollProcess(data:any){
        return this.http.post(`${environment.hrmsUrl}/api/transaction/mstPayRoll/save`,data)
      }
  
      payrollProcessApplicationScrutiny(data:any){
        return this.http.post(`${environment.hrmsUrl}/api/transaction/mstPayRoll/scrutiny`,data)
      }
    
      payrollProcessScrutiny(data:any){
        return this.http.post(`${environment.hrmsUrl}/api/transaction/payrollProcess/scrutiny`,data)
      }

        // Fractionation Tbale
      getFractionationTable(){
        return this.http.get(`${environment.hrmsUrl}/api/master/mstFractionationTable/getAllDashboard`)
      }
      getByActiveFractionationTabl(){
        return this.http.get(`${environment.hrmsUrl}/api/master/mstFractionationTable/getAllActive`)
      }
      activeInactiveFractionationTabl(id,flag){
        return this.http.post(`${environment.hrmsUrl}/api/master/mstFractionationTable/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
      }

      getByIdFractionationTabl(id : any){
        return this.http.get(`${environment.hrmsUrl}/api/master/mstFractionationTable/getById?id=${id}`)
      }

      saveFractionationTabl(data:any){
        return this.http.post(`${environment.hrmsUrl}/api/master/mstFractionationTable/save`,data)
      }
        
       //Family Pension
       getfamilypension(){
        return this.http.get(`${environment.hrmsUrl}/api/transaction/familypension/getAll`)
      }
      
      getByIdfamilypension(id : any){
        return this.http.get(`${environment.hrmsUrl}/api/transaction/familypension/getById?id=${id}`)
      }
    
      savefamilypension(data:any,docs : any,docList : any){
        const formData = new FormData
        formData.append('trnFamilyPensionProposalandCalculationDaoString',JSON.stringify(data))
        formData.append('documentsList',docList)
        docs.forEach(file => {
          formData.append('documents', file);
        });
        return this.http.post(`${environment.hrmsUrl}/api/transaction/familypension/save`,formData)
      }
  
  
      familypensionScrutiny(data:any){
        return this.http.post(`${environment.hrmsUrl}/api/transaction/familypension/scrutiny`,data)
      }

      familypensionPropsalScrutinyPositive(data:any){
        return this.http.post(`${environment.hrmsUrl}/api/transaction/familypension/scrutinyPositiveV1`,data)
      }
    
      familypensionPropsalScrutinyNegtaive(data:any){
        return this.http.post(`${environment.hrmsUrl}/api/transaction/familypension/scrutinyNegativeV1`,data)
      }

        //Doctor Pension
        getdoctorpension(){
          return this.http.get(`${environment.hrmsUrl}/api/transaction/doctorpension/getAll`)
        }
        
        getByIddoctorpension(id : any){
          return this.http.get(`${environment.hrmsUrl}/api/transaction/doctorpension/getById?id=${id}`)
        }
      
        savedoctorpension(data:any,docs:any,docList : any){
          const formData = new FormData
          formData.append('trnDoctorPensionProposalandCalculationDaoString',JSON.stringify(data))
          formData.append('documentsList',docList)

          docs.forEach(file => {
            formData.append('documents', file);
          });
          return this.http.post(`${environment.hrmsUrl}/api/transaction/doctorpension/save`,formData)
        }
    
    
        doctorpensionScrutiny(data:any){
          return this.http.post(`${environment.hrmsUrl}/api/transaction/doctorpension/scrutiny`,data)
        }

        doctorpensionPropsalScrutinyPositive(data:any){
          return this.http.post(`${environment.hrmsUrl}/api/transaction/doctorpension/scrutinyPositiveV1`,data)
        }
      
        doctorpensionPropsalScrutinyNegtaive(data:any){
          return this.http.post(`${environment.hrmsUrl}/api/transaction/doctorpension/scrutinyNegativeV1`,data)
        }
  
          //Leaving Process
          getLeavingProcess(){
            return this.http.get(`${environment.hrmsUrl}/api/transaction/leavingprocess/getAll`)
          }
          
          getByIdLeavingProcess(id : any){
            return this.http.get(`${environment.hrmsUrl}/api/transaction/leavingprocess/getById?id=${id}`)
          }
        
          saveLeavingProcess(data:any,document : any){
            let body = {}
  
            const formData = new FormData();
            formData.append("trnLeavingProcessDaoString",data);
            formData.append("documents", document);
        
           
           
            return this.http.post(`${environment.hrmsUrl}/api/transaction/leavingprocess/save`,formData)
          }
      
      
          leavingProcessScrutinyPositive(data:any){
            return this.http.post(`${environment.hrmsUrl}/api/transaction/leavingprocess/scrutinyPositive`,data)
          }

          leavingProcessScrutinyNegative(data:any){
            return this.http.post(`${environment.hrmsUrl}/api/transaction/leavingprocess/scrutinyNegative`,data)
          }


          // Reports 
 // Government Recognized Post

 getGovtRecPosts(){
  return this.http.get(`${environment.hrmsUrl}/api/report/getAppendixCDesignationWiseCount`)
}

 // Vacant Post

 getYearlyEmployeeClassWiseCount(){
  return this.http.get(`${environment.hrmsUrl}/api/report/getYearlyEmployeeClassWiseCount`)
}

// get employee by dept

getEmpByDeptId(id : any){
  return this.http.get(`${environment.hrmsUrl}/api/master/mstEmployee/getEmpDetailsByDptId?dptId=${id}`)
}

getEmpByDesId(id : any){
  return this.http.get(`${environment.hrmsUrl}/api/master/mstEmployee/getEmpDetailsByDesignationId?designationId=${id}`)
}

getEmpByDesIdAndDptId(deptId:any,desId:any){
  return this.http.get(
    `${environment.hrmsUrl}/api/master/mstEmployee/getEmpDetailsByDesIdandDptId?desId=${desId}&departmentId=${deptId}`
  );
}

 // employee class
 getmstClass(){
  return this.http.get(`${environment.hrmsUrl}/api/master/mstClass/getAllDashboard`)
}
getByActiveFlagmstClass(){
  return this.http.get(`${environment.hrmsUrl}/api/master/mstClass/getAllActive`)
}
activeInactivemstClass(id,flag){
  return this.http.post(`${environment.hrmsUrl}/api/master/mstClass/changeFlag?id=${id}&activeFlag=${flag.activeFlag}`,null)
}

getByIdmstClass(id : any){
  return this.http.get(`${environment.hrmsUrl}/api/master/mstClass/id?id=${id}`)
}

savemstClass(data:any){
  return this.http.post(`${environment.hrmsUrl}/api/master/mstClass/save`,data)
}

getUsersByDeptDesg(dptId: any, designationId: any) {
  var url =
    `${environment.hrmsUrl}` +
    "/api/master/user/getEmpDetailsByDesIdandDptId?departmentId=" +
    dptId +
    "&desId=" +
    designationId;

  return new Promise((resolve) => {
    this.http
      .get(url)
      .toPromise()
      .then((res) => {
        resolve(res);
      })
      .catch((err: any) => {
        resolve(err);
      });
  });
}
  
getCurrentPosition(): Observable<GeolocationPosition> {
  return new Observable((observer: Observer<GeolocationPosition>) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          // You can log the accuracy here
          console.log('Accuracy:', position.coords.accuracy);
          observer.next(position);   // Emit the position
          observer.complete();       // Complete the observable
        },
        (error: GeolocationPositionError) => {
          observer.error(error);     // Emit an error if geolocation fails
        },
        { enableHighAccuracy: true }  // Request high accuracy if possible
      );
    } else {
      observer.error('Geolocation is not supported by this browser.');
    }
  });
}

getAddress(latitude:any,longitude : any){
  return this.http.get(`${environment.hrmsUrl}/api/address/get-address?latitude=${latitude}&longitude=${longitude}`)
}

getAddressFromCoordinates(latitude: number, longitude: number): Observable<any> {
  const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`;
  return this.http.get<any>(url);
}
//inbuilt navigator -geolocation

 //task management

 getTasksList(){
  return this.http.get(`${environment.hrmsUrl}/api/transaction/taskmanagement/getAll`)
}

getAllActiveTasksList(){
  return this.http.get(`${environment.hrmsUrl}/api/transaction/taskmanagement/findAllActiveTasks`)
}

getByIdtaskDetails(taskId : any){
  return this.http.get(`${environment.hrmsUrl}/api/transaction/taskmanagement/getById?id=${taskId}`)
}

getTaskByEmpId(empId : any){
  return this.http.get(`${environment.hrmsUrl}/api/transaction/taskmanagement/findAllByEmployeeId?id=${empId}`)
}

saveNewTask(data:any){
  return this.http.post(`${environment.hrmsUrl}/api/transaction/taskmanagement/save`,data)
}

saveUrgentTask(data:any){
  return this.http.post(`${environment.hrmsUrl}/api/transaction/taskmanagement/urgentTaskSave`,data)
}

updateTaskByEmployee(data:any){
  return this.http.put(`${environment.hrmsUrl}/api/transaction/taskmanagement/update`,data)
}


taskScrutinyPositive(data:any){
  return this.http.post(`${environment.hrmsUrl}/api/transaction/taskmanagement/positveScrutiny`,data)
}

taskScrutinyNegative(data:any){
  return this.http.post(`${environment.hrmsUrl}/api/transaction/taskManagement/negativeScrutiny`,data)
}

  // Medical Reimbursement
  getMedicalReimbursement(){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/getAllv1`)
  }
  
  getByIdMedicalReimbursement(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/getByIdv1?id=${id}`)
  }

  getByIdMedicalReimbursementV1(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/getByIdv1?id=${id}`)
  }

  saveMedicalReimbursement(data:any,docs){
    // const formData = new FormData
    // formData.append('trnMedicalReimbursmentDaoString',JSON.stringify(data))
    // docs.forEach(file => {
    //   formData.append('documents', file);
    // });
    return this.http.post(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/save`,data)
  }


  medicalReimbursementScrutinyPositive(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/scrutinyPositive`,data)
  }

  // medicalReimbursementScrutinyPositive(data:any){
  //   return this.http.post(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/dependencyCertificateGetPosScrutiny`,data)
  // }

  medicalReimbursementScrutinyNegtaive(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/scrutinyNegative`,data)
  }

  // DependencyCertificate
  saveDependencyCertificate(data){
    const formData = new FormData
    formData.append('tnMedicalReimbursmentDependencyCertificateDaoToString',JSON.stringify(data))
    return this.http.post(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/saveDependencyCertificate`,formData)

  }

  getDependencyCertificate(){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/dependencyCertificateGetAll`)
  }
  
  getByIdDependencyCertificate(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/dependencyCertificateGetById?id=${id}`)
  }

   // Essential nursing service
   saveReqNursingServiceCertificate(data){
    const formData = new FormData
    formData.append('tnMedicalReimbursmentReqNursingServiceCertificateDaoToString',JSON.stringify(data))
    return this.http.post(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/saveReqNursingServiceCertificate`,formData)

  }

  getReqNursingServiceCertificate(){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/reqNursingCertificateGetAll`)
  }
  
  getByIdReqNursingServiceCertificate(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/reqNursingCertificateGetById?id=${id}`)
  }

  // LabCertificate
  saveLabCertificate(data){
    const formData = new FormData
    formData.append('tnMedicalReimbursmentLaboratoryCertificateDaoToString',JSON.stringify(data))
    return this.http.post(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/saveLaboratoryServiceCertificate`,formData)

  }

  getLabCertificate(){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/laboratoryCertificateGetAll`)
  }
  
  getByIdLabCertificate(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/laboratoryCertificateGetById?id=${id}`)
  }

  // hospital stay certificate
  saveHospitalResidenceCertificate(data){
    const formData = new FormData
    formData.append('tnMedicalReimbursmentSpeechCertificateDaoToString',JSON.stringify(data))
    return this.http.post(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/saveSpeechCertificate`,formData)

  }

  getHospitalResidenceCertificate(){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/speechCertificateGetAll`)
  }
  
  getByIdHospitalResidenceCertificate(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/speechCertificateGetById?id=${id}`)
  }

    // limited family
    saveLimitedFamilyCertificate(data){
      const formData = new FormData
      formData.append('tnMedicalReimbursmentLimitedFamilyCertificateDaoToString',JSON.stringify(data))
      return this.http.post(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/saveLimitedFamilyCertificate`,formData)
  
    }
  
    getLimitedFamilyCertificate(){
      return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/limitedFamilyCertificateGetAll`)
    }
    
    getByIdLimitedFamilyCertificate(id : any){
      return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/limitedFamilyCertificateGetById?id=${id}`)
    }

     // private hospital
     saveTreatmentAtPrivateHospitalCertificate(data){
      const formData = new FormData
      formData.append('tnMedicalReimbursmentDiscloserCertificateDaoToString',JSON.stringify(data))
      return this.http.post(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/saveDiscloserCertificate`,formData)
  
    }
  
    getTreatmentAtPrivateHospitalCertificate(){
      return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/discloserCertificateGetAll`)
    }
    
    getByIdTreatmentAtPrivateHospitalCertificate(id : any){
      return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/discloserCertificateGetById?id=${id}`)
    }

    // certificate of urgency treatment
    saveUrgentTreatmentCertificate(data){
      const formData = new FormData
      formData.append('tnMedicalReimbursmentUrgencyTreatCertificateDaoToString',JSON.stringify(data))
      return this.http.post(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/saveUrgencyTreatCertificate`,formData)
  
    }
  
    getUrgentTreatmentCertificate(){
      return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/urgencyTreatCertificateGetAll`)
    }
    
    getByIdUrgentTreatmentCertificate(id : any){
      return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/urgencyTreatCertificateGetById?id=${id}`)
    }
  
 
  //suspended employee
  getSuspendedEmployee(){
    return this.http.get(`${environment.hrmsUrl}/api/master/suspendedEmployee/getAll`)
  }
  
  getByIdSuspendedEmployee(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/suspendedEmployee/getById?id=${id}`)
  }

  saveSuspendedEmployee(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/suspendedEmployee/save`,data)
  }

  activeInactiveSuspendedEmployee(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/suspendedEmployee/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }

  getObjectionForm(){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/seniorityList/getAll`)
  }
  
  getByIdObjectionForm(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/seniorityList/getById?id=${id}`)
  }
  saveObjectionForm(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/seniorityList/save`,data)
  }

  seniorityObjectionPositiveScrutiny(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/seniorityList/scrutinyForApprove`,data)
  }

  seniorityObjectionNegativeScrutiny(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/seniorityList/scrutinyForReject`,data)
  }

  getSeniorityList(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstSeniorityList/getAll`)
  }
  
  getActiveFlagSeniorityList(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstSeniorityList/getAllActiveList`)
  }
  getByIdSeniorityList(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstSeniorityList/getById?id=${id}`)
  }

  activeInactiveSeniorityList(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstSeniorityList/activeInactiveMstSeniorityList?id=${id}&activeFlag=${flag}`,null)
  }

  activeInactiveSeniorityListEmpDetails(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstSeniorityList/activeInactiveSeniorityListEmpDetails?id=${id}&activeFlag=${flag}`,null)
  }

  saveSeniorityList(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstSeniorityList/save`,data)
  }

  exportSeniorityList(mstSeniorityId){
    return this.http.post(`${environment.hrmsUrl}/api/reports/generateSeniorityReport?mstSeniorityId=${mstSeniorityId}`,null)
  }

  getsByIdexportSeniorityList(id: any,status: any) {
    const url = `${environment.hrmsUrl}/api/reports/getSeniorityReport?mstSeniorityId=${id} &status=${status}`;
    return this.http.get(url);
  }


  //payment voucher creation 

  savePaymentVoucherService(data:any){
    const formData = new FormData
    formData.append('trnServicePensionPaymentVoucherDaoString',JSON.stringify(data))
    return this.http.post(`${environment.hrmsUrl}/api/transaction/servicepension/savePaymentVoucher`,formData)
  }

  getByPaymentOrderNoService(paymentOrderNo : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/servicepension/getByPaymentOrderNo?paymentOrderNo=${paymentOrderNo}`)
  }

  savePaymentVoucherFamily(data:any){
    const formData = new FormData
    formData.append('trnServicePensionPaymentVoucherDaoString',JSON.stringify(data))
    return this.http.post(`${environment.hrmsUrl}/api/transaction/familypension/savePaymentVoucher`,formData)
  }

  getByPaymentOrderNoFamily(paymentOrderNo : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/familypension/getByPaymentOrderNo?paymentOrderNo=${paymentOrderNo}`)
  }

  savePaymentVoucherDoctor(data:any){
    const formData = new FormData
    formData.append('trnServicePensionPaymentVoucherDaoString',JSON.stringify(data))
    return this.http.post(`${environment.hrmsUrl}/api/transaction/doctorpension/savePaymentVoucher`,formData)
  }

  getByPaymentOrderNoDoctor(paymentOrderNo : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/doctorpension/getByPaymentOrderNo?paymentOrderNo=${paymentOrderNo}`)
  }

  getBudgetDetailsByDptIdBhIdAndYear(dptId : any,bhId : any){
    return this.http.get(`${environment.faUrl}/api/trn/budget/getBudgetDetailsByDptIdBhIdAndYear?dptId=${dptId}&bhId=${bhId}`)

  }

  seniorityListPositiveScrutiny(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstSeniorityList/scrutinyForApprove`,data)
  }

  seniorityListNegativeScrutiny(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstSeniorityList/scrutinyForReject`,data)
  }

  saveAttendanceForm(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/attendanceSystem/save`,data)
  }

  getByIdNewCalender(id : any) {
    return this.http.get(
      `${environment.hrmsUrl}/api/master/attendanceSystem/getById?id=${id}`
    );
  }

  getCalender() {
    return this.http.get(
      `${environment.hrmsUrl}/api/master/attendanceSystem/getAllDashboard`
    );
  }
  getByActiveFlagNewCalender() {
    return this.http.get(
      `${environment.hrmsUrl}/api/master/attendanceSystem/getAll`
    );
  }
  activeInactiveNewCalender(id, flag) {
    return this.http.post(
      `${environment.hrmsUrl}/api/master/attendanceSystem/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveNewCalender(data: any) {
    return this.http.post(
      `${environment.hrmsUrl}/api/master/attendanceSystem/save`,
      data
    );
  }

  saveAttendance(data: any) {
    return this.http.post(
      `${environment.hrmsUrl}/api/attendance/save`,
      data
    );
  }

  // Medical reimbursement new form for employee
  getMedicalReimbursementDetails(){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/getAllForService`)
  }
  // getMedicalReimbursementDetailsById(){
  //   return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/getAllForFamily`)
  // }

  getMedicalReimbursementDetailsById(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/getById?id=${id}`)
  }
  

  saveMedicalReimbursementDetails(mergedData : any){
    
    return this.http.post(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/saveV1`,mergedData)
  }


  getsByIdexport(docName: string): Observable<string> {
    const url = `${environment.hrmsUrl}/api/reports/downloadMedicalCertFormat?docName=${docName}`;
    return this.http.get<string>(url, { responseType: 'text' as 'json' });
  }

  saveDocumentValidity(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/validateMedicalDocument`,data)
  }

  getByIdFinalizeCostDetails(id:any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/getByFinalizeId?medId=${id}`)
  }
  saveFinalizeCostDetails(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/saveFinalizeAmount`,data)
  }
  getByMonthSalaryReport(monthYear :any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/mstPayRoll/getAll?monthYear=${monthYear}`)
  }
  getByEmpIdSalaryReport(id : any,monthYear :any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/mstPayRoll/getById?id=${id} &monthYear=${monthYear}`)
  }
  saveMedicalReimbursementPaymentVoucher(data:any){
    const formData = new FormData
    formData.append('trnMedicalPaymentVoucherDaoString',JSON.stringify(data))
    return this.http.post(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/savePaymentVoucher`,formData)
  }
  getByMedicalReimbursementPaymentOrderNo(paymentOrderNo : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/getByPaymentOrderNo?paymentOrderNo=${paymentOrderNo}`)
  }
  getByActiveFlagEmployeeList(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstEmployee/getAllActiveEmployeeList`)
  }
  getmstAllowance(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAllowance/getAllDashboard`)
  }
  getByActiveFlagmstAllowance(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAllowance/getAllActive`)
  }
  activeInactivemstAllowance(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstAllowance/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  getByIdmstAllowance(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstAllowance/getById?id=${id}`)
  }
  savemstAllowance(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstAllowance/save`,data)
  }
  getmstDeduction(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstDeduction/getAllDashBoard`)
  }
  getByActiveFlagmstDeduction(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstDeduction/getAllActive`)
  }
  activeInactivemstDeduction(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstDeduction/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  getByIdmstDeduction(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstDeduction/getById?id=${id}`)
  }
  savemstDeduction(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstDeduction/save`,data)
  }
  saveRecruitmentQuestions(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstRecruitmentQuestions/save`,data)
  }
  getRecruitmentQuestions(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstRecruitmentQuestions/getAllDashBoard`)
  }
  getAllActiveRecruitmentQuestions(){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstRecruitmentQuestions/getAllActive`)
  }
  getRecruitmentQuestionById(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/master/mstRecruitmentQuestions/getById?id=${id}`)
  }
  activeInactiveRecruitmentQuestions(id,flag){
    return this.http.post(`${environment.hrmsUrl}/api/master/mstRecruitmentQuestions/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,null)
  }
  savePaymentVoucherMedReimbursment(data:any){
    return this.http.post(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/savePaymentVoucher`,data)
  }

  getPaymentVoucheByMedReibursementId(id : any){
    return this.http.get(`${environment.hrmsUrl}/api/transaction/medicalReimbursment/getByPaymentVoucherId?id=${id}`)
  }
}