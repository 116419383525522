<div class="pl-6">
    <h1>Driver Dashboard</h1>
</div>

<div style="padding: 2rem;">
    <div class="grid grid-cols-4 gap-4 ">
        <div *ngFor="let service of masterData">
            <mat-card [routerLink]="[service.router]" class="card w-34 h-28 justify-center text-center shadow-md">
                <mat-card-title>{{service.masterName}}</mat-card-title>
            </mat-card>
        </div>
    </div>
</div>