import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { CommonService } from 'src/app/_services/Common-Services/common.service';
import { AddReligionMasterComponent } from './add-religion-master/add-religion-master.component';
import { ActivationInactivationConfirmationComponent } from '../activation-inactivation-confirmation/activation-inactivation-confirmation.component';
@Component({
  selector: 'app-religion',
  templateUrl: './religion.component.html',
  styleUrls: ['./religion.component.scss']
})
export class ReligionComponent {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  data: any;

  columnNames: any[] = [
    {
      columnDef: 'sno',
      header: 'Sr.No.',
    },
    {
      columnDef: 'religionName',
      header: 'Religion Name',
    },
    {
      columnDef: 'validYn',
      header: 'Valid',
    },
    {
      columnDef: 'actions',
      header: 'Actions',
    },
  ];


  departments = new MatTableDataSource<any>();
  displayedColumns: any[] = []
  contentLoaded = false;
  constructor(private commonService: CommonService,
    private dialog: MatDialog,
    private _snackBar: SnackbarMsgService,) {

  }
  ngOnInit() {
    this.displayedColumns = this.displayedColumns.concat(this.columnNames.map(x => x.columnDef));
    this.getReligion()
  }

  getReligion() {
    this.commonService.getReligion().subscribe(
      data => {
        this.departments.data = data as any;
        this.departments.data = this.departments.data.sort((a, b) => a.id - b.id);
        setTimeout(
          () => {
            this.departments.sort = this.sort;
            this.departments.paginator = this.paginator;
          });

        setTimeout(() => {
          this.contentLoaded = true;
        }, 2000);

      },
      err => {
        this.contentLoaded = true;

      }
    )
  }

  add() {
    const dialogRef = this.dialog.open(AddReligionMasterComponent, {
      width: "550px",
      data: {
        isEditMode: false,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this._snackBar.success('Sucess....!');
        this.getReligion()
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.departments.filter = filterValue.trim().toLowerCase();
  }

  statusAction : any = ''
  checked: boolean = false;

  onSlideToggleChange(row: any, event: any): void {
  
    
    if(event.checked == true){
      this.statusAction = 'Activate'
      row.activeFlag = 'Y'
    }else {
      this.statusAction  = 'Deactivate';
      row.activeFlag = 'N'

    }
    var formData = {
      activeFlag : row.activeFlag
    }

    const dialogRef = this.dialog.open(ActivationInactivationConfirmationComponent, {
      width: "550px",
      data: {
        mode : this.statusAction,
        component : 'Religion'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {

      

        if (this.statusAction == 'Activate') {
          this.commonService.activeInactiveReligion(row.id,formData).subscribe(
            res => {
              this._snackBar.success('Selected Religion activated');
              this.getReligion();

              },
            err => {
              this._snackBar.error('Activation Failed')
              this.getReligion();

            }
          )
          
        } else {
          this.commonService.activeInactiveReligion(row.id,formData).subscribe(
            res => {
              this._snackBar.info('Selected Religion Deactivated');
              this.checked = !this.checked;
              this.getReligion();
              
              },
            err => {
              this._snackBar.error('Deactivation Failed')
              this.getReligion();

            })
        }
      }else {
        this.getReligion();
      }
    })
   
   
  }

  // onSlideToggleChange(row: any, event: any): void {
  //   const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
  //     width: '550px',
  //     data: {
  //       mode: event.checked ? 'Activate' : 'Deactivate',
  //       component: 'Religion'
  //     },
  //     autoFocus: false,
  //     restoreFocus: false
  //   });

  //   dialogRef.afterClosed().subscribe((result: any) => {
  //     if (result === 'success') {
  //       const id = row.id;
  //       const checkFlag = row.activeFlag === "Y" ? "N" : "Y";
  //       this.commonService
  //         .activeInactiveReligion(id, checkFlag)
  //         .subscribe(
  //           (res) => {
  //             if (res['status'].toLowerCase() == 'success') {
  //               if (checkFlag == "N") {
  //                 this._snackBar.error("Deactivated Sucessfully")
  //               } else {
  //                 this._snackBar.success("Activated Sucessfully")
  //               }
  //             }
  //             else {
  //               this._snackBar.error("Error While Activating/DeActivating")
  //             }
  //             this.getReligion();
  //           },
  //           (err) => {
  //             this._snackBar.error("Error While Activating/DeActivating")
  //             // console.log(err);
  //           }
  //         );

  //     } else {
  //       this.getReligion();
  //     }
  //   });

  // }



  onEdit(row: any) {
    const dialogRef = this.dialog.open(AddReligionMasterComponent, {
      width: "550px",
      data: {
        isEditMode: true,
        row: row
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this._snackBar.success('Sucess....!');
        this.contentLoaded = false;
        this.getReligion()
      }
    });
  }

}
