import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/_services/Common-Services/common.service';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';

@Component({
  selector: 'app-add-ofices',
  templateUrl: './add-ofices.component.html',
  styleUrls: ['./add-ofices.component.scss']
})
export class AddOficesComponent {
  sectorId: any = null;
  sectorData: any;
  zoneId: any;
  wards: any[] = []
  zones: any[] = []
  nodes: any[] = []
  selectedZone: any = ''
  nodeId: any;
  selectedOption: string | null = null;
  hideZone: boolean = false;
  officeName:string;
 address:any;
  addedit:any;
  id=null;
  errorMessage:string="";
  createdUserId:any;
  updateUserId:any;
  userId: string;

  constructor(
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private msmService: MsmamServiceService,
    private dialog: MatDialog,
    private _snackBar: SnackbarMsgService,
    public dialogRef: MatDialogRef<AddOficesComponent>,
    @Inject(MAT_DIALOG_DATA) public data1: any){
     
 }
  ngOnInit() {
    this.addedit = this.data1['isEditMode'];
    this.userId = localStorage.getItem("userId");
    this.createdUserId=this.userId;
    this.updateUserId=this.userId;
    if(this.addedit == true){
      console.log(this.data1['row'])
      const data12 = this.data1['row']
      console.log(data12)
      this.zoneId = data12['zoneId'];
      this.getWard(this.zoneId);
      this.nodeId = data12['nodeId'];
      this.wardId = data12['wardId'];
      this.hideNode=false;
      this.hideWard=false;
      this.wardName=data12['wardName'];
      this.sectorId = data12['sectorId'];
      this.officeName=data12['officeName'];
      this.address=data12['address'];
      this.id=data12['id'];
      this.createdUserId=data12['createdUserId'];
      this.updateUserId=this.userId;
      this.selectedValues = true;

}
    // this.getSectorData()
    this.getZone();
    // this.getNodeData()
  }

  

  getZone() {
    this.commonService.getByActiveFlagZone().subscribe((data: any) => {
      this.zones = data['zoneMasterList']
    })
  }

  onZoneChange(data){
    this.getWard(data);
    this.getNode(data);
  }

  getNode(zoneId) {
    this.commonService.getNodeByZoneId(zoneId).subscribe((data: any) => {
      this.nodes = data
    })
  }

  getSectorByNode(nodeId){
    this.commonService.getSectorByNode(nodeId).subscribe((data: any) => {
      this.sectorData = data
    })
  }

  getSectorByWard(wardId){
    this.commonService.getSectorByWard(wardId).subscribe((data: any) => {
      this.sectorData = data
    })
  }
  hideNode: boolean = true;
  hideWard: boolean=true;
  getWard(event: any) {
    this.hideNode=false;
    this.hideWard=false;
    this.zoneId = event;
    this.selectedZone = event;
    this.commonService.getWardByZoneId(this.selectedZone).subscribe((data: any) => {
      this.wards = data
    })
    this.selectedValues = true;

  }

 

  onSectorChange(data: any) {
    // this.sectorId = this.sectorData[0].id;
    this.sectorId = data;
    this.selectedValues = true;

  }

  saveOffice() {
    const data = {
      "id":this.id,
      "zoneId": this.zoneId,
      "wardId": this.wardId,
      "nodeId": this.nodeId,
      "sectorId": this.sectorId,
      "officeName":this.officeName,
      "address":this.address,
      "createdUserId":this.createdUserId,
      "updateUserId":this.updateUserId,
      "activeFlag": "Y"
    };
    

    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: "550px",
      data: {
        mode: this.addedit?'Edit':'Add',
        component: 'Office'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        this.msmService.saveOffices(data).subscribe(
          res => {
            if(res['status'] === 'Success'){
              setTimeout(() => {
                this.dialogRef.close({data : 'Success'});

              }, 1000);
            }else{
                this.errorMessage=res['status']
              }
          },
          err => {
            this._snackBar.error('Office not updated')
            console.log(err)
          }
        )
      }
    })
  }



selectedValues: boolean = false;
wardId: any;
wardName: any;
onWardSelection(data: any){
  this.hideNode=true
  this.nodeId=null;
  this.selectedValues = true
  this.getSectorByWard(data);
}

onNodeChange(event: any){
 this.hideWard=true;
 this.wardName=null;
 this.wardId=null;
 this.getSectorByNode(event);

}

}
