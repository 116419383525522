import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatkeyvalue'
})
export class FormatkeyvaluePipe implements PipeTransform {

  transform(value: any): any {
    return value
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  }

}
