import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-driver-dashboard',
  templateUrl: './driver-dashboard.component.html',
  styleUrls: ['./driver-dashboard.component.scss']
})
export class DriverDashboardComponent implements OnInit{

  paramid:any;
  subDeptData:any;
  masterData:any;
  constructor(private router:Router){}
  ngOnInit(): void {
    this.getMasterData();
  }

  getMasterData() {
   this.masterData = [
     {
      masterName : 'Fuel',
      subMasterName : 'Dept1',
      router:'/fuelRecord'
     },
     {
      masterName : 'Maintenance',
      subMasterName : 'Dept1',
      router:'/cwm-maintenance'
     }
   ]
  }

  onCardClick(service) {
    if(service.masterName == 'Department') {
      this.router.navigateByUrl('/departmentMaster')
    }else if(service.masterName == 'Sub Department') {
      this.router.navigateByUrl('/subDepartmentMaster')
    }
  }
}
