<div class="forbidden-container">
  <div class="forbidden-content w-full">
      <div class="illustration">
          <div class="number">4</div>
          <div class="svg-container">
              <img src="assets/undraw_loading_re_5axr.svg" alt="403 Illustration">
          </div>
          <div class="number">3</div>
      </div>
      <div class="bottomContent">
          <h1>We are Sorry...</h1>
          <p>The page you're trying to access has restricted access.<br>
          Please refer to your system administrator.</p>
          <button (click)="goBack()">Go Back</button>
      </div>
  </div>
</div>