<div class="grid" style="background-color: #7ac142; margin: 0; padding: 0">
  <div class="col-12" style="margin: 0; padding: 0">
    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
      <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  </div>
  <div
    class="col-12"
    style="
      text-align: center;
      font-size: 20px;
      letter-spacing: 1px;
      color: #fff;
      margin-top: -1rem;
      margin-bottom: 2rem;
    "
  >
    SUCCESS
  </div>
</div>
<div class="grid" style="text-align: center">
  <div class="col-12" style="font-size: 20px; color: grey; margin-top: 2rem">
    Payment Successful
    <!-- <div style="font-size: 12px" class="mt-3">for transaction id :</div>
    {{ transactionId }} -->
  </div>
  <!-- <div class="col-12">
    <button
      mat-raised-button
      mat-dialog-close
      [autofocus]="false"
      style="
        background-color: #7ac142;
        color: #fff;
        border-radius: 20px;
        width: 200px;
        font-weight: normal;
        line-height: 40px;
        font-size: 20px;
        margin-top: 1rem;
      "
    >
      View Status
    </button>
  </div> -->
  <div class="col-12 mt-3">
    <a routerLink="/dashboard" style="text-decoration: underline; color: grey; font-size: 10px" mat-dialog-close>
      GO TO DASHBOARD
    </a>
  </div>
</div>
