<mat-card  [class.mat-elevation-z8]="true"
style="margin: 1.4rem;" >

<mat-card-content> 
  <div class="p-4">
    <h2 class="text-2xl font-semibold">Application Details</h2>
    <!-- <form class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        <ng-container *ngFor="let item of data | keyvalue">
            <div class="mb-4" *ngIf="item.value">
                <label for="{{item.key}}" class="text-md font-semibold max-w-full">{{ item.key | formatkeyvalue
                    }}:</label>
                <br>
                <ng-container *ngIf="!isPdfOrTxt(item.value)">
                    <label for="" class="text-center text-gray-500 overflow-x-auto max-w-full">{{ item.value }}</label>
                </ng-container>
                <ng-container *ngIf="isPdfOrTxt(item.value)">
                    <a href="" 
                    *ngIf="isPdfOrTxt(item.value)" 
                    (click)="show(item.value)"
                    class="font-medium text-blue-600 dark:text-blue-500 underline hover:underline">View</a>
                   
                </ng-container>
            </div>
        </ng-container>
    </form> -->
</div>
  <!-- form -->
<div class=" w-full  p-4">
    <div class="lg:flex lg:items-center lg:justify-between">
        <div class="min-w-0 flex-1">
        <!-- <h2 class="text-2xl font-bold">Application scrutiny</h2> -->
    
        </div>
        <div class="mt-1 flex lg:ml-4 lg:mt-0">
       
        </div>
    </div>
    
</div>
<div class="w-full  p-4">
      <!-- <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Choose an action</label> -->
      
        <!-- On take action  -->
        <!-- <div class="grid w-[20rem] grid-cols-2 gap-2 rounded-xl bg-gray-200 p-2">
            
                <div>
                    <input type="radio" name="option" id="1" value="A" class="peer hidden" 
                    (change)="handleActionChange($event)"

                    checked/>
                    <label for="1" class="block cursor-pointer select-none rounded-xl p-2 text-center peer-checked:bg-green-500 peer-checked:font-bold peer-checked:text-white">Approve</label>
                </div>
        
                <div>
                    <input type="radio" name="option" id="2" value="R" class="peer hidden"
                    (change)="handleActionChange($event)"
                    />
                    <label for="2" class="block cursor-pointer select-none rounded-xl p-2 text-center peer-checked:bg-red-500 peer-checked:font-bold peer-checked:text-white">Decline</label>
                </div>
        
        </div> -->

        <!-- on remark -->
        <!-- <div class="relative z-0 w-full mb-5 pt-4">
          
        <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Remark</label>
        <textarea 
        id="remark"
        name="remark"
        required
        (keypress)="enterRemark()"
        [(ngModel)]="rejectRemark"
         rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
         placeholder="Write your remark here..."></textarea>
        </div> -->

        <!-- on doc upload  -->
        <div *ngIf="allQuotesUploaded == false">
            <label for="message" class="block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Competitive Quotes</label>
         <button   (click)="file.click()" type="button" class=" border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
          Document upload 
         </button>
         <input #file type="file" multiple (change)="upload($event)" name="my_file" id="my-file" accept="application/pdf,image/*">
        </div>

        <!-- on success doc uploads -->
        <div *ngIf="allQuotesUploaded == true">
            <label for="message" class="block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Competitive Quotes</label>
            <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="flex items-center pl-3">
                        <input id="horizontal-list-radio-license" (change)="handleQuoteChange('Quote1')" type="radio" value="" name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                        <label for="horizontal-list-radio-license" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Competitive Quote 1 </label>
                        
                    </div>
                    <mat-progress-bar mode="indeterminate" *ngIf="loading1 == true"></mat-progress-bar>

                    <div  class="mt-3"  *ngIf="showImage3 == 'P'">
                        <ngx-doc-viewer
                        [url]="quote1Url"
                        (loaded)="contentLoaded1()"
                        viewer="google"
                        style="width:100%;height:40vh;"
                        >
                        </ngx-doc-viewer>
                      </div>
                      <div *ngIf="showImage3 == 'Y'" class="mt-3">
                        <img [src]="quote1Url">
        
                      </div> 
                </li>
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="flex items-center pl-3">
                        <input id="horizontal-list-radio-id" (change)="handleQuoteChange('Quote2')" type="radio" value="" name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                        <label for="horizontal-list-radio-id" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Competitive Quote 2</label>
                    </div>
                    <mat-progress-bar mode="indeterminate" *ngIf="loading2 == true"></mat-progress-bar>

                    <div  class="mt-3"  *ngIf="showImage2 == 'P'">
                        <ngx-doc-viewer
                        [url]="quote2Url"
                        (loaded)="contentLoaded2()"
                        viewer="google"
                        style="width:100%;height:40vh;"
                        >
                        </ngx-doc-viewer>
                      </div>
                      <div *ngIf="showImage2 == 'Y'" class="mt-3">
                        <img [src]="quote2Url">
        
                      </div> 
                </li>
                <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                    <div class="flex items-center pl-3">
                        <input id="horizontal-list-radio-millitary" (change)="handleQuoteChange('Quote3')" type="radio" value="" name="list-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                        <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Competitive Quote 3</label>
                    </div>
                    <mat-progress-bar mode="indeterminate" *ngIf="loading3 == true"></mat-progress-bar>
                    <div  class="mt-3" *ngIf="showImage3 == 'P'">
                        <ngx-doc-viewer
                        [url]="quote3Url"
                        (loaded)="contentLoaded3()"
                        viewer="google"
                        style="width:100%;height:40vh;"
                        >
                        </ngx-doc-viewer>
                      </div>
                      <div *ngIf="showImage3 == 'Y'" class="mt-3">
                        <img [src]="quote3Url">
        
                      </div> 
                </li>
                
            </ul>
        </div>

         <!-- on work order upload  -->
         <div *ngIf="showWorkOrder == true">
            <label for="message" class="block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Work Order</label>
         <button   (click)="file.click()" type="button" class=" border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
        Order upload 
         </button>
         <input #file type="file" (change)="uploadWorkOrder($event)" name="my_file2" id="my-file2" accept="application/pdf,image/*">
        </div>

         <!-- on invoice upload  -->
         <div *ngIf="showInvoice == true">
            <label for="message" class="block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Invoice</label>
         <button   (click)="file.click()" type="button" class=" border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
          Invoice upload 
         </button>
         <input #file type="file" (change)="uploadInvoice($event)" name="my_file2" id="my-file2" accept="application/pdf,image/*">
        </div>

         <!-- on receipt upload  -->
         <div *ngIf="showReceipt == true">
            <label for="message" class="block mb-2 text-sm mt-4 font-medium text-gray-900 dark:text-white">Payment Receipt</label>
         <button   (click)="file.click()" type="button" class=" border rounded-md px-4 py-2  bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
          Receipt upload 
         </button>
         <input #file type="file" (change)="uploadPaymentReceipt($event)" name="my_file2" id="my-file2" accept="application/pdf,image/*">
        </div>


        <div class="float-right">
            <button
            id="button"
            type="submit"
            (click)="saveScrutiny()"
            [disabled]="formDisabled"
            class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
          >
            Submit
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button
          id="button"
          (click)="cancel()"
          type="button"
          class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-red-500 hover:bg-red-600 hover:shadow-lg focus:outline-none"
        >
          Cancel
        </button>
        </div>
      
</div>

</mat-card-content>

</mat-card>
