import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent {
  action : any = ''
  component : any = ''
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ConfirmationPopupComponent>,) { }

  ngOnInit(): void {
    this.action = this.data.mode
    this.component = this.data.component
  }

  onConfirm() {
    this.dialogRef.close('success');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

