import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-activation-inactivation-confirmation',
  templateUrl: './activation-inactivation-confirmation.component.html',
  styleUrls: ['./activation-inactivation-confirmation.component.scss']
})
export class ActivationInactivationConfirmationComponent {
  action : any = ''
  component : any = ''
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ActivationInactivationConfirmationComponent>,) { }

  ngOnInit(): void {
    this.action = this.data.mode
    this.component = this.data.component
  }

  onConfirm() {
    this.dialogRef.close('success');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}


