import { Component } from '@angular/core';

@Component({
  selector: 'app-hrms-dashboard',
  templateUrl: './hrms-dashboard.component.html',
  styleUrls: ['./hrms-dashboard.component.scss']
})
export class HrmsDashboardComponent {
  masterItems = [
    // { title: 'Bank', routerLink: '/hrms-module/hrms-bank' },
    // { title: 'Bank Branch', routerLink: '/hrms-module/hrms-bank-branch' },
    { title: 'Week Off', routerLink: '/hrms-module//hrms-week-off' },
    { title: 'Holiday', routerLink: '/hrms-module/hrms-holiday' },
    { title: 'Department Head', routerLink: '/hrms-module/hrms-dept-head' },
    { title: 'Shift', routerLink: '/hrms-module/hrms-shift' },
    { title: 'Roaster Setting', routerLink: '/hrms-module/hrms-roaster-setting' },
    { title: 'Leave Type', routerLink: '/hrms-module/hrms-leave-type' },
    { title: 'Leave Allocation', routerLink: '/hrms-module/leave-allocation' },
    { title: 'DPC', routerLink: '/hrms-module/hrms-dpc' },
    { title: 'Permission', routerLink: '/hrms-module/hrms-permission' },
    { title: 'Employee', routerLink: '/hrms-module/hrms-employee' },
    { title: 'Salary Item', routerLink: '/hrms-module/hrms-salary-item' },
    { title: 'Loan', routerLink: '/hrms-module/hrms-Loan' },
    { title: 'Pay Scale', routerLink: '/hrms-module/hrms-pay-scale' },
    { title: 'Pay Band', routerLink: '/hrms-module/hrms-pay-band' },
    { title: 'Pay Scale to Pay Band', routerLink: '/hrms-module/hrms-pay-scale-to-pay-band' },
    { title: 'Departmental Posts', routerLink: '/hrms-module/departmental-posts' },
    { title: 'pay Commission', routerLink: '/hrms-module/hrms-pay-commission' },
    { title: 'Confidential Review Rating', routerLink: '/hrms-module/hrms-review-rating' },
    { title: 'Increment', routerLink: '/hrms-module/hrms-increment' },
    { title: 'Group', routerLink: '/hrms-module/hrms-group' },
    { title: 'Examination Details', routerLink: '/hrms-module/hrms-examination-details' },
    { title: 'Handicap Type', routerLink: '/hrms-module/hrms-handicap-type' },
    { title: 'Role', routerLink: '/hrms-module/hrms-role' },
    // { title: 'Questions Master', routerLink: '/hrms-module/hrms-question-master' },
    { title: 'Appendix A', routerLink: '/hrms-module/appendix-A' },
    { title: 'Appendix B', routerLink: '/hrms-module/appendix-B' },
    { title: 'Appendix C', routerLink: '/hrms-module/appendix-C' },
    { title: 'Appendix A for Caste', routerLink: '/hrms-module/appendix-A-For-Caste' },
    { title: 'Appendix B for Caste', routerLink: '/hrms-module/appendix-B-For-Caste' },
    // { title: 'Appendix C for Caste', routerLink: '/hrms-module/appendix-C-For-Caste' },
    { routerLink: '/hrms-module/fractionationTable', title: 'Fractionation Table' },
    { routerLink: '/hrms-module/ref-book', title: 'Ref Book' }






    // { title: 'Leave Application', routerLink: '/hrms-module/leave-application' },

  ];
}
