import { ChangeDetectorRef, Component } from '@angular/core';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { INITIAL_EVENTS, createEventId } from './event-utils';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { MatDialog } from '@angular/material/dialog';
import { AddCalenderShedulingComponent } from './add-calender-sheduling/add-calender-sheduling.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';
import { ConfirmationModalComponent } from './add-calender-sheduling/confirmation-modal/confirmation-modal.component';
@Component({
  selector: 'app-calender-scheduling-and-holiday',
  templateUrl: './calender-scheduling-and-holiday.component.html',
  styleUrls: ['./calender-scheduling-and-holiday.component.scss']
})
export class CalenderSchedulingAndHolidayComponent {
  calendarVisible = true;
  calendarOptions: CalendarOptions = {
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
    ],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    initialView: 'dayGridMonth',
   initialEvents: INITIAL_EVENTS, 
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this)
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  };
  currentEvents: EventApi[] = [];

  constructor(private changeDetector: ChangeDetectorRef,private msmService:MsmamServiceService,
    private dialog: MatDialog,
    private _snackBar: SnackbarMsgService) {
    this.loadEventsFromBackend();

  }

  loadEventsFromBackend() {
    this.msmService.getByActiveFlagNewCalender().subscribe(
      (events: any[]) => {
        const fullCalendarEvents: any[] = events['calenderSchedulingAndHolidayMasterList'].map((backendEvent) => ({
          id: backendEvent.id,
          title: backendEvent.event,
          start: backendEvent.date, 
          allDay: true,
          remark:backendEvent.remark,
          isActive: backendEvent.activeFlag,
          calenderPrefix:backendEvent.calenderPrefix,

        }));

        this.calendarOptions.events = fullCalendarEvents;

        this.currentEvents = fullCalendarEvents;

        this.changeDetector.detectChanges();

      },
      (error) => {
        console.error('Error fetching events from the backend:', error);
      }
    );
  }
  
  
  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
  

    const dialogRef = this.dialog.open(AddCalenderShedulingComponent, {
      width: "550px",
      data: {
        isEditMode: false,
        date:selectInfo.startStr
      },
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
      this._snackBar.success("event added Sucessfully");
      this.loadEventsFromBackend();
      }
    });
   
  }
row:any;
  handleEventClick(clickInfo: EventClickArg) {
    console.log(clickInfo.event)
    if (clickInfo.event.title) {
        this.row = {
          id:clickInfo.event.id,
          event:clickInfo.event.title,
          date: clickInfo.event.startStr,
          remark:clickInfo.event.extendedProps['remark'],
           calenderPrefix:clickInfo.event.extendedProps['calenderPrefix'],
          isActive:"Y",
        };}
   
   this.confirmationEvent();
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
    this.changeDetector.detectChanges();
  }

  confirmationEvent(){
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: "550px",
      data: {
        // mode: this.addedit?'Edit':'Add',
        component: 'Event'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        this.eventEditPopup();
        
      }
      else if(result == "delete"){
       
        this.msmService.activeInactiveNewCalender(this.row['id'],'N').subscribe(
          (res) => {
            this._snackBar.info("The event deleted Sucessfully");
            this.loadEventsFromBackend();
          },
          (error) => {
            this._snackBar.error("The error while deleting event");
          })
      }
    })
  }

  eventEditPopup(){
    const dialogRef = this.dialog.open(AddCalenderShedulingComponent, {
      width: "550px",
      data: {
        isEditMode: true,
         row:this.row
      },
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this._snackBar.success("Event Edited Sucessfully");
        this.loadEventsFromBackend();
      }
    });
  }
}
