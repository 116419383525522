<div class="bg-indigo-600 text-white py-8 text-center">
  <h1 class="text-6xl font-bold">Welcome to Property Tax Management</h1>
</div>

<div class="bg-white p-6">
  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
    <mat-form-field class="searchBox col-span-1 md:col-span-1">
      <mat-label>Enter Property No</mat-label>
      <input matInput placeholder="Search" [(ngModel)]="propertyNo" [ngModelOptions]="{standalone: true}" />
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Select Cycle</mat-label>
      <mat-select [(ngModel)]="selectedCycle" name="cycle" id="cycle" placeholder="Select Title">
        <mat-option value="April-September">April-September</mat-option>
        <mat-option value="October-March">October-March</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Select Financial Year</mat-label>
      <mat-select [(ngModel)]="selectedFinancialYear" name="financialYear" id="financialYear" placeholder="Select Financial Year">
        <mat-option *ngFor="let year of financialYears" [value]="year">{{ year }}</mat-option>
      </mat-select>
    </mat-form-field>
    
    
    <button mat-fab color="primary" aria-label="Search" (click)="onSearch()">
      <mat-icon>search</mat-icon>
    </button>
  </div>

  <table *ngIf="billDetails" class="table-auto border-collapse border border-gray-300 w-full text-center mt-5">
    <thead>
      <tr>
        <th rowspan="2" class="bg-indigo-200 font-serif text-sm font-semibold px-4 py-2">Tax Name</th>
        <th colspan="2" class="bg-indigo-200 font-serif text-sm font-semibold px-4 py-2">Residential</th>
        <th colspan="2" class="bg-indigo-200 font-serif text-sm font-semibold px-4 py-2">Commercial</th>
      </tr>
      <tr class="bg-indigo-200">
        <th class="px-4 py-2 font-serif text-sm">Authorised</th>
        <th class="px-4 py-2 font-serif text-sm">Non-Authorised</th>
        <th class="px-4 py-2 font-serif text-sm">Authorised</th>
        <th class="px-4 py-2 font-serif text-sm">Non-Authorised</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let tax of getTaxNames()">
        <td class="px-4 py-2 border border-gray-300">{{ tax }}</td>
        <td class="px-4 py-2 border border-gray-300">{{ formatCurrency(billDetails.data.residentialAuthorized[tax]?.taxRate) || '' }}</td>
        <td class="px-4 py-2 border border-gray-300">{{ formatCurrency(billDetails.data.residentialUnauthorized[tax]?.taxRate) || '' }}</td>
        <td class="px-4 py-2 border border-gray-300">{{ formatCurrency(billDetails.data.commercialAuthorized[tax]?.taxRate) || '' }}</td>
        <td class="px-4 py-2 border border-gray-300">{{ formatCurrency(billDetails.data.commercialUnauthorized[tax]?.taxRate) || '' }}</td>
      </tr>
      <tr>
        <td class="px-4 py-2 border border-gray-300"><strong>Total</strong></td>
        <td class="px-4 py-2 border border-gray-300"><strong>{{ formatCurrency(totalResidentialAuthorized) }}</strong></td>
        <td class="px-4 py-2 border border-gray-300"><strong>{{ formatCurrency(totalResidentialUnauthorized) }}</strong></td>
        <td class="px-4 py-2 border border-gray-300"><strong>{{ formatCurrency(totalCommercialAuthorized) }}</strong></td>
        <td class="px-4 py-2 border border-gray-300"><strong>{{ formatCurrency(totalCommercialUnauthorized) }}</strong></td>
      </tr>
      <tr>
        <td class="px-4 py-2 border border-gray-300"><strong>267A</strong></td>
        <td class="px-4 py-2 border border-gray-300"><strong></strong></td>
        <td class="px-4 py-2 border border-gray-300"><strong>{{ formatCurrency(totalResidentialUnauthorized * 2) }}</strong></td>
        <td class="px-4 py-2 border border-gray-300"><strong></strong></td>
        <td class="px-4 py-2 border border-gray-300"><strong>{{ formatCurrency(totalCommercialUnauthorized * 2) }}</strong></td>
      </tr>
      <tr>
        <td colspan="5" class="px-4 py-2 border border-gray-300"><strong>Total of All Taxes: {{ formatCurrency(totalOfAllTaxes) }}</strong></td>
      </tr>
      <tr>
        <td colspan="5" class="px-4 py-2 border border-gray-300"><strong>Total 267A: {{ formatCurrency((totalResidentialUnauthorized + totalCommercialUnauthorized) * 2)}}</strong></td>
      </tr>
      <tr>
        <td colspan="5" class="px-4 py-2 border border-gray-300"><strong>Bill: {{ formatCurrency(totalOfAllTaxes + (totalResidentialUnauthorized + totalCommercialUnauthorized) * 2)}}</strong></td>
      </tr>
    </tbody>
  </table>



</div>

