<mat-toolbar class="fixed top-0 left-0 w-full z-50"
  style="background-color: #015296; color: #fff; min-height: 30px !important">
  <button mat-icon-button aria-label="Menu" (click)="toggleSideNav()" *ngIf="isAuthenticated">
    <mat-icon>menu</mat-icon>
  </button>
  <img routerLink="/dashboard" class="w-12 h-12 rounded-full mx-7 p-1" src="../../assets/nmmc.jpg" alt="" />
  <span class="text-lg">NMMC</span>
  <div class="flex justify-end items-center flex-grow text-sm" *ngIf="isAuthenticated">

    <p class="aligned-with-icon pt-2 px-2" *ngIf="ugpPrevId === '2'">
      {{ loginName }}<br>
      <span *ngIf="designationName && departmentName">
        ({{ designationName }} - {{ departmentName }})
      </span>
    </p>
    <p class="aligned-with-icon pt-4 px-2" *ngIf="ugpPrevId == 3">
      {{ loginName }} <br />
      (Citizen)
    </p>
    <p class="aligned-with-icon pt-4 px-2" *ngIf="ugpPrevId != 3 && ugpPrevId != 2">
      {{ loginName }} <br />
    </p>
  </div>
  <div>
    <button mat-icon-button aria-label="People" [matMenuTriggerFor]="userMenu" *ngIf="isAuthenticated">
      <mat-icon>person</mat-icon>
    </button>
    <mat-menu #userMenu="matMenu">
      <button mat-menu-item (click)="goToUserProfile()">User Profile</button>
      <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
  </div>
</mat-toolbar>

<!-- Sidebar (mat-sidenav) -->
<mat-sidenav-container class="example-container h-full" style="height: calc(100vh - 10px)">
  <mat-sidenav [autoFocus]="false" #sidenav mode="over" opened="false" style="width: 290px" *ngIf="isAuthenticated">
    <mat-nav-list style="text-align: left; margin-top: 50px">
      <div>
        <mat-list-item *ngIf="ugpPrevId !== '15'" (click)="toggleSideNav()" routerLink="/dashboard">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">home</mat-icon>
            <h3 class="mt-3">Home</h3>
          </div>
        </mat-list-item>

        <mat-accordion *ngIf="isRTSServiceRoute  && userId === '018d8776-b566-7d3c-9904-47166ad9dc0a'" [multi]="false">
          <!-- Masters -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Masters</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of rtsServices" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion *ngIf="isSurveyRoute && userId === '018d8776-b566-7d3c-9904-47166ad9dc0a'" [multi]="false">
          <!-- Masters -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Masters</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of surveyServices" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>


        <!-- Online rts -->
        <mat-list-item *ngIf="ugpPrevId == '3' && isRTSServiceRoute" routerLink="/online-rts/brief-case"
          (click)="toggleSideNav()">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">folder</mat-icon>
            <h3 class="mt-3">Brief Case</h3>
          </div>
        </mat-list-item>
        <!-- <mat-list-item routerLink="/online-rts/documentMaster" *ngIf="isRTSServiceRoute && ugpPrevId == '2'"
          (click)="toggleSideNav()">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">article</mat-icon>
            <h3 class="mt-3">Document</h3>
          </div>
        </mat-list-item> -->
        <mat-list-item routerLink="/online-rts/rtsservicedashboard"
          *ngIf="isRTSServiceRoute && ugpPrevId == '2' && userId=='018d8776-b566-7d3c-9904-47166ad9dc0a'"
          (click)="toggleSideNav()">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">bookmark</mat-icon>
            <h3 class="mt-3">Service</h3>
          </div>
        </mat-list-item>
        <mat-list-item routerLink="/online-rts/application-count" *ngIf="isRTSServiceRoute && ugpPrevId == '2'"
          (click)="toggleSideNav()">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">dashboard</mat-icon>
            <h3 class="mt-3">Application Count</h3>
          </div>
        </mat-list-item>

        <mat-list-item routerLink="online-rts/action-dashboard" *ngIf="isRTSServiceRoute && ugpPrevId == '2' " (click)="toggleSideNav()">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">dashboard</mat-icon>
            <h3 class="mt-3">Action Dashboard </h3>
          </div>
        </mat-list-item>

        <mat-list-item routerLink="online-rts" *ngIf="isRTSServiceRoute && ugpPrevId == '2' " (click)="toggleSideNav()">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">dashboard</mat-icon>
            <h3 class="mt-3">Applications Dashboard</h3>
          </div>
        </mat-list-item>


        <mat-list-item routerLink="online-rts" *ngIf="isRTSServiceRoute && ugpPrevId == '3'" (click)="toggleSideNav()">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">dashboard</mat-icon>
            <h3 class="mt-3">Application Dashboard</h3>
          </div>
        </mat-list-item>

        <!-- <mat-list-item routerLink="online-rts/action-dashboard" *ngIf="isRTSServiceRoute && ugpPrevId == '2'"
          (click)="toggleSideNav()">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">dashboard</mat-icon>
            <h3 class="mt-3">Action Dashboard</h3>
          </div>
        </mat-list-item> -->

        <!-- <mat-list-item routerLink="online-rts/ward-map" *ngIf="isRTSServiceRoute " (click)="toggleSideNav()">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">map</mat-icon>
            <h3 class="mt-3">Ward Map</h3>
          </div>
        </mat-list-item> -->

        <!-- <mat-list-item
          routerLink="online-rts/ward-map"
          *ngIf="isRTSServiceRoute"
          (click)="toggleSideNav()"
        >
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">map</mat-icon>
            <h3 class="mt-3">Ward Map</h3>
          </div>
        </mat-list-item> -->

        <mat-list-item routerLink="online-rts/rts-services" *ngIf="isRTSServiceRoute" (click)="toggleSideNav()">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">dashboard</mat-icon>
            <h3 class="mt-3">Service Dashboard</h3>
          </div>
        </mat-list-item>

        <mat-list-item routerLink="online-rts/pandal-service-report" *ngIf="isRTSServiceRoute && ugpPrevId == '2' && userId === '018d8776-b566-7d3c-9904-47166ad9dc0a'"
          (click)="toggleSideNav()">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">dashboard</mat-icon>
            <h3 class="mt-3">Ganpati Pandal Service Report</h3>
          </div>
        </mat-list-item>

        <mat-list-item routerLink="online-rts/application-service-report" *ngIf="isRTSServiceRoute && ugpPrevId == '2' && userId === '018d8776-b566-7d3c-9904-47166ad9dc0a'"
          (click)="toggleSideNav()">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">dashboard</mat-icon>
            <h3 class="mt-3">Pandal Application Service Report</h3>
          </div>
        </mat-list-item>


        <mat-list-item routerLink="online-rts/export-excel" *ngIf="isRTSServiceRoute && ugpPrevId == '2'"
          (click)="toggleSideNav()">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">dashboard</mat-icon>
            <h3 class="mt-3">Export Service Report</h3>
          </div>
        </mat-list-item>

        <!-- <mat-list-item *ngIf="isRTSServiceRoute && ugpPrevId == '2'" (click)="toggleSideNav()"
          routerLink="/online-rts/service-level-escalation">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">article</mat-icon>
            <h3 class="mt-3">Service Level Escalation</h3>
          </div>
        </mat-list-item> -->

        <!-- <mat-list-item *ngIf="isRTSServiceRoute && ugpPrevId == '2'" (click)="toggleSideNav()"
          routerLink="/online-rts/application-service-form/018f532f-28ae-71cd-abe6-d9914bb7e1cb">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">article</mat-icon>
            <h3 class="mt-3">Outdated Renewal License</h3>
          </div>
        </mat-list-item> -->
        <mat-list-item routerLink="/survey-database/survey-dashboard"
          *ngIf="isSurveyRoute && (ugpPrevId == '2' || ugpPrevId == '3')" (click)="toggleSideNav()">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">bookmark</mat-icon>
            <h3 class="mt-3">Dashboard</h3>
          </div>
        </mat-list-item>
        <mat-list-item routerLink="/survey-database/department-survey-dashboard"
          *ngIf="isSurveyRoute && ugpPrevId == '2'" (click)="toggleSideNav()">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">article</mat-icon>
            <h3 class="mt-3">Manage Survey</h3>
          </div>
        </mat-list-item>
        <mat-list-item routerLink="/survey-database/children-information" *ngIf="isSurveyRoute && ugpPrevId == '2'"
          (click)="toggleSideNav()">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">article</mat-icon>
            <h3 class="mt-3">Children Information</h3>
          </div>
        </mat-list-item>

        <!-- Online rts -->
        <!-- CWM -->
        <mat-accordion *ngIf="isCWMRoute" [multi]="false">
          <!-- Masters -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Masters</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of cwmMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3" *ngIf="ugpPrevId !== '5'">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <!-- Documents -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Transaction</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of cwmtransactionMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">{{
                    menuItem.icon
                    }}</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>
        <!-- CWM -->
        <!-- PROPERTY-TAX -->
        <mat-accordion *ngIf="isProtax" [multi]="false">
          <!-- Masters -->
          <mat-expansion-panel *ngIf="this.ugpPrevId == '2'">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Masters</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of propertyTaxMaster" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <!-- Transaction -->
          <mat-expansion-panel *ngIf="isProtax">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Transactions</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of propertyTaxTransaction" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>


          <!-- Transaction -->
          <!-- 
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Transaction</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a
                routerLink="/property-tax/new-property-registration-dashboard"
                mat-list-item
                routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200"
                style="border-radius: 10px"
              >
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">New Registration</p>
                </div>
              </a>
              <a
                routerLink="/property-tax/transfer-dashboard"
                mat-list-item
                routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200"
                style="border-radius: 10px"
              >
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">Transfer</p>
                </div>
              </a>

              <a
                routerLink="/property-tax/cheque-dishonoured-dashboard"
                mat-list-item
                routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200"
                style="border-radius: 10px"
              >
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">Cheque Dishonour</p>
                </div>
              </a>

              <a
                routerLink="/property-tax/hearing-on-objection-dashboard"
                mat-list-item
                routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200"
                style="border-radius: 10px"
              >
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">Hearing On Objection</p>
                </div>
              </a>

              <a
                routerLink="/property-tax/bill-generation-dashboard"
                mat-list-item
                routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200"
                style="border-radius: 10px"
              >
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">Bill Generation</p>
                </div>
              </a>
              <a
                routerLink="/property-tax/property-services-dashboard"
                mat-list-item
                routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200"
                style="border-radius: 10px"
              >
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">Property Services</p>
                </div>
              </a>
              <a
                routerLink="/property-tax/property-attachment-and-auction"
                mat-list-item
                routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200"
                style="border-radius: 10px"
              >
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">Attachment & Auction</p>
                </div>
              </a>
              <a
                routerLink="/property-tax/property-exemption-trn-dashboard"
                mat-list-item
                routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200"
                style="border-radius: 10px"
              >
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">Property Tax Exemption</p>
                </div>
              </a>
              <a
                routerLink="/property-tax/property-transfer-issuence-dashboard"
                mat-list-item
                routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200"
                style="border-radius: 10px"
              >
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">Transfer Certificate Issuence</p>
                </div>
              </a>
             
            </mat-nav-list>
          </mat-expansion-panel> -->

          <!-- Reports -->
          <mat-expansion-panel *ngIf="this.ugpPrevId == '2'">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Reports</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of propertyTaxReports" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">{{
                    menuItem.icon
                    }}</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>
        <!-- PROPERTY-TAX -->

        <!-- Disaster Managment -->
        <mat-accordion *ngIf="isDisaster" [multi]="false">
          <!-- Masters -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Masters</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of disasterMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
          <!-- Transaction -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Transaction</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of disasterTransactionMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">{{
                    menuItem.icon
                    }}</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>

        <!-- HRMS -->
        <mat-accordion *ngIf="isHrmsRoute" [multi]="false">
          <!-- Masters -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Masters</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of hrmsMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
          <!-- Transaction -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Transaction</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a routerLink="/hrms-module/leave-management" mat-list-item routerLinkActive="active"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p>Leave Application</p>
                </div>
              </a>
              <a routerLink="/hrms-module/hrms-confidential-report" mat-list-item routerLinkActive="active"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p>Confidential Report</p>
                </div>
              </a>
              <a routerLink="/hrms-module/life-certificate" mat-list-item routerLinkActive="active"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p>Life Certificate</p>
                </div>
              </a>
              <a routerLink="/hrms-module/loan-application" mat-list-item routerLinkActive="active"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p>Loan Application</p>
                </div>
              </a>
              <a routerLink="/hrms-module/medical-reimbursement-dashboard" mat-list-item routerLinkActive="active"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p>Medical Reimbursement</p>
                </div>
              </a>
              <a routerLink="/hrms-module/recruitment-process" mat-list-item routerLinkActive="active"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p>Recuitment Process</p>
                </div>
              </a>
              <a routerLink="/hrms-module/service-pension-proposal" mat-list-item routerLinkActive="active"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p>Pensioner Flow</p>
                </div>
              </a>
              <a routerLink="/hrms-module/task-management" mat-list-item routerLinkActive="active"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p>Task Management</p>
                </div>
              </a>
              <a routerLink="/hrms-module/transfer-process" mat-list-item routerLinkActive="active"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p>Transfer Process</p>
                </div>
              </a>
              <a routerLink="/hrms-module/pay-hike-6th-pay" mat-list-item routerLinkActive="active"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p>Pay Fixation</p>
                </div>
              </a>
              <a routerLink="/hrms-module/shift-process" mat-list-item routerLinkActive="active"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p>Shift Process</p>
                </div>
              </a>
              <a routerLink="/hrms-module/payroll" mat-list-item routerLinkActive="active" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200" style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p>Payroll Process</p>
                </div>
              </a>
              <!-- <mat-expansion-panel >
                <mat-expansion-panel-header (click)="stopPropagation($event)">
                  <mat-panel-title>
                    <div class="flex flex-1 gap-4 mt-3">
                      <mat-icon style="color: #015296"
                        >card_membership</mat-icon
                      >
                      <h3 class="mt-3">Pensioner Flow</h3>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list>
                  <a
                    routerLink="/hrms-module/service-pension-proposal"
                    mat-list-item
                    routerLinkActive="active"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="hover:bg-blue-200"
                    style="border-radius: 10px"
                  >
                    <div class="flex flex-1 gap-4 mt-3">
                      <p>Service Pension <br />Proposal</p>
                    </div>
                  </a>

                  <a
                    routerLink="/hrms-module/family-pension"
                    mat-list-item
                    routerLinkActive="active"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="hover:bg-blue-200"
                    style="border-radius: 10px"
                  >
                    <div class="flex flex-1 gap-4 mt-3">
                      <p>Family Pension</p>
                    </div>
                  </a>
                  <a
                    routerLink="/hrms-module/doctors-pension"
                    mat-list-item
                    routerLinkActive="active"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="hover:bg-blue-200"
                    style="border-radius: 10px"
                  >
                    <div class="flex flex-1 gap-4 mt-3">
                      <p>Doctor's Pension</p>
                    </div>
                  </a>
                </mat-nav-list>
              </mat-expansion-panel> -->

              <a routerLink="/hrms-module/leaving-process" mat-list-item routerLinkActive="active"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p>Leaving Process</p>
                </div>
              </a>
              <a routerLink="/hrms-module/calendar-attendance-system" mat-list-item routerLinkActive="active"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p>Attendance System</p>
                </div>
              </a>              
            <a
              routerLink="/hrms-module/salary-dashboard"
              mat-list-item
              routerLinkActive="active"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="hover:bg-blue-200"
              style="border-radius: 10px"
            >
              <div class="flex flex-1 gap-4 mt-3">
                <mat-icon style="color: #015296">card_membership</mat-icon>
                <p>Salary Dashboard</p>
              </div>
            </a>
              <a
                routerLink="/hrms-module/seniority-list"
                mat-list-item
                routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200"
                style="border-radius: 10px"
              >
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p>Seniority List</p>
                </div>
              </a>
              <!-- <a
                routerLink="/hrms-module/objection-form-dashboard"
                mat-list-item
                routerLinkActive="active"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200"
                style="border-radius: 10px"
              >
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p>Seniority List Objection</p>
                </div>
              </a> -->
            </mat-nav-list>
          </mat-expansion-panel>
          <!-- Reports -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Reports</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a routerLink="/hrms-module/govt-sanctioned-posts" mat-list-item routerLinkActive="active"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <!-- <mat-icon style="color: #015296">card_membership</mat-icon> -->
                  <p>
                    Government Recognized <br />
                    Posts
                  </p>
                </div>
              </a>
              <a routerLink="/hrms-module/vacant-posts" mat-list-item routerLinkActive="active"
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <p>
                    Sanctioned Filled and <br />
                    Vacant Posts
                  </p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>
        <!-- HRMS -->

        <!-- solid waster management -->
        <mat-accordion *ngIf="isSWMRoute" [multi]="false">
          <!-- Masters -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Masters</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of swmMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
          <!-- Transactions  -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Transaction</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of swmTransaction" [routerLink]="menuItem.link" mat-list-item
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">{{
                    menuItem.icon
                    }}</mat-icon>
                  <p>{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>
        <!-- solid waster management -->

        <!-- MSMAM -->
        <mat-accordion *ngIf="isMSMRoute" [multi]="false">
          <!-- Masters -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Masters</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of msmamMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <!-- Transactions -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Transactions</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of msmamTransaction" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>
        <!-- MSMAM -->

        <!-- MATERIAL MANAGEMENT -->
        <mat-accordion *ngIf="isMMRoute" [multi]="false">
          <!-- Masters -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Masters</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of mmMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
          <!-- Transaction -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Transaction</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of mmTransactionNavLinks" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.path" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <!-- /// -->
        </mat-accordion>
        <!-- MATERIAL MANAGEMENT -->

        <!-- Enterprise Workflow Management -->
        <mat-accordion *ngIf="isEWMRoute" [multi]="false">
          <!-- Masters -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Masters</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of ewmMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>
        <!-- Enterprise Workflow Management -->

        <!-- Content Management System -->
        <mat-accordion *ngIf="isCMSRoute" [multi]="false">
          <!-- Masters -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Masters</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of cmsMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
          <!-- Transactions -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Transaction</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let item of cmstransactionMenuItems" [routerLink]="item.link" mat-list-item
                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                style="border-radius: 10px">
                <div class="flex items-center gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="whitespace-normal break-words">{{ item.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <mat-list-item routerLink="/content-management/feedback" (click)="toggleSideNav()">
            <div class="flex flex-1 gap-4 mt-3 ml-2">
              <mat-icon style="color: #015296">card_membership</mat-icon>
              <h3 class="mt-3">Feedback</h3>
            </div>
          </mat-list-item>
        </mat-accordion>

        <!-- Content Management System -->

        <!-- Work Management Route -->
        <mat-accordion *ngIf="isWorkManagementRoute" [multi]="false">
          <!-- Masters -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Masters</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of workManagementMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
          <!-- Transaction -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Transaction</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let link of WorksTrnNavLinks" [routerLink]="link.path" mat-list-item routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200" style="border-radius: 10px">
                <div class="flex items-center gap-4 mt-4">
                  <mat-icon style="color: #015296">{{ link.icon }}</mat-icon>
                  <p class="whitespace-normal break-words">{{ link.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>
        <!-- Work Management Route -->

        <!-- Land Estate Route -->
        <mat-accordion *ngIf="isLandEstateRoute" [multi]="false">
          <!-- Masters -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Masters</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of landEstateMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Transaction</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of landEstateTransactions" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>
        <!-- Land Estate Route -->

        <!-- GRIEVANCE MANAGEMENT -->

        <mat-accordion [multi]="false">
          <!-- Masters -->
          <mat-expansion-panel *ngIf="isGrivence && this.ugpPrevId == '2'">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Masters</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of grivanceMangementMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <!-- Transaction -->
          <mat-expansion-panel *ngIf="isGrivence && this.ugpPrevId == '2'">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Transaction</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of grievanceManagementTransactionMenuItems" mat-list-item
                routerLinkActive="active" [routerLink]="menuItem.link" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200" style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">{{
                    menuItem.icon
                    }}</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>
        <!-- GRIEVANCE MANAGEMENT -->

        <!-- Common  -->

        <mat-accordion *ngIf="isCommon && this.ugpPrevId == '2'" [multi]="false">
          <!-- Masters -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Masters</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of commonRoutesMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>
        <!-- Common -->

        <!-- Asset Management -->

        <mat-accordion *ngIf="isAssetManagement" [multi]="false">
          <!-- Masters -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Masters</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of assetManagementMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Transaction</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of assetManagementTransactionMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">{{
                    menuItem.icon
                    }}</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>
        <!-- Asset Management -->

        <!-- Financial Managment -->

        <mat-accordion *ngIf="isFinancialAccounting" [multi]="false">
          <!-- Masters -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Masters</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of financialMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
          <!-- Transaction -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Transaction</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
              <ng-container *ngFor="let menuItem of financialTransactionMenuItems">
                <!-- Menu items without sub-menu -->
                <a *ngIf="!menuItem.subMenuItems" mat-list-item routerLinkActive="active" [routerLink]="menuItem.link"
                  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200"
                  style="border-radius: 10px" (click)="toggleSideNav()">
                  <div class="flex flex-1 gap-4 mt-3">
                    <mat-icon style="color: #015296">{{
                      menuItem.icon
                      }}</mat-icon>
                    <p class="truncate">{{ menuItem.label }}</p>
                  </div>
                </a>

                <!-- Menu items with sub-menu -->
                <div *ngIf="menuItem.subMenuItems">
                  <a mat-list-item class="hover:bg-blue-200" mat-list-item routerLinkActive="active"
                    [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                    style="border-radius: 10px" (click)="toggleSideNav()">
                    <div class="flex flex-1 gap-4 mt-3">
                      <mat-icon style="color: #015296">{{
                        menuItem.icon
                        }}</mat-icon>
                      <p class="truncate">{{ menuItem.label }}</p>
                    </div>
                  </a>
                  <mat-nav-list style="padding-left: 20px">
                    <a *ngFor="let subMenuItem of menuItem.subMenuItems" mat-list-item routerLinkActive="active"
                      [routerLink]="subMenuItem.link" routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200" style="border-radius: 10px"
                      (click)="toggleSideNav()">
                      <div class="flex flex-1 gap-4 mt-3">
                        <mat-icon style="color: #015296">{{
                          subMenuItem.icon
                          }}</mat-icon>
                        <p class="truncate">{{ subMenuItem.label }}</p>
                      </div>
                    </a>
                  </mat-nav-list>
                </div>
              </ng-container>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>

        <!-- Financial Managment -->
        <mat-list-item (click)="logout()">
          <div class="flex flex-1 gap-4 mt-3 ml-2">
            <mat-icon style="color: #015296">logout</mat-icon>
            <h3 class="mt-3">Logout</h3>
          </div>
        </mat-list-item>

        <!-- Water Tax Management -->
        <mat-accordion *ngIf="isWTRoute" [multi]="false">
          <!-- Dashboard -->
          <!-- <mat-list-item (click)="toggleSideNav()">
            <div
              class="flex flex-1 gap-4 mt-3 ml-2"
              routerLink="/water-tax/water-tax-dashboard"
            >
              <mat-icon style="color: #015296">monitor</mat-icon>
              <h3 class="mt-3">Dashboard</h3>
            </div>
          </mat-list-item> -->
          <!-- Dashboard -->
          <!-- Masters -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Masters</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of wtMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
          <!-- Master -->

          <!-- Transaction -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">fact_check</mat-icon>
                  <h3 class="mt-3">Trasactions</h3>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list (click)="toggleSideNav()">
              <a *ngFor="let menuItem of wtTrMenuItems" mat-list-item routerLinkActive="active"
                [routerLink]="menuItem.link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
                class="hover:bg-blue-200" style="border-radius: 10px">
                <!-- <mat-icon>{{ menuItem.icon }}</mat-icon>  -->
                <div class="flex flex-1 gap-4 mt-3">
                  <mat-icon style="color: #015296">card_membership</mat-icon>
                  <p class="truncate">{{ menuItem.label }}</p>
                </div>
              </a>
            </mat-nav-list>
          </mat-expansion-panel>
          <!-- Transaction -->
        </mat-accordion>
        <!-- Water Tax Management -->
      </div>
      <!-- <a mat-list-item (click)="logout()">
      <mat-icon>logout</mat-icon> Logout
    </a> -->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <main class="flex-grow z-20 main-content-item">
      <router-outlet></router-outlet>
      <div class="fixed bottom-0 left-0 w-full" style="z-index: -1">
        <img src="assets/bg.svg" alt="Background SVG" class="opacity-60 w-full h-auto" />
      </div>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- If we have to load like multilevel menuBar -->
<!-- <mat-accordion *ngIf="isProtax" [multi]="false">
        <mat-expansion-panel *ngFor="let section of propertyTaxItems1">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="flex flex-1 gap-4 mt-3">
                <mat-icon style="color: #015296">{{ section.icon }}</mat-icon>
                <h3 class="mt-3">{{ section.label }}</h3>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-nav-list (click)="toggleSideNav()">
            <a *ngFor="let menuItem of section.subItems" mat-list-item [routerLink]="menuItem.link" routerLinkActive="active" 
              [routerLinkActiveOptions]="{ exact: true }" class="hover:bg-blue-200" style="border-radius: 10px;">
              <div class="flex flex-1 gap-4 mt-3">
                <mat-icon style="color: #015296">{{ menuItem.icon }}</mat-icon>
                <p class="truncate">{{ menuItem.label }}</p>
              </div>
            </a>
          </mat-nav-list>
        </mat-expansion-panel>
      </mat-accordion> -->