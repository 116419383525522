import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(private httpClient: HttpClient) { }

 
  getVendors(){
    var url = `${environment.cwmUrl}` + '/api/master/vendor/getAllDashboard'

    return new Promise(resolve => 
      {
        this.httpClient.get(url).toPromise().then(res => {
          resolve(res);   
        }).catch((err : any)=> {
          resolve(err);
        });     
      });
  }

  

  saveVendor(vendorName : any,vendorPhoneNumber : any,vendorEmail : any,vendorAddress : any,vendorServices : any,
    vendorContract : any,vendorRenewalDate : any,vendorCertifiacte : any,vendorNotes : any,itemId : any,activeFlag : any,id : any){
    let body = {}
    

    body = {
      vendorName : vendorName,
      vendorPhoneNumber : vendorPhoneNumber,
      vendorEmail : vendorEmail,
      vendorAddress : vendorAddress,
      vendorServices : vendorServices,
      vendorContract : vendorContract,
      vendorRenewalDate : vendorRenewalDate,
      vendorCertifiacte : vendorCertifiacte,
      vendorNotes : vendorNotes,
      itemId : itemId,
      activeFlag : activeFlag,
      id : id
    }

    // const formData = new FormData();
    // formData.append("mstDepartmentDao", JSON.stringify(body));

    var url = `${environment.cwmUrl}` + '/api/master/vendor/save'

    return new Promise(resolve => {
      this.httpClient.post(url,body).toPromise().then(res => {
        resolve(res);
      }).catch((err : any) => {
        resolve(err);
      });
    })

  }

  activateInactivateVendor(data){
    return this.httpClient.post(`${environment.cwmUrl}/api/master/vendor/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,null)
  }

 
}





