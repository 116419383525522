import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WardService {

  constructor(private httpClient : HttpClient) { }
  

  getWard(){
    var url = `${environment.commonUrl}` + '/api/master/ward/getAllWard'

    return new Promise(resolve => 
      {
        this.httpClient.get(url).toPromise().then(res => {
          resolve(res);   
        }).catch((err : any)=> {
          resolve(err);
        });     
      });
  }

  getWardForDropdown(){
    var url = `${environment.commonUrl}` + '/api/master/ward/getAll'

    return new Promise(resolve => 
      {
        this.httpClient.get(url).toPromise().then(res => {
          resolve(res);   
        }).catch((err : any)=> {
          resolve(err);
        });     
      });
  }

  getAreaForDropdown(){
    var url = `${environment.commonUrl}` + '/api/master/ward/getAll'

    return new Promise(resolve => 
      {
        this.httpClient.get(url).toPromise().then(res => {
          resolve(res);   
        }).catch((err : any)=> {
          resolve(err);
        });     
      });
  }
  getWard2(){
    var url = `${environment.commonUrl}` + '/api/master/ward/getAllWard'
    return this.httpClient.get(url);
  }

  getWardByZone(zoneId : any){
    var url = `${environment.commonUrl}` + '/api/master/ward/getWardByZoneId?zoneId=' + zoneId
 
    return new Promise(resolve => 
      {
        this.httpClient.get(url).toPromise().then(res => {
          resolve(res);   
        }).catch((err : any)=> {
          resolve(err);
        });     
      });
  }

  getWardByZoneId(zoneId : any){
    return this.httpClient.get(`${environment.commonUrl}/api/master/ward/getWardByZoneId?zoneId=${zoneId}`)
  }

  saveWard(gisId : any,latitude : any,longitude : any,wardName : any,wardNameMr : any,zoneId : any,activeFlag : any,id : any,
    stateId : any,districtId : any,talukaId : any){
    let body = {}
  
  
    body = {
      gisId : gisId,
      // latitude : latitude,
      // longitude : longitude,
      wardName : wardName,
      wardNameMr : wardNameMr,
      zoneId : zoneId,
      activeFlag : activeFlag,
      id : id,
      stateId  : stateId,
      districtId : districtId,
      talukaId : talukaId
    }
  
    // const formData = new FormData();
    // formData.append("mstZoneDao", JSON.stringify(body));
  
    var url = `${environment.commonUrl}` + '/api/master/ward/save'
  
    return new Promise(resolve => {
      this.httpClient.post(url,body,{responseType:'text'}).toPromise().then(res => {
        resolve(res);
      }).catch((err : any) => {
        resolve(err);
      });
    })
  
  }

  activateInactivateWard(id,data){
    return this.httpClient.post(`${environment.commonUrl}/api/master/ward/active/${id}`,data)
  }
}


