import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileUploadService } from 'src/app/_services/file-upload.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {


  docPath : any = ''
  uploadedDocs : any[] = []
  filePaths : any[] = []
  singleFilePath : any = ''

  constructor(private fileUploadService : FileUploadService,
    private _snackBar : MatSnackBar) {
  }

  ngOnInit(){
    
  }

  onFileSelected(event : any){
    // this.uploadedDocs = event.target.files
    for( let file of event.target.files ) {
      this.uploadedDocs.push(file)
    }    
    this._snackBar.open('File selected Successfully', 'Close', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 3000,
    });  }

    removeFile(file: string) {
      const index = this.uploadedDocs.indexOf(file);
      if (index !== -1) {
        this.uploadedDocs.splice(index, 1);
      }
    }
  

  generateFiles(){
    for(var i = 0; i < this.uploadedDocs.length; i++){
      this.docPath = this.uploadedDocs[i]
      this.saveUploadedFile()
      if(i == this.uploadedDocs.length - 1){
        this._snackBar.open('Files saved successfully', 'Close', {
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: 3000,
        });
        this.showFilePreview()
      }

    }
   
  }

  saveUploadedFile(){
    this.fileUploadService.uploadFile(this.docPath).then((data : any) => {
      this.singleFilePath = data.filePath
      this.showFilePreview()
      // this.filePaths.push(data.filePath)
      // if(JSON.parse(data).status=="SUCCESS"){
   
      //   setTimeout(() => {
      //   }, 1000);
      // }else {
        // this._snackBar.open('File Saved', 'Close', {
        //   horizontalPosition: 'right',
        //   verticalPosition: 'top',
        //   duration: 3000,
        // });
      // }
    })
  }

  showFilePreview(){
    this.fileUploadService.getFilePreviewSingle(this.singleFilePath).then((data : any) => {
    })
  }
}
