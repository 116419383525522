import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FileUploadService } from 'src/app/_services/file-upload.service';

@Component({
  selector: 'app-show-qr-code',
  templateUrl: './show-qr-code.component.html',
  styleUrls: ['./show-qr-code.component.scss']
})
export class ShowQrCodeComponent {
  base64Img:any;
  // base64Img='iVBORw0KGgoAAAANSUhEUgAAASwAAAEsAQAAAABRBrPYAAADI0lEQVR4Xu2ZQc6jMAyFXbFg2SPkJuVilUDiYuUmHIFlFgjPey+tVPL/0sxiFrGEFxSSL4snO7aTmv+LbVaP/GoXVtmFVXZhlV1YZRdWWcGywfA7Wb5tlvqXdasvPq8Y7oJgg/uKx8y3efXDRnxu44q5OQj2gDSIfJo0682IZbtHwvx135MN8Jgv2+j9snXRMDyeqWfQMcqwimNxsIHx9rhrpOPnpPHVfwnLRjGKAzaT/X5QcwxMhnFIW6jUjFumiJS1j2Gbj2t/3HfKnbjhkYpfyF72sDEGBn3PhGSLh2GcWGL2Sn58b5mWsZJssfWdrJkI7Hr/eKx9DEOQZtj1mSLhNr6xpg/b95ZpGOMkQ2031G9LireP8CAYbGcDNSpdOZzVef9iKl5OzmoYY/FjC4IA467PN/ZTC+RyaRCsSKN3bvzGsDyWfuysZjEWDfQcJmnGoGMjiLYEznovC4CN9JOjhqCBMjZQ0IwdpKALgVGaqXRjkn6iSDqLXgyBKe3ig8XPIRf6uGr8hF8IjEaRLCQmZ7m6cxyPYmAq2Jn1u7xxQc92XOkgCMYmnNWORyEethF0TMBY8J24GsYyUyw9lnjMU+lei9Jzim4Yg9KJPaAwRZ5CjW/neGsXKx3TwYK9+MSiMaVcdv0pcbWMGZRKrtNF6qLKhmddiYHBOywa7AZN0qBUBTEQhj2iIi4XOY8VRvVVvDWMYRxR5jrSYRLOmumnp/WKwRAYjPeWI7zDBgpEwpuvVp+M2sV4GZDVwb6QgKWZNeSns5rFVL91IDVOcpiPcv8aA9MxVEdTuEh3A+9L2ECYl55j58kIxzwOGM+sWh8D0yRVYZtDJMtHZvk4596WMRbxFTlrZ0Or7kOpGLm3nDcCYDJdN8E6FkQ0tDxbKAZDYJnTije6rXTikiuPxcAGlAqWQet9k9K53J1pQQzs8f4Di12tMd5QPtSdD5wOg6nkZf09fdz3RD+xs+oCYfjo6DG243JRcZssADa4Wqknvya2hHsqjeDp0q9pjKFV6sWhe2T0tyjnM87ZP5NDm9hf7cIqu7DKLqyyC6vswir7z9gfcnoxRrgtEXMAAAAASUVORK5CYII='

  constructor(
    private uploadService: FileUploadService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {console.log(data)
    
    // this.uploadService.getGenerateQrCode(data).subscribe(
    //   (res: Blob) => {
    //     console.log(res)
    //     const reader = new FileReader();
    //     reader.onload = () => {
    //       const base64String = reader.result.toString().split(',')[1];
    //       console.log(base64String)
    //       this.base64Img = this.sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + base64String);
    //     };
    //     reader.readAsDataURL(res);
    //   }
    // );
  }
}
