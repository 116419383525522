<div class="flex flex-col shadow-2xl drop-shadow-lg mt-16 p-4 w-full">
  <!-- Your content goes here -->

    
  
    <!-- Full-Width Card -->
    <!-- <div class="container"> -->
      <!-- Card Content -->
    
    <!-- <app-dynamic-form [formData]="formData" [formId]="formId"></app-dynamic-form> -->
    <!-- <app-dynamic-form ></app-dynamic-form> -->
    <!-- <app-document-submission></app-document-submission>
   -->
      
    <!-- </div> -->
  
    <!-- add dynamic-form component-->
    
  
  </div>
  