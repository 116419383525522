<div class="mx-20">
  <section class="main-section">
    <section class="inner-section">
        <div class="page-heading">
            <!-- <div class="row">
                <div class="ol-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="page-heading">
                        <h1 class="alternate">Edit profile</h1>
                    </div>
                </div>
            </div> -->
        </div>

            <form [formGroup]="form" class="space-y-6">
            <div class="block">
                <div class="text-center">
                <!-- Hidden Inputs for Additional Data -->
                <!-- <input type="hidden" formControlName="MobileNo" value="7719878830">
                <input type="hidden" formControlName="EmailID" value="sushant.probitysoft@gmail.com">
                <input type="hidden" formControlName="UserID" value="911047"> -->

                <div class="user-profile-wrapper">
                    <div class="clearfix">
                        <div class="pull-left">
                            <div class="text-2xl font-bold text-gray-700" style="padding-top: 20px;">{{ form.get('userFirstname').value }} {{ form.get('userLastname').value }}</div>
                            <div class="text-gray-600"><strong>{{ form.get('userEmail').value }}</strong>(Registered Email)</div>
                            <div class="text-gray-600"><strong>{{ form.get('userMobile').value }} </strong>(Registered Mobile)</div>
                        </div>
                        <!-- Placeholder for Profile Image -->
                        <!-- <img src="/OnlineServices/Images/default-profile-img.jpg" alt="Profile Image" class="w-24 h-24 rounded-full border-2 border-gray-300"> -->
                    </div>
                </div>
                    <!-- <div class="text-right">
                        <a [routerLink]="['/change-email']" class="text-blue-600 hover:underline">Change Email ID/Mobile No</a> |
                        <a [routerLink]="['/change-password']" class="text-blue-600 hover:underline">Change Password</a>
                    </div> -->
                

                <hr class="my-6 border-gray-300">

                <div id="user-details">
                    <h2 class="text-xl font-semibold text-gray-800 mb-6">Personal Details</h2>
                        <ng-container *ngIf="ugpPrevId === 3; else otherFields">
                            <!-- Show only first name, last name, email, and mobile fields when ugpPrevId == 3 -->
                            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">                            <mat-form-field class="w-full">
                              <label for="userFirstname" class="block text-sm font-medium text-gray-700">First Name</label>
                              <input type="text" formControlName="userFirstname" id="userFirstname"
                                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            </mat-form-field>
          
                            <mat-form-field class="w-full">
                              <label for="userLastname" class="block text-sm font-medium text-gray-700">Last Name</label>
                              <input type="text" formControlName="userLastname" id="userLastname"
                                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            </mat-form-field>

                            <mat-form-field class="w-full">
                                <label for="userMobile" class="block text-sm font-medium text-gray-700">User Name/Mobile No.</label>
                                <input onKeyPress="if(this.value.length==10) return false;" type="number" formControlName="userMobile" id="userMobile"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                              </mat-form-field>

                            <mat-form-field class="w-full">
                              <label for="userEmail" class="block text-sm font-medium text-gray-700">Email</label>
                              <input type="email" formControlName="userEmail" id="userEmail"
                                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            </mat-form-field>
          
                        </div>
                          </ng-container>
          
                          <!-- Fields for other ugpPrevId values -->
                          <ng-template #otherFields>   
                            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4"> 
                        <mat-form-field class="w-full">
                            <label for="userFirstname" class="block text-sm font-medium text-gray-700">First Name</label>
                            <input type="text" formControlName="userFirstname" id="userFirstname" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        </mat-form-field>
                        <mat-form-field class="w-full" >
                            <label for="userMiddleName" class="block text-sm font-medium text-gray-700">Middle Name</label>
                            <input type="text" formControlName="userMiddleName" id="userMiddleName" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        </mat-form-field>
                        <mat-form-field class="w-full">
                            <label for="userLastname" class="block text-sm font-medium text-gray-700">Last Name</label>
                            <input type="text" formControlName="userLastname" id="userLastname" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        </mat-form-field>
                        <mat-form-field class="w-full">
                            <label for="userName" class="block text-sm font-medium text-gray-700">User Name</label>
                            <input type="text" formControlName="userName" id="userName" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        </mat-form-field>
                        <mat-form-field class="w-full">
                            <label for="dptName" class="block text-sm font-medium text-gray-700">Department Name</label>
                            <input type="text" formControlName="dptName" id="dptName" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        </mat-form-field>
                        <mat-form-field class="w-full">
                            <label for="designationName" class="block text-sm font-medium text-gray-700">Designation Name</label>
                            <input type="text" formControlName="designationName" id="designationName" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        </mat-form-field>                       
                        <mat-form-field class="w-full">
                            <label for="gender" class="block text-sm font-medium text-gray-700">Gender</label>
                            <input type="text" formControlName="gender" id="gender" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        </mat-form-field>
                        <mat-form-field class="w-full">
                            <label for="userEmail" class="block text-sm font-medium text-gray-700">Email</label>
                            <input type="email" formControlName="userEmail" id="userEmail" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        </mat-form-field>
                        <mat-form-field class="w-full">
                            <label for="userMobile" class="block text-sm font-medium text-gray-700">Mobile No.</label>
                            <input onKeyPress="if(this.value.length==10) return false;" type="number" formControlName="userMobile" id="userMobile" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        </mat-form-field>
                        </div>
                    </ng-template>
                </div>
                </div>
            </div>
            </form>
        </section>
    </section>
</div>
