import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivationInactivationConfirmationComponent } from 'src/app/master-data/activation-inactivation-confirmation/activation-inactivation-confirmation.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';
import { AssetServiceService } from 'src/app/_services/assetService/asset-service.service';
import { AddEditAssetComponent } from './add-edit-asset/add-edit-asset.component';

@Component({
  selector: 'app-asset-asset',
  templateUrl: './asset-asset.component.html',
  styleUrls: ['./asset-asset.component.scss']
})
export class AssetAssetComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  // departments: any[] = [];
  columnNames: any[] = [
    {
      columnDef: 'sno',
      header: 'Sr.No.',
    },
    {
      columnDef: "categoryName",
      header: 'Category'
    },

    {
      columnDef: 'actions',
      header: 'Actions',
    },
  ]
  assets = new MatTableDataSource<any>();
  displayedColumns: any[] = []
  contentLoaded = false;
  statusAction: any = ''
  checked: boolean = false;



  constructor(private assetService: AssetServiceService,
    private dialog: MatDialog,
    private _snackBar: SnackbarMsgService
  ) {
  }



  ngOnInit() {
    //call this method on component load
    this.displayedColumns = this.displayedColumns.concat(this.columnNames.map(x => x.columnDef));    // pre-fix static
    this.getAssetData();
  }

  ngAfterViewInit() {
    this.assets.sort = this.sort;
  }

  getAssetData() {
    this.assetService.getAsset().subscribe(res => {
      this.assets.data = res['categoryMasterList']
      this.assets.data = this.assets.data.sort((a, b) => a.id - b.id);
      setTimeout(
        () => {
          this.assets.sort = this.sort;
          this.assets.paginator = this.paginator


        });

      setTimeout(() => {
        this.contentLoaded = true;
      }, 2000);


    })
    // this.DeptService.getDepartment()
    // .subscribe((res)=>{
    //   console.log(res);
    //   this.departments.data = res as DepartmentData[];
    //   console.log(this.departments)

    // })
  }

  capitalize(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.assets.filter = filterValue.trim().toLowerCase();
  }

  addAsset() {

    const dialogRef = this.dialog.open(AddEditAssetComponent, {
      width: "550px",
      data: {
        isEditMode: false,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getAssetData()
      }
    });

  }

  onEdit(row) {
    const dialogRef = this.dialog.open(AddEditAssetComponent, {
      width: "550px",
      data: {
        isEditMode: true,
        row: row
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getAssetData()
      }
    });
  }


  onSlideToggleChange(row: any, event: any): void {

    if (event.checked == true) {
      this.statusAction = 'Activate'
      row.activeFlag = 'Y'
    } else {
      this.statusAction = 'Deactivate';
      row.activeFlag = 'N'

    }
    var formData = {
      id: row.id,
      activeFlag: row.activeFlag
    }

    const dialogRef = this.dialog.open(ActivationInactivationConfirmationComponent, {
      width: "550px",
      data: {
        mode: this.statusAction,
        component: 'Asset'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {



        if (this.statusAction == 'Activate') {
          this.assetService.activateInactivateAsset(formData).subscribe(
            res => {
              this._snackBar.success('Selected Asset activated');
              this.getAssetData();

            },
            err => {
              this._snackBar.error('Activation Failed')
              this.getAssetData();

            }
          )

        } else {
          this.assetService.activateInactivateAsset(formData).subscribe(
            res => {
              this._snackBar.success('Selected Asset Deactivated');
              this.checked = !this.checked;
              this.getAssetData();

            },
            err => {
              this._snackBar.error('Deactivation Failed')
              this.getAssetData();

            })
        }
      } else {
        this.getAssetData();
      }
    })


  }




}
