import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CentralWorkshopServiceService {
  constructor(private http: HttpClient) {}
  // Agency
  getAgency() {
    return this.http.get(`${environment.cwmUrl}/api/master/agency/getAll`);
  }
  getByActiveFlagAgency() {
    return this.http.get(
      `${environment.cwmUrl}/api/master/agency/getAllActiveList`
    );
  }
  activeInactiveAgency(id, flag) {
    return this.http.post(
      `${environment.cwmUrl}/api/master/agency/activeInactive?id=${id}&activeFlag=${flag}`,
      null
    );
  }

  saveAgency(data: any) {
    return this.http.post(`${environment.cwmUrl}/api/master/agency/save`, data);
  }

  // DriverType
  getDriverType() {
    return this.http.get(`${environment.cwmUrl}/api/master/driverType/getAll`);
  }
  getByActiveFlagDriverType() {
    return this.http.get(
      `${environment.cwmUrl}/api/master/driverType/getAllActiveList`
    );
  }
  activeInactiveDriverType(id, flag) {
    return this.http.post(
      `${environment.cwmUrl}/api/master/driverType/activeInactive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveDriverType(data: any) {
    return this.http.post(
      `${environment.cwmUrl}/api/master/driverType/save`,
      data
    );
  }
  // Manufacture
  getManufacture() {
    return this.http.get(
      `${environment.cwmUrl}/api/master/manufacturer/getAll`
    );
  }
  getByActiveFlagManufacture() {
    return this.http.get(
      `${environment.cwmUrl}/api/master/manufacturer/getAllActiveList`
    );
  }
  activeInactiveManufacture(id, flag) {
    return this.http.post(
      `${environment.cwmUrl}/api/master/manufacturer/activeInactive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveManufacture(data: any) {
    return this.http.post(
      `${environment.cwmUrl}/api/master/manufacturer/save`,
      data
    );
  }

  // Model
  getModel() {
    return this.http.get(`${environment.cwmUrl}/api/master/model/getAll`);
  }
  getByActiveFlagModel() {
    return this.http.get(
      `${environment.cwmUrl}/api/master/model/getAllActiveList`
    );
  }
  activeInactiveModel(id, flag) {
    return this.http.post(
      `${environment.cwmUrl}/api/master/model/activeInactive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveModel(data: any) {
    return this.http.post(`${environment.cwmUrl}/api/master/model/save`, data);
  }

  getModelByManufactureId(id: any) {
    return this.http.get(
      `${environment.cwmUrl}/api/master/model/getModelNameByManufacturerId?manufacturerId=${id}`
    );
  }

  // Vehicle
  getVehicle() {
    return this.http.get(`${environment.cwmUrl}/api/master/vehicle/getAll`);
  }

  getVehicleAssign() {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/asdsvehicle/getAllVehicleAssign`
    );
  }

  getVehicleAssignById(id) {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/asdsvehicle/getEmpById?id=${id}`
    );
  }

  getVehicleById(id) {
    return this.http.get(
      `${environment.cwmUrl}/api/master/vehicle/getVehicleNoDetails?driverId=${id}`
    );
  }

  getVehicleByDriverId(id) {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/asdsvehicle/getAssignVehicleByDriverId?driverId=${id}`
    );
  }

  getByActiveFlagVehicle() {
    return this.http.get(
      `${environment.cwmUrl}/api/master/vehicle/getAllActiveList`
    );
  }
  activeInactiveVehicle(id, flag) {
    return this.http.post(
      `${environment.cwmUrl}/api/master/vehicle/active/${id}`,
      flag
    );
  }
  saveVehicle(data: any) {
    return this.http.post(
      `${environment.cwmUrl}/api/master/vehicle/save`,
      data
    );
  }

  // VehicleType
  getVehicleType() {
    return this.http.get(`${environment.cwmUrl}/api/master/vehicleType/getAll`);
  }
  getByActiveFlagVehicleType() {
    return this.http.get(
      `${environment.cwmUrl}/api/master/vehicleType/getAllActiveList`
    );
  }

  activeInactiveVehicleType(id, flag) {
    return this.http.post(
      `${environment.cwmUrl}/api/master/vehicleType/activeInactive?id=${id}&activeFlag=${flag}`,
      null
    );
  }

  saveVehicleType(data: any) {
    return this.http.post(
      `${environment.cwmUrl}/api/master/vehicleType/save`,
      data
    );
  }

  // Driver
  getDriver() {
    return this.http.get(`${environment.cwmUrl}/api/master/driver/getAll`);
  }
  getByActiveFlagDriver() {
    return this.http.get(
      `${environment.cwmUrl}/api/master/driver/getAllActiveList`
    );
  }
  activeInactiveDriver(id, flag) {
    return this.http.post(
      `${environment.cwmUrl}/api/master/driver/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveDriver(data: any) {
    return this.http.post(`${environment.cwmUrl}/api/master/driver/save`, data);
  }

  //Associate Driver

  associateDriver(id: any, driverId: any) {
    let body = {};
    body = {
      id: id,
      driverId: driverId,
    };

    const formData = new FormData();
    formData.append("id", id);
    formData.append("driverId", driverId);

    var url =
      `${environment.cwmUrl}` + "/api/transaction/asdsvehicle/associateDriver";

    return this.http.post(url, formData, { responseType: "text" });
  }

  DeAssociateUser(id: any) {
    let body = {};

    body = {
      id: id,
    };

    const formData = new FormData();
    formData.append("id", id);
    // formData.append("employeeId", employeeId);

    var url =
      `${environment.cwmUrl}` +
      "/api/transaction/asdsvehicle/disassociateEmployee";

    return new Promise((resolve) => {
      this.http
        .post(url, formData, { responseType: "text" })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err: any) => {
          resolve(err);
        });
    });
  }

  DeAssociateDriver(id: any) {
    let body = {};

    body = {
      id: id,
    };

    const formData = new FormData();
    formData.append("id", id);
    // formData.append("employeeId", employeeId);

    var url =
      `${environment.cwmUrl}` +
      "/api/transaction/asdsvehicle/disassociateDriver";

    return new Promise((resolve) => {
      this.http
        .post(url, formData, { responseType: "text" })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err: any) => {
          resolve(err);
        });
    });
  }

  activateInactivateVehicle(data) {
    return this.http.post(
      `${environment.cwmUrl}/api/master/vehicle/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }

  //Item
  getItem() {
    return this.http.get(
      `${environment.cwmUrl}/api/master/item/getAllDashboard`
    );
  }

  getByActiveFlagItem() {
    return this.http.get(`${environment.cwmUrl}/api/master/item/getAll`);
  }

  activeInactiveItem(id, flag) {
    return this.http.post(
      `${environment.cwmUrl}/api/master/item/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveItem(data: any) {
    return this.http.post(`${environment.cwmUrl}/api/master/item/save`, data);
  }

  //Items
  getItems() {
    var url = `${environment.cwmUrl}` + "/api/master/items/getAll";
    return this.http.get(url);
  }
  getByActiveFlagItems() {
    var url = `${environment.cwmUrl}` + "/api/master/items/getAllActiveList";
    return this.http.get(url);
  }
  activeInactiveItems(id, flag) {
    return this.http.post(
      `${environment.cwmUrl}/api/master/item/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveItems(data: any) {
    return this.http.post(`${environment.cwmUrl}/api/master/items/save`, data);
  }

  // save maintainance request
  saveMaintenanceRequest(data: any) {
    return this.http.post(
      `${environment.cwmUrl}/api/transaction/maintenanceRequest/save`,
      data
    );
  }

  getMaintenanceRequest() {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/maintenanceRequest/getAll`
    );
  }

  getMaintenanceRequestById(id) {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/maintenanceRequest/getManById?id=${id}`
    );
  }

  getMaintenanceVehRequest() {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/maintenanceRequest/getAllMaintenanceRequest`
    );
  }

  getMaintenanceVehEstimation() {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/maintenanceEstimation/getAllEstimation`
    );
  }

  getMaintenanceEstimation() {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/maintenanceEstimation/getAll`
    );
  }

  getMaintenanceEstimationById(id) {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/maintenanceEstimation/getEstById?id=${id}`
    );
  }

  getMaintenanceVehQuotation() {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/maintenanceQuotation/getAllQuotation`
    );
  }

  getMaintenanceQuotation() {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/maintenanceQuotation/getAll`
    );
  }

  getMaintenanceQuotationById(id) {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/maintenanceQuotation/getQuoById?id=${id}`
    );
  }

  saveMaintenanceEstimation(data: any) {
    return this.http.post(
      `${environment.cwmUrl}/api/transaction/maintenanceEstimation/save`,
      data
    );
  }

  getRepairs() {
    return this.http.get(
      `${environment.cwmUrl}/api/master/repair/getAllDashboard`
    );
  }

  getByActiveFlagRepairs() {
    return this.http.get(`${environment.cwmUrl}/api/master/repair/getAll`);
  }

  // vendor
  getVendor() {
    return this.http.get(`${environment.cwmUrl}/api/master/vendor/getAll`);
  }

  getByActiveFlagVendor() {
    return this.http.get(
      `${environment.cwmUrl}/api/master/vendor/getAllActiveList`
    );
  }

  saveVendor(data) {
    return this.http.post(`${environment.cwmUrl}/api/master/vendor/save`, data);
  }

  // save vehicle quotation

  getMaintenanceVendorQuotation(id) {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/maintenanceQuotation/getAllQuotationDetails?quotationId=${id}`
    );
  }

  saveMaintenanceQuotation(data: any) {
    return this.http.post(
      `${environment.cwmUrl}/api/transaction/maintenanceQuotation/save`,
      data
    );
  }

  // repair reason
  getrepairReason() {
    return this.http.get(
      `${environment.cwmUrl}/api/master/repairReason/getAll`
    );
  }

  getByActiveFlagrepairReason() {
    return this.http.get(
      `${environment.cwmUrl}/api/master/repairReason/getAllActiveList`
    );
  }

  activeInactiverepairReason(id, flag) {
    return this.http.post(
      `${environment.cwmUrl}/api/master/repairReason/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saverepairReason(data: any) {
    return this.http.post(
      `${environment.cwmUrl}/api/master/repairReason/save`,
      data
    );
  }

  getItemNamebyItemCategory(data: any) {
    return this.http.get(
      `${environment.cwmUrl}/api/master/items/getItemNameByItemCategoryId?itemCategoryId=${data}`
    );
  }

  // saveWorkOrder
  saveworkOrder(data: any) {
    return this.http.post(
      `${environment.cwmUrl}/api/transaction/maintenanceWorkOrder/save`,
      data
    );
  }

  getAllWorkOrder() {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/maintenanceWorkOrder/getAll`
    );
  }

  getWorkOrderById(id) {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/maintenanceWorkOrder/getWrkById?id=${id}`
    );
  }

  getWorkOrderInVendor() {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/maintenanceWorkOrder/getAllWorkOrder`
    );
  }

  uploadPDF(data) {
    return this.http.post(
      `${environment.cwmUrl}/api/transaction/maintenanceWorkOrder/save`,
      data
    );
  }

  // Vendor Payment
  saveVendorPayment(data: any) {
    return this.http.post(
      `${environment.cwmUrl}/api/transaction/vendorPayment/save`,
      data
    );
  }

  getVendorPayment() {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/vendorPayment/getAll`
    );
  }

  getVendorPaymentById(id) {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/vendorPayment/getVenById?id=${id}`
    );
  }

  // Travel Allowance

  getTravelAllowance() {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/travelAllowance/getAll`
    );
  }

  getTravelAllowanceById(id) {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/travelAllowance/getTraById?id=${id}`
    );
  }

  saveTravelAllowance(data) {
    return this.http.post(
      `${environment.cwmUrl}/api/transaction/travelAllowance/save`,
      data
    );
  }

  // vehicle rent or driver pay

  getVehicleRentDriverPay() {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/vehicleRentDriverPay/getAll`
    );
  }

  getVehicleRentDriverPayById(id) {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/vehicleRentDriverPay/getVehRentById?id=${id}`
    );
  }

  saveVehicleRentDriverPay(data) {
    return this.http.post(
      `${environment.cwmUrl}/api/transaction/vehicleRentDriverPay/save`,
      data
    );
  }

  // PUC - Insurance renewal
  getPucRenewal() {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/insurancepucrenewal/getAll`
    );
  }

  getPucRenewalById(id) {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/insurancepucrenewal/getPucById?id=${id}`
    );
  }

  savePucRenewal(data) {
    return this.http.post(
      `${environment.cwmUrl}/api/transaction/insurancepucrenewal/save`,
      data
    );
  }

  // Fuel Record'
  getFuelRecord() {
    return this.http.get(`${environment.cwmUrl}/api/transaction/Fuel/getAll`);
  }

  getFuelRecordById(id) {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/Fuel/getFuelById?id=${id}`
    );
  }

  saveFuelRecord(data) {
    return this.http.post(
      `${environment.cwmUrl}/api/transaction/Fuel/save`,
      data
    );
  }

  // save associate driver employee
  saveAssociateDriverOrEmployee(data) {
    return this.http.post(
      `${environment.cwmUrl}/api/transaction/asdsvehicle/saveVehicleAssign`,
      data
    );
  }

  // vehicle aging
  getVehicleAging() {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/vehicleAgeing/getAll`
    );
  }

  saveVehicleAging(data) {
    return this.http.post(
      `${environment.cwmUrl}/api/transaction/vehicleAgeing/save`,
      data
    );
  }

  getVehicleAgingById(id) {
    return this.http.get(
      `${environment.cwmUrl}/api/transaction/vehicleAgeing/getVehById?id=${id}`
    );
  }
}
